import { Injectable, OnInit } from '@angular/core';
import { Observable, forkJoin, map, of, switchMap} from 'rxjs';
import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { IDataSelectedLocation, ILightLocation, IntegPrintStationResponse, RequiredChildsData} from '../core/required-childs-data';
import { Data } from '@angular/router';
import { HttpUtils, ILayoutRequest, OffsetCollection, PrintJobViewModel} from 'app/models';
import { LabelLayoutOrderField, OrderDirection } from 'app/models/printing/enums';
import { LocationTypes } from 'app/models/locations/enum';

@Injectable()
export class PrintingService extends BaseHttpService implements OnInit {
    tabs: NgbNav;
    idsToCheckStatus: string[];
    take = 20;
    getAllVendorJobs = true;
    basePrinterApiUrl: string = environment.printServiceBaseUrl + '/api/printer/';
    basePrintApiUrl: string = environment.printServiceBaseUrl + '/api/print/';

    ngOnInit() {
        this.idsToCheckStatus = [];
    }

    public getLayouts(vendorId: number): Observable<LayoutViewModel[]> {
      const chunkSize = 1000;
      const url = environment.portalBaseAddress + '/api/v1/printing/layout';
      const request: ILayoutRequest = {
        orderBy: LabelLayoutOrderField.Name,
        orderDirection: OrderDirection.Ascending,
        vendorId: vendorId,
        deleted: false
      };

      return this.getManyLayouts(url,request,chunkSize).pipe(
        map(layouts => layouts.map<LayoutViewModel>(_ => {
          return {
          id: _.id,
          name: `${_.name} - (${_.formatType})`,
          format: _.formatType,
        }}))
      );
    }

    private getLocations(locationKeys: string[]): Observable<OffsetCollection<ILightLocation>> {
      const url = environment.portalBaseAddress + '/api/v1/locations';
      const request = {
        locationKeys: locationKeys
      };
      return this.http.get<ILightLocation[]>(url, {observe: 'response', params: request})
        .pipe(
          map(HttpUtils.toOffsetCollection)
        );
    }

    public getPrinters(vendorId: number): Observable<PrinterViewModel[]> {
      return forkJoin({
        integ: this.getIntegPrinters(vendorId),
        portal: this.getPortalPrinters(vendorId)
      }).pipe(map(_ => {
        return [..._.portal, ..._.integ];
      }));
    }

    private getIntegPrinters(vendorId: number): Observable<PrinterViewModel[]> {
      const request = {
        vendorId: vendorId
      };
      return this.http.get<{ Printers: IntegPrintStationResponse[] }>(this.basePrinterApiUrl + 'GetAvailablePrintersAndLayouts', {
        params: request,
      }).pipe(
        map(response => {
          return response.Printers
            .map(this.mapIntegPrintStation)
            .flat(1);
        })
      );
    }

    private mapIntegPrintStation(station: IntegPrintStationResponse): PrinterViewModel[] {
      return [...station.Printers, station.SelectedPrinter]
      .map(printer => {
        return {
          id: station.Id,
          name: printer.Name,
          dpi: printer.Dpi == 600 ? PrinterDpi.D600 : (printer.Dpi == 300 ? PrinterDpi.D300 : PrinterDpi.D203),
          displayName: printer.Name,
          serialNumber: '',
          clientId: '',
          status: station.SelectedPrinterOnline ? PrinterStatus.Online : PrinterStatus.Offline,
          displayFullName: `${station.LocationName}: ${station.ComputerName} / ${printer.Name}`,
          isNewPrintClient: false,
          location: `${LocationTypes[station.LocationType]}:${station.LocationId}`,
        }
      })
    }

    private getPortalPrinters(vendorId: number): Observable<PrinterViewModel[]> {
      return this.getPrintStations(vendorId).pipe(
        switchMap(stations => {
          // Return empty result if new print stations not found
          if (stations.length == 0) {
            return of([]);
          }

          // Get information about printers based on stations
          const clientIds = stations.map(_ => _.clientId);
          const locationKeys = stations.map(_ => _.locationKey);
          return forkJoin({
            stations: of(stations),
            locations: this.getLocations(locationKeys),
            printers: this.getPrints(clientIds)
          }).pipe(
            map(
            data => {
            // Build dictionary to get stations quickly by clientId\
            
            const stationsDic = data.stations.reduce<{[key: string]: IPortalPrintStationResponse}>((prev, curr) => {
              prev[curr.clientId] = curr;
              return prev;
            }, {});
            const locationsDic = data.locations.reduce<{[key: string]: ILightLocation}>((prev, curr) => {
              prev[curr.locationKey] = curr;
              return prev;
            }, {});

            // Build display view model
            return data.printers.map<PrinterViewModel>(printer => {
              const station = stationsDic[printer.clientId];
              const location = locationsDic[station.locationKey];
              return {
                ...printer,
                location: `${station.locationKey}`,
                displayFullName: `(new) ${location.name}: ${station.displayName} / ${printer.displayName}`,
                isNewPrintClient: true
              };
            });
          }))
        })
      );
    }

    public getPrintHistory(
      fromNewSource: boolean,
      page: number,
      location: IDataSelectedLocation,
      vendorId: number
    ): Observable<OffsetCollection<PrintJobViewModel>> {
      let dataSource = this.getPortalPrintHistory;

      if (!fromNewSource) {
        dataSource = this.getIntegPrintHistory;
      }

      return  dataSource.call(this, page, location, vendorId);
    }

    private getIntegPrintHistory(
      page: number,
      location: IDataSelectedLocation,
      vendorId: number
    ): Observable<OffsetCollection<PrintJobViewModel>> {
      const url = this.basePrintApiUrl + 'GetManyByLocation';

      const request = {
        skip: (page - 1) * this.take,
        take: this.take,
        locationId: location.internalId,
        locationType: location.internalType,
        searchKeyword: '',
        getAllVendorJobs: this.getAllVendorJobs
      };

      return this.http.get<IntegPrintJobsResponse>(url , { params: request }).pipe(
        map(res => {
          const result = res.List as OffsetCollection<PrintJobViewModel>;
          result.totalCount = res.Total;
          return result;
        })
      );
    }

    private getPortalPrintHistory(
      page: number,
      location: IDataSelectedLocation,
      vendorId: number
    ): Observable<OffsetCollection<PrintJobViewModel>> {
      const url = environment.portalBaseAddress + '/api/v1/printing/jobs';
      const request = {
        offset:  (page - 1) * this.take,
        take: this.take,
        vendorId: vendorId,
        orderBy: 'Id',
        orderDirection: 'Descending'
      };

      return this.http
        .get<OffsetCollection<IPrintJobModel>>(url, {
          params: request,
          observe: 'response',
        })
        .pipe(
          map(HttpUtils.toOffsetCollection),
          switchMap(printJobs => {
            return forkJoin({
              totalCount: of(printJobs.totalCount),
              printJobs: forkJoin(printJobs.map<Observable<PrintJobViewModel>>(job => {
                return forkJoin({
                  printJob: of(job),
                  productNames: this.getJobProductNames(job.id)
                }).pipe(
                  map(this.buildPrintJobViewModel)
                );
              }))
            });
          }),
          map((printJobWithProducts) => {
            const res = printJobWithProducts.printJobs as OffsetCollection<PrintJobViewModel>;
            res.totalCount = printJobWithProducts.totalCount;

            return res;
          })
        );
    }

    private buildPrintJobViewModel(data: { printJob: IPrintJobModel, productNames: string }) : PrintJobViewModel {
      return {
        Id: data.printJob.id,
        Name: data.productNames,
        ProductsCount: data.printJob.documentsCount,
        Type: 0,
        TasksCount: data.printJob.documentsCount,
        Status: data.printJob.status
      };
    }


    private getJobProductNames(jobId: number): Observable<string> {
      const r = {
        take: 10
      };
      return this.http.get<PrintJobTemplate[]>(environment.portalBaseAddress + `/api/v1/printing/jobs/${jobId}/templates`, { params: r })
        .pipe(
          map(products => {
            return products.map(p => {
              let name = p.productTitle + ' - '+ p.documentsCount;
              if (!!p.productSku) {
                name = p.productSku + ' | ' + name;
              }
              return name;
            }).join('; ');
          })
        );
    }

    public printProducts(data: RequiredChildsData, products: SearchProductModel[]): Observable<any> {
      let dataSource = this.printPortalProducts;

      if (!data.OnlinePrinter.isNewPrintClient) {
        dataSource = this.printIntegProducts;
      }

      return  dataSource.call(this, data, products);
    }

    private printPortalProducts(data: RequiredChildsData, products: SearchProductModel[]): Observable<any> {
      const url = environment.portalBaseAddress + '/api/v1/printing/jobs';

      const createModel = this.createPrintJob(data,  products);

      return this.http.post(url, createModel);
    }

    private printIntegProducts(data: RequiredChildsData, products: SearchProductModel[]): Observable<any> {
      const url = environment.portalBaseAddress + '/api/v1/printing/jobs';

      const request = this.generateModel(data, false);
      request.Products = products.map<PrintProductRequest>(product => {
        return {
          Quantity: product.Quantity,
          Product: {
            Sku: product.Sku,
            Name: product.Name,
            Id: product.ProductId,
            VariantId: product.VariantId
          }
        };
      })

      return this.post(urls.printProducts, request);
    }

    printOrders(orderIds: Array<any>, childsData: RequiredChildsData, isNotPrintersTab: boolean, productIds: Array<any>): Observable<any> {
        const data = this.generateOrdersModel(childsData, isNotPrintersTab)
        data.OrderIds = orderIds;
        data.ProductIds = productIds;

        return this.post(urls.printOrders, data);
    }

    private createPrintJob(data: RequiredChildsData, products: SearchProductModel[]): ICreatePrintJobModel {
      return {
        vendorId: data.vendorId,
        layoutId: data.DefaultLayoutId,
        locationKey: data.selectedLocation.internalKey,
        epcLock: false,
        epcVerification: false,
        postpone: false,
        printer: {
          clientId: data.OnlinePrinter.clientId,
          dpi: data.OnlinePrinter.dpi,
          name: data.OnlinePrinter.name,
          serialNumber: data.OnlinePrinter.serialNumber
        },
        items: products.map(product => {
          return {
            productId: product.ProductId,
            newTagsCount: product.Quantity,
          };
        })
      };
    }

    public generateModel(data: RequiredChildsData, isNotprinterTabs: boolean): PrintProductsRequest {
      return {
        ...this.generateBaseModel(data, isNotprinterTabs),
          Products: [],
      };
    }

    public generateOrdersModel(data: RequiredChildsData, isNotprinterTabs: boolean): PrintOrdersRequest {
        return {
          ...this.generateBaseModel(data, isNotprinterTabs),
          ProductIds: [],
          OrderIds: [],
        };
    }

    public goToPrintTab() {
        this.tabs.select('printing-tab');
    }

    searchProduct(term: string): Observable<any> {
      if (!term) {
        return of([]);
      }
      const url = environment.baseAddress + urls.searchProductsUrl;
      const request = {
        searchKeyword: term
      };

      return this.http.get<SearchProductModel[]>(url, {
        params: request,
        headers: this.getRequestHeaders()
      });
    }

    setTabSet(tabset: NgbNav) {
        this.tabs = tabset;
    }

    // Get stations from Portal
    private getPrintStations(vendorId: number): Observable<IPortalPrintStationResponse[]> {
      const url = environment.portalBaseAddress + '/api/v1/printing/stations';
      const params = {
        vendorId: vendorId,
        hasLocationAssigned: true
      };
      return this.http.get<IPortalPrintStationResponse[]>(url, {
        params: params
      });
    }

    // Get stations from PrintService
    private getPrints(clientIds: string[]): Observable<IPrinterResponse[]> {
      const url = environment.printServiceBaseAddress + '/api/v1/stations/printers';
      const params = {
        clientIds: clientIds
      };
      return this.http.get<IPrinterResponse[]>(url, {
        params: params
      });
    }

    private generateBaseModel(data: RequiredChildsData, isNondrinkerTabs: boolean): PrintRequestBaseModel {
      return {
        PrinterId: data.OnlinePrinter ? data.OnlinePrinter.id : '0',
        LayoutId: data.DefaultLayoutId,
        // TODO:: load and add userName here
        UserName: 'Integration user',
        // TODO:: load and add userId here
        UserId: 0,
        LocationId: data.selectedLocation.internalId,
        LocationType: data.selectedLocation.internalType,
        IsPostponed: false
      };
    }
}

interface IPortalPrintStationResponse {
  id: number;
  clientId: string;
  vendorId: number;
  locationKey: string | null;
  displayName: string;
}

interface IPrinterResponse {
  id: string;
  name: string;
  status: PrinterStatus;
  displayName: string;
  dpi: PrinterDpi;
  disconnected: Date;
  serialNumber: string;
  clientId: string;
  issueDescription: string;
}

export interface IPortalLayoutResponse {
  id: number;
  name: string;
  dpi: PrinterDpi;
  image: string;
  width: number;
  height: number;
  modified: Data;
  formatType: LayoutFormatType;
  body: string;
  tagId: number;
  readOnly: boolean;
}

export enum LayoutFormatType {
  Zpl = 'Zpl',
  Sld = 'Sld',
}

enum PrinterDpi {
  D203 = 'D203',
  D300 = 'D300',
  D600 = 'D600',
}

export enum PrinterStatus {
  Online = 'Online',
  Offline = 'Offline',
}

export interface LayoutViewModel {
  name: string;
  id: number;
  format: LayoutFormatType;
}

export interface PrinterViewModel {
  name: string;
  id: string;
  status: PrinterStatus,
  displayName: string,
  dpi: PrinterDpi,
  disconnected?: Date,
  serialNumber: string,
  clientId: string,
  issueDescription?: string,
  displayFullName: string;
  isNewPrintClient: boolean;
  location: string;
}

export interface PrintRequestBaseModel {
  PrinterId: string;
  LayoutId: number;
  IsPostponed: boolean;
  UserName: string;
  UserId?: number;
  OrderId?: number;
  LocationId: number;
  LocationType: string;
  VendorId?: number;
}

export interface PrintProductsRequest extends PrintRequestBaseModel {
  Products: PrintProductRequest[];
}

export interface PrintProductRequest {
  Product: {
    Id: number;
    Name: string;
    Sku: string;
    VariantId?: number;
  };
  Quantity: number;
  LotNumber?: string;
  SN?: string;
  ExpDate?: Date;
  BornDate?: Date;
  SellBy?: Date;
  Cost?: number;
}

export interface PrintOrdersRequest extends PrintRequestBaseModel {
  OrderIds: string[];
  ProductIds: ProductIdQuantityModel[];
}

export interface ProductIdQuantityModel {
  Id: number;
  Quantity: number;
}

export interface IPrintJobModel {
    id: number,
    referenceName: string,
    userName: string,
    labelName: string,
    status: string,
    created: Date,
    started: Date,
    finished: Date,
    printerSerialNumber: string,
    printerName: string,
    documentsCount: number,
    successPrintedCount: number,
    verifiedCount: number,
    locationName: string,
    jobNumber: number,
    failedPrintedCount: number
}

export interface ICreatePrintJobModel {
  vendorId: number,
  locationKey: string,
  printer: {
    clientId: string,
    serialNumber: string,
    name: string,
    dpi: PrinterDpi
  },
  items: ICreatePrintJobProductModel[],
  layoutId: number,
  referenceName?: string,
  postpone: boolean,
  epcLock: boolean,
  epcVerification: boolean
}

export interface ICreatePrintJobProductModel {
  productId: number,
  newTagsCount: number,
  serialNumber?: string,
  lotNumber?: string,
  cost?: number,
  bornDate?: Date,
  expirationDate?: Date,
  sellBy?: Date
}

export interface SearchProductModel {
  Name: string,
  ProductId: number,
  VariantId?: number,
  Sku: string,
  Quantity: number
}

interface IntegPrintJobsResponse {
  List: IntegPrintJobModel[];
  Total: number;
}

interface IntegPrintJobModel {
  Name: string,
  TasksCount: number,
  Status: string,
  ProductsCount: number,
  Type: number
}

interface PrintJobTemplate {
  id: number,
  productTitle: string,
  productSku: string,
  minSerialNumber: number,
  maxSerialNumber: number,
  documentsCount: number,
  successPrintedCount: number,
  originalProductId: number,
  bornDate: Date,
  expirationDate: Date,
  sellBy: Date,
  cost: number,
  serialNumber: string,
  lotNumber: string
}

