<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
        <br />
        <br />
        <img alt="SimpleRfid" src="./assets/images/internal-logo.svg" class="logo" />
    </div>
  </div>
  <br/>
  <div *ngIf="!!(errorMessageService?.error)"  class="row">
    <div class="col-md-12 bg-danger text-white">
      {{ errorMessageService?.error }}
    </div>
  </div>
  <div *ngIf="!!(errorMessageService?.warning)"  class="row">
    <div class="col-md-12 bg-warning text-white">
      {{ errorMessageService?.warning }}
    </div>
  </div>
  <div *ngIf="!!(errorMessageService?.info)"  class="row">
    <div class="col-md-12 bg-info text-white">
      {{ errorMessageService?.info }}
    </div>
  </div>
  <div *ngIf="loading" align="center">
    <br/>
    <div class="loader"></div>
  </div>
  <div *ngIf="!loading" class="main-container">
    <div *ngIf="checkAuth$ | async as checkAuth">
      <div *ngIf="checkAuth.isAuthenticated">
        <div class="toper-flax">
          <div class="steps-container box1-toper-flax">
            <div id="wizard-steps">
              <ul>
                <li [ngClass]="{'intro':'active'}[step]"><span>Welcome</span></li>
                <li [ngClass]="{'connection':'active'}[step]"><span>Setup</span></li>
              </ul>
            </div>
          </div>
          <div class="box2-toper-flax">
            <div class="top-email-flax">
              <div class="box1-top-email-flax">
                <label for="email">
                  Email:
                </label>
              </div>
              <div class="box2-top-email-flax">
                  <input [(ngModel)]="email" type="text"
                    name="email" id="email" [disabled]="true"/>
              </div>
            </div>
          </div>
          <div class="box3-toper-flax">
            <button class="btn btn-primary" type="button"
              [disabled]="!email"
              (click)="goSignOut()">
              SignOut
            </button>
          </div>
        </div>
        <hr />
        <ng-container [ngSwitch]="step">
          <div *ngSwitchCase="'intro'">
            <form>
              <div>
                <div class="row">
                  <div class="col-md-10 offset-md-1">
                    <h3>Welcome to the SimpleRFiD</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 offset-md-1">
                    (844) 637-2464
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 offset-md-1">
                    <a href="mailto:support@simplerfid.com">support&#64;simplerfid.com</a>
                  </div>
                </div>
                <br/>
                <ng-container *ngIf="platform=='shopify'">
                  <div class="row">
                    <div class="col-md-4 offset-md-1">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/XnltU9HNmzA?rel=0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group row">
                  <div class="col-md-2 offset-md-10">
                      <button type="button" class="btn btn-primary" (click)="step='connection'">
                          Next
                      </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10 offset-md-1">
                    Android APP can be found <a target="_blank" href="https://play.google.com/store/apps/details?id=com.simplerfid.tagreader">here</a>
                    <br />
                  </div>
                </div>
                <br/>
              </div>
            </form>
          </div>
          <div *ngSwitchCase="'connection'">
            <form>
              <div class="form-group row">
                <label for="setvendor" class="col-md-2 form-control-label">
                  <p>Vendor:</p>
                </label>
                <div class="col-md-3">
                  <select *ngIf="vendors$ | async as vendors"
                    [ngModel]="selectedVendor$ | async"
                    (ngModelChange)="selectedVendor$.next($event)"
                    class="form-control" name="setvendor">
                    <option *ngFor="let vendor of vendors;"
                      [ngValue]="vendor">{{vendor.Name}}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <div class="form-group row" *ngFor="let location of externalLocations; let i = index">
                  <label for="source" class="col-md-2 form-control-label" style="padding-top: 1px;">
                    <p *ngIf="i == 0">Source:</p>
                  </label>
                  <label class="col-md-2 field-center">{{location.name}}</label>
                  <div class="col-md-2 field-center">
                    <input [(ngModel)]="location.locationType"
                            (ngModelChange)="resetSelectedLocation(location)"
                            value="dc"
                            type="radio"
                            id="id_lt_dc_{{i}}"
                            name="group_lt_{{i}}"
                            class="location-type-radio">
                    <label for="id_lt_dc_{{i}}" class="location-type-label">DC</label>
                    <input [(ngModel)]="location.locationType"
                            (ngModelChange)="resetSelectedLocation(location)"
                            type="radio"
                            id="id_lt_store_{{i}}"
                            name="group_lt_{{i}}"
                            value="store"
                            class="location-type-radio"
                            >
                    <label for="id_lt_store_{{i}}" class="location-type-label">Store</label>
                  </div>
                  <div class="col-md-3" style="padding-top: 3px;">
                    <select [(ngModel)]="location.selectedLocation"
                      (ngModelChange)="rebuildInnerLocations();"
                      class="form-control">
                      <option *ngFor="let innerLocation of innerLocations | filterInnerLocationsPipe :externalLocations :location;"
                        [ngValue]="innerLocation">{{innerLocation.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div class="col-md-12 footer-message">
              <div [hidden]="!CanUseBarcodeAsKeyField" class="choise-barcode">
                <span class="box1-choise-barcode">Which field in your Shopify library is unique, SKU or Barcode?</span>
                <span class="box2-choise-barcode">
                    <input name="skuorbarcode" type="radio" [value]="false" [(ngModel)]="isBarcodeProduct" style="margin-top: 6px;"> SKU
                </span>
                <span class="box3-choise-barcode"><input name="skuorbarcode" type="radio" [value]="true" [(ngModel)]="isBarcodeProduct" style="margin-top: 6px;"> Barcode</span>
              </div>
            </div>
            <!-- <div class="col-md-12 footer-message">
              <input id="autocopy" type="checkbox" [(ngModel)]="isProductCopy">
              <label for="autocopy" style="vertical-align: middle; margin-left: 3px;"> Import products to SimpleRFiD</label>
            </div> -->
            <div class="col-md-1 offset-md-10">
              <button class="btn btn-primary" type="button"
                  [disabled]="!saveAvailable"
                  (click)="saveLocations()">
                  Finish
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
