import { Injectable } from "@angular/core";



@Injectable()
export class DateService {

  

    public convertUTCDateToLocalDate(dateUTC?: string) {
        let localDate = null;
        if(dateUTC){
            const date = new Date(dateUTC);
            localDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
        }
         
        return localDate;
      }
}