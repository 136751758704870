import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/authentication/services/auth.service';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
	constructor(private authService: AuthService) {}

	ngOnInit() {}

	login() {
		this.authService.doLogin();
	}
}
