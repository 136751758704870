export enum ActivityOperationType{
	NotOperation = 1,
	LoadInventory,
	SyncInventory,
	RevertHistory,
	ImportAllProduct,
	ModifyProduct,
	LoadOnHandInventory,
	SyncOnHandInventory,
	CompareOnHandInventory,
	LoadNeverPrinted,
}