export enum LabelLayoutOrderField
{
  Id = 'Id',
  Name = 'Name',
  Dpi = 'Dpi',
  Modified = 'Modified'
}

export enum OrderDirection
{
  Ascending = 'Ascending',
  Descending = 'Descending'
}