import { Component, Input, OnInit} from "@angular/core";



@Component({
    selector: 'app-image-hendler',
    templateUrl: './image-hendler.component.html',
    styleUrls: ['./image-hendler.component.css']
  })
  
  export class ImageHendlerComponent implements OnInit {
    @Input() product: any;
    pic: string;
    existsImage: boolean;

    ngOnInit(): void {
        this.pic = this.product.ThumbnailUrl;
        this.existsImage = true;
    }

    setDefaultPic() {
        this.pic = "./assets/images/no-image.jpg";
        this.existsImage = false;
      }

  }