import { Injectable, Input} from '@angular/core';
import { InventorySyncService } from './inventory-sync.service';
import { urls } from './../core/urls';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { HangFireService } from 'app/core/hangfire/hangfire.service';
import { NeverPrintedViewModel } from 'app/never-printed/never-printed.component';
import { catchError } from 'rxjs/operators';

@Injectable()
export class InventoryStorageService {

    public inventorySync: Array<any>;
    public inventoryOnHandSync: Array<any>;
    public inventoryAdd: Array<any>;
    public inventoryMatch: Array<any>;
    public inventoryOnHandMatch: Array<any>;
    public neverPrinted: Array<NeverPrintedViewModel>;
    public isLoadInProgress: boolean;
    public lastAuditDate: any;
    public isOrdersAccounted: boolean;
    public inventoryStatus: string;
    public isResetRecommended: boolean;
    public isInventorySyncLoaded: boolean;
    public isInventoryMatchLoaded: boolean;
    public isFirstloaded: boolean;
    public progressBarTimer: any;
    public inventorySyncCount: number = 0;
    public inventoryMatchCount: number = 0;
    public neverPrintedCount: number = 0;
    public inventoryDeflatedCount: number = 0;
    public inventoryInflatedCount: number = 0;
    public inventoryOutOfStockCount: number = 0;
    public allRfidCount: number = 0;
    public allOrderCount: number = 0;
    public allNewCount: number = 0;
    public allCurrentCount: number = 0;
    public allScanCount: number = 0;
    public lastScanDate: any;
    public lastAuditDateOnHand: any;
    public lastSyncDate: any;

    @Input() data: RequiredChildsData;

    private static instance: InventoryStorageService;

    constructor(private inventoryService: InventorySyncService, private errorModalService: ErrorModalService, private hangFireService: HangFireService) {
        this.initStorrage();
    }

    public static GetInstance(invService: InventorySyncService, errModalService: ErrorModalService, hangService: HangFireService): InventoryStorageService{
        if(InventoryStorageService.instance == null){
            InventoryStorageService.instance = new InventoryStorageService(invService, errModalService, hangService);
        }

        return InventoryStorageService.instance;
    }

    public Load(jobId:string, locationExternalId: string, operation: ActivityOperationType): void{
        this.isInventorySyncLoaded = false;
        this.isInventoryMatchLoaded = false;
        if(operation == ActivityOperationType.LoadInventory || operation == ActivityOperationType.LoadOnHandInventory){
            this.loadInventory(jobId, locationExternalId, operation);
        }else if(ActivityOperationType.CompareOnHandInventory){
            this.compareInventory(locationExternalId);
        }
    }

    public compareInventory(locationExternalId: string): void{
        this.isLoadInProgress = true;
        this.inventoryService.getItemsOnHand(locationExternalId)
        .pipe(catchError(e => {
            this.isLoadInProgress = false;
            this.isInventorySyncLoaded = true;
            this.isInventoryMatchLoaded = true;
            this.errorModalService.open('Error', `${e.message}`);
            throw new Error('Error load inventory');
        }))
        .subscribe(res => {
            this.inventoryOnHandSync = res.Inventory.filter(i => {
                return i.NewCount != i.CurrentCount
            });

            this.inventoryOnHandMatch = res.Inventory.filter(i => { 
                return i.NewCount == i.CurrentCount
            })

            this.inventoryMatchCount = this.inventoryOnHandMatch.length;
            this.isInventorySyncLoaded = true;
            this.isInventoryMatchLoaded = true;
            this.isOrdersAccounted = res.IsOrdersAccounted;
            this.isResetRecommended = res.IsResetRecommended;
            this.inventoryStatus = res.InventoryStatus;
            this.lastAuditDate = res.LastAuditDate;
            this.isLoadInProgress = false;
            this.isFirstloaded = true;

            this.inventorySync.forEach(it => {
                    it.sync = it.RfidCount !== 0 && it.RfidCount > it.CurrentCount && !this.isResetRecommended;
                    it.printQuantity = this.getPrintQuantity(it.CurrentCount, it.RfidCount);
            });
            this.inventoryAdd.forEach(it => {
                it.sync = true;
            });
        });
    }

    public loadInventory(jobId:string, locationExternalId: string, operation: ActivityOperationType): void{
        this.isLoadInProgress = true;
        this.hangFireService.getHangFireJobResult(jobId, locationExternalId, operation)
        .subscribe(res => {
            this.inventorySync = res.Inventory.filter(i => {
                return i.NewCount != i.CurrentCount
            }).map(i => {
                i.VariantId = i.VariantId || null;
                return i;
            });

            this.inventoryMatch = res.Inventory.filter(i => { 
                return i.NewCount == i.CurrentCount
            }).map(i => {
                i.VariantId = i.VariantId || null;
                return i;
            });

            this.inventoryMatchCount = this.inventoryMatch.length;
            this.isInventorySyncLoaded = true;
            this.isInventoryMatchLoaded = true;
            this.isOrdersAccounted = res.IsOrdersAccounted;
            this.isResetRecommended = res.IsResetRecommended;
            this.inventoryStatus = res.InventoryStatus;
            this.lastAuditDate = res.LastAuditDate;
            this.isLoadInProgress = false;
            this.isFirstloaded = true;

            this.inventorySync.forEach(it => {
                    it.sync = it.NewCount !== 0 && it.NewCount > it.CurrentCount && !this.isResetRecommended;
                    it.printQuantity = this.getPrintQuantity(it.CurrentCount, it.NewCount);
            });
            this.inventoryAdd.forEach(it => {
                it.sync = true;
            });
        },
        err =>{
            this.isLoadInProgress = false;
            this.isInventorySyncLoaded = true;
            this.isInventoryMatchLoaded = true;
            this.errorModalService.open('Error', 'loading inventory');
            throw new Error('Error load inventory');
        });
    }

    public initStorrage(): void{
        this.inventorySync = [];
        this.inventoryAdd = [];
        this.inventoryMatch = [];
        this.inventoryOnHandSync = [];        
        this.inventoryOnHandMatch = [];
        this.neverPrinted = [];
        this.isLoadInProgress = false;
        this.isInventorySyncLoaded = true;
        this.isInventoryMatchLoaded = true;
        this.isFirstloaded = false;
    }

    public SetMismatchItemsCount(operation: ActivityOperationType = ActivityOperationType.LoadInventory){
        let inventory = this.inventorySync;
        if(operation == ActivityOperationType.LoadOnHandInventory){
            inventory = this.inventoryOnHandSync;
        }
        this.inventorySyncCount = inventory.length;//.filter(i => i.RfidCount != 0).length;
        this.inventoryDeflatedCount = inventory.filter(i => {return i.FilterType == "Deflated"}).length;
        this.inventoryInflatedCount = inventory.filter(i => {return i.FilterType == "Inflated"}).length;
        this.inventoryOutOfStockCount = inventory.filter(i => {return i.FilterType == "OutOfStock"}).length;
    }

    public SetInventoryItemsCounts(items){
        this.allRfidCount = items.reduce((sum, current) => sum + current.RfidCount, 0);
        this.allOrderCount = items.reduce((sum, current) => sum + current.OrdersCount, 0);
        this.allNewCount = items.reduce((sum, current) => sum + current.NewCount, 0);
        this.allCurrentCount = items.reduce((sum, current) => sum + current.CurrentCount, 0);
    }

    private getPrintQuantity(partnerCount: number, rfidCount: number): number {
        return partnerCount > 0 ? partnerCount - rfidCount : 0;
    }
}
