import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequiredChildsData } from 'app/core/required-childs-data';

@Component({
    selector: 'inventory-order-modal-component',
    template: `
    <div class="header-modal">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-scrollable-container">
            <table class="table col-md-12">
                <thead>
                    <tr>
                        <th>
                            <app-image-hendler [product]="product"></app-image-hendler>
                        </th>
                        <th>{{product.Title}}</th>
                        <th>Audit: {{date.toLocaleString()}}</th>
                        <th>Sku: {{product.Sku}}</th>
                        <th>Total: {{product.OrdersCount}}</th>
                    </tr>
                    <tr class="col-md-12">
                        <th class="col-md-1"></th>
                        <th class="col-md-1">Order ID</th>
                        <th class="col-md-4">Order Date / Time</th>
                        <th *ngIf="data.isShopify" class="col-md-2">Channel</th>
                        <th class="col-md-3">Status</th>
                        <th class="col-md-1">Items</th>
                    </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let order of orders">
                            <td></td>
                            <td><a href="{{order.link}}" target="_blank" style="text-decoration: none;">{{order.Name}}</a></td>
                            <td>{{order.created_at}}</td>
                            <td *ngIf="data.isShopify">{{order.SourceName}}</td>
                            <td>{{order.status}}<span class="modal-statuses">{{order.fulfillment_status}}</span>
                            </td>
                            <td>
                                <div *ngFor="let item of order.line_items">
                                    <span *ngIf="data.isShopify && item.variant_id == product.VariantId">{{item.Quantity}}</span>
                                    <span *ngIf="data.isBigCommerce && item.product_id == product.ProductId">{{item.Quantity}}</span>
                                </div>
                            </td>
                        </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info cursor-pointer-btn" (click)="activeModal.close(false)">Close</button>
    </div>
    `,
    styleUrls: ['./inventory-order-modal.component.css'],
  })

  export class InventoryOrderModalComponent{
    @Input() product;
    @Input() orders;
    @Input() date;
    @Input() data: RequiredChildsData;

    constructor(public activeModal: NgbActiveModal) {}


  }