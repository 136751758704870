import { ProgressBarService } from './progress-bar.service';
import { Component, Input, EventEmitter, Output, OnInit} from '@angular/core';
import { ProgressBarDataModel } from './progress-bar-data-model';
import { MainComponent } from './../main/main.component';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateService } from 'app/services/date.service';

@Component({
  selector: 'app-progress-bar',
  template: `
  <div class="row">
    <div *ngIf="loading" align="center"  class="col-md-12">
      <br/>
      <div class="loader"></div>
      <span>Please wait</span>
      <div style="padding-top:8px;">
        <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" 
          title="Use only if the loading wheel spins for more than a few (2-3) minutes." 
          (click)='cancelActivity(true)'>Cancel</button>
      </div>
    </div>
  </div>
  <div *ngIf="!loading">
    <div  *ngIf="!data.loadOnHandQuantity" class="progress">
      <div class="progress-bar" role="progressbar" [ngStyle]="{'width.%': completePercent}" aria-valuemin="0" aria-valuemax="100">
        <span class="progress-bar-percents">{{completePercent}}% ({{currentCount}}/{{totalCount}}) {{message? message:""}}</span>
      </div>
    </div>
    <div *ngIf="data.loadOnHandQuantity" class="title-message">
      <h3 align="center">{{message? message:""}}</h3>
    </div>
    <div class="button-box button-bar">
      <div class="button-box1">
        <button class="btn btn-info button-refresh copyAndRefreshButton simpleRfIdColor cursorFinger" title="Use if progress bar is stuck" (click)='refresh()'>
          <span class="fa fa-sync-alt button-icon"></span>
        </button>
      </div>
      <div class="button-box2">
        <button class="btn btn-info copyAndRefreshButton simpleRfIdColor cursorFinger" 
          title="Use only if the progress bar is stuck and the refresh button has not responded. 
                 This button does not cancel the operation, but only disables the progress bar." 
          (click)='cancelActivity()'>Cancel</button>
      </div>
    </div>
    <!-- <div *ngIf="data.loadOnHandQuantity" class="title-list-no-audit">
      <div class="title-list-no-audit-box1">
        <div class="title-sub-box1"></div>
        <div class="title-sub-box2">
          <img src="./assets/images/reader.svg">
        </div>
        <div class="title-sub-box3"></div>
      </div>
      <div class="title-list-no-audit-box2 title-box2">
        <div class="title-sub-box1"></div>
        <div class="title-sub-box2">
          <p class="title-center">
            <b>You can scanning your products
              <br>
              Please, scan Add some tags and click "Compare".</b>
          </p>
          <ul>
            <li>
              Do not fulfill any items while <b>the audit</b> is in progress.
              <br>
              Fulfilling items will change the <b>On Hand</b> quantity in <b>Shopify</b>.
            </li>
            <li>
              Remove <b>fulfilled</b> items from the scan area so they are not captured.
            </li>
            <li>
              Please note that immediately following an <b>audit & sync</b>, any 
              items which are fulfilled will appear as <b>mismatch</b>.
            </li>
          </ul>
        </div>
        <div class="title-sub-box3"></div>
      </div>
    </div> -->
  </div>
  `,
  styleUrls: ['./progress-bar.component.css']
})

export class ProgressBar implements OnInit{

  @Input() data : ProgressBarDataModel;
  @Output() onFinish = new EventEmitter<any>();
  @Output() onProgress = new EventEmitter<any>();
  constructor(private progressBarService: ProgressBarService, private mainComponent: MainComponent, private dateService: DateService) {}  

  totalCount: number;
  currentCount: number;
  completePercent: number = 0;
  loading: boolean = false;
  message: string;
  operationType: number;
  hangfireJobId: string;
  hangfireJobStatus: string;
  requestCount: number = 0;
  jobCreateTime?: Date;
  //isWasStarted: boolean = false;

  ngOnInit(){
    this.runProgressBar();
  }

  refresh(){
    this.getSyncState();
  }

  cancel(): void{
    this.onFinish.emit();
  }

  getSyncState(){
    this.mainComponent.isContentDisabled = true;
    return this.progressBarService.getTaskState(this.data.locationExternalId, this.data.operationType)
    .pipe(
      tap(res => {
        this.totalCount = res.TotalCount;
        this.currentCount = res.CurrentCount;
        this.message = res.Message;
        let isInProgress = res.IsInProgress;
        this.hangfireJobId = res.HangfireJobId;
        this.hangfireJobStatus = res.StatusJob;
        this.jobCreateTime = this.dateService.convertUTCDateToLocalDate(res.CreateDateTimeJob?.toString());
        if(this.totalCount > 0){
          this.completePercent = Math.round((this.currentCount / this.totalCount) * 100);
        }
        this.checkJobStatus();
        
        if(!isInProgress && this.hangfireJobStatus != "Processing"){
          if(this.data.operationType == ActivityOperationType.LoadOnHandInventory){
            this.data.loadOnHandQuantity = false;
          }
          if(this.hangfireJobStatus == 'Failed' && this.message){
            this.data.errorMessage = this.message;
          }
          this.mainComponent.isContentDisabled = false;
          this.data.isTaskInProgress = false;
          this.completePercent = 0;
          this.loading = false;
          if(this.data.isWasStarted){
            this.data.isWasStarted = false;
          }
          this.onFinish.emit();
        } else if(!isInProgress && this.hangfireJobStatus == "Processing"){
          this.loading = true;
          this.data.loadOnHandQuantity = false;
        }else {
          this.data.isWasStarted = true;
          this.data.isTaskInProgress = true;
          this.loading = res.TotalCount == 0;
          this.onProgress.emit();
        }
      })
    );
  }

  runProgressBar() {
    this.progressBarUpdate().subscribe(() => {
      setTimeout(() => { this.runProgressBar(); }, 1000);
    });    
  }

  progressBarUpdate(){
    if(!this.data.isTaskInProgress){
      this.loading = false;
      if(!this.data.isSync){
        return new Observable(subscribe => {
          subscribe.next();
        });
      }
    }
    return this.getSyncState().pipe(tap(() => {
      this.data.jobRun = true;
    }));
  }

  cancelActivity(reload: boolean = false){
    if(this.data.loadOnHandQuantity){
      reload = true;
      this.data.loadOnHandQuantity = false;
      this.data.auditCanceled = true;
    }
    this.progressBarService.cancelActivity(this.data.locationExternalId, this.data.operationType).subscribe(r => {});
    this.cancel();
    this.mainComponent.isContentDisabled = false;
    this.loading = false;
    if(reload){
      setTimeout(() => this.reloadPage, 1000);
    }
  }

  reloadPage(){
    window.location.reload();
  }

  checkJobStatus(){
    if(this.hangfireJobStatus == "Scheduled"){
      this.data.hangFireFail = true;
      this.data.errorMessage = "This operation has been aborted";
      this.progressBarService.cancelActivity(this.data.locationExternalId, this.data.operationType).subscribe(r => {});
  }else if(this.hangfireJobStatus == "Enqueued"){
    if(this.requestCount > 3){
      this.data.hangFireFail = true;
      this.data.isTaskInProgress= false;
      this.requestCount = 0;
      this.data.errorMessage = "while executing. The server is currently overloaded. Please try again later";
      this.progressBarService.cancelActivity(this.data.locationExternalId, this.data.operationType).subscribe(r => {});
    }else{
      this.requestCount ++;
      this.data.isTaskInProgress = true;
      return;
    }
  }else if(this.hangfireJobStatus == "Processing" ){
      this.data.isTaskInProgress = true;
      return;
  } else if(this.hangfireJobStatus == "Failed"){
      let message = "";
      this.data.hangFireFail = true;
      if(this.data.operationType == ActivityOperationType.ImportAllProduct){
          message = "importing products";
      } else if(this.data.operationType == ActivityOperationType.LoadInventory){
          message = "loading inventory";
      } else if(this.data.operationType == ActivityOperationType.SyncInventory){
          message = "syncing inventory";
      } else if(this.data.operationType == ActivityOperationType.RevertHistory){
          message = "reverting inventory";
      } else {
          message = "occured";
      }
      this.data.errorMessage = message;
      this.progressBarService.deleteJodId(this.data.locationExternalId, this.data.operationType).subscribe(r => {});
  } else if (this.hangfireJobStatus == "Succeeded"){
    this.data.hangfireJobId = this.hangfireJobId;
      this.data.hangFireSuccess = true;
      this.data.isTaskInProgress = false;
      this.data.hangFireJobDateCreate = this.jobCreateTime;
  }
  }
}
