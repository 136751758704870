import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequiredChildsData } from 'app/core/required-childs-data';

@Component({
    selector: 'inventory-order-modal-component',
    template: `
    <div class="header-modal">
        <h3 class='header-text'>Privacy Agreement</h3>
    </div>
    <div class="modal-body">
        <div class="modal-scrollable-container">
            <h4>
                Definitions:
            </h4>
            <p>
                In accordance with the requirements for protected personal data of clients, 
                we inform you that we will use your personal data and personal data of the client, 
                including the fields of name, address, phone or e-mail.
            </p>
            <p>
                This data is used to print documents.
            </p>
            <p>
                To <b>continue</b> using this app, you need to <b>agree</b> with <b>these conditions</b>:
            </p>
            <div class="col-md-12">
                <input id="partner-data" type="checkbox" [(ngModel)]="data.agreeUseMyData">
                <label for="partner-data" style="vertical-align: buttom; margin-left: 5px;"> Use your personal data</label>
            </div>
            <div class="col-md-12">
                <input id="customer-data" type="checkbox" [(ngModel)]="data.agreeUseCastomerData">
                <label for="customer-data" style="vertical-align: buttom; margin-left: 5px;"> Use personal data of your customers</label>
            </div>
            <br>
            <p>
                Personal data will be stored in secure storage.
            </p>
            <p>
            <span>If you <b>disagree</b> with this Privacy Agreement, please, <b>close</b> the browser tab and <b>remove</b> Simple RFID app from your Apps list.</span>
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <button [disabled]='!isFullAgreement' type="button" class="btn btn-primary" (click)="activeModal.close(true)">I Agree</button>
    </div>
    `,
    styleUrls: ['./license-agreement.component.css'],
  })

  export class LicenseAgreementComponent{

    isFullAgreement: boolean = false;

    @Input() data: RequiredChildsData;


    constructor(public activeModal: NgbActiveModal) {}

    ngDoCheck(){
        this.isFullAgreement = this.data.agreeUseMyData && this.data.agreeUseCastomerData; 
    }
    
    RedirectToShopify(){
        window.location.href='https://www.shopify.com/partners';
    }
  }