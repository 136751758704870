import { Injectable } from '@angular/core';

@Injectable()
export class ErrorMessageService {
  _warning: string;
  _error: string;
  _info: string;

  get warning(): string {
    return this._warning;
  }
  set warning(theWarning: string) {
    console.log(theWarning);
    this._warning = theWarning;
  }

   get info(): string {
    return this._info;
  }
  set info(theInfo: string) {
    console.log(theInfo);
    this._info = theInfo;
  }


  get error(): string {
    return this._error;
  }
  set error(theError: string) {
    console.log(theError);
    this._warning = theError;
  }

  setErrorWithDetails(message: string, error: any) {
    console.log(error);
    this._error = message;
  }

  setWarningWithDetails(message: string, warning: any) {
    console.log(warning);
    this._warning = message;
  }

  clear() {
    this._error = '';
    this._warning = '';
    this._info = '';
  }
}
