<br/>

<div *ngIf="loading" align="center">
  <br/>
  <div class="loader"></div>
</div>

<div *ngIf="!loading">
  <div class="row">
    <div>
      <span style="font-weight: bold;">Printers</span>
    </div>
    <div class="col-md-12">
      <input id="showPrintersByLocation" type="checkbox" [(ngModel)]="settings.ShowPrintersByLocation" (ngModelChange)="update()"/>
      <label for="showPrintersByLocation" class="ml-5">Show printers by location</label>
    </div>
    <div>
      <span style="font-weight: bold;">Inventory</span>
    </div>
    <div *ngIf="!data.isVend && !data.isFulfil" class="col-md-12">
      <input id="extended" type="checkbox" [(ngModel)]="settings.extendedReport" (ngModelChange)="update()"/>
      <label for="extended" class="ml-5">Extended reports (Show all information on inventory history reports)</label>
    </div>
    <div *ngIf="data.isShopify" class="col-md-12">
      <input id="inventoryDays" class="inputDays" type="number" name="inventoryDays" value="openOrdersAgeDays"  
              min="14" max="60" required [(ngModel)]="openOrdersAgeDays" (ngModelChange)="changeOpenOrdersAgeDays()"/>
      <span class="validity"></span>
      <label for="inventoryDays" class="ml-5">Number of days to display open orders in inventory (14-60 days)</label>
    </div>
    <div>
      <span style="font-weight: bold;">Products</span>
    </div>
    <div *ngIf="!data.isVend && !data.isFulfil" class="col-md-12">
      <input id="createWebHook" type="checkbox" [(ngModel)]="createWebHook" (ngModelChange)="setProductCreateAndUpdateWebHook()"/>
      <label for="createWebHook" class="ml-5">Auto-import/update products to SimpleRFiD</label>
    </div>
    <div class="col-md-12">
        <input id="isProductsImportedMarked" type="checkbox" [(ngModel)]="settings.showImportedProducts" (ngModelChange)="update()"/>
        <label for="isProductsImportedMarked" class="ml-5">Mark imported products</label>
    </div>
    <div *ngIf="data.isShopify" class="col-md-12">
      <span>Unique Field: {{productKey}}</span>
    </div>
    <!-- <div *ngIf="!data.isVend && !data.isFulfil">
      <div>
        <span style="font-weight: bold;">Orders</span>
      </div>
      <div class="col-md-12">
        <input id="createOrdersWebHook" type="checkbox" [(ngModel)]="createOrdersWebHook" (ngModelChange)="setOrderCreateWebHook()"/>
        <label for="createOrdersWebHook" class="ml-5">Auto-import order to SimpleRFiD</label>
      </div>
      <div class="col-md-12" class="submitOrder">
        <input id="submitOrder" type="checkbox" [(ngModel)]="submitOrder" [disabled] = "!createOrdersWebHook" (ngModelChange)="setSubmitOrder()"/>
        <label for="submitOrder" class="ml-5">Auto-submit orders to QC</label>
      </div>
    </div> -->
    <div class="col-md-12" *ngIf="data.isBigCommerce">
      <input id="binLocation" type="checkbox" [(ngModel)]="settings.showBinLocation" (ngModelChange)="update()"/>
      <label for="binLocation" class="ml-5">Show Bin location</label>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12" style="margin-top: 10px;">
        <button (click)="changeVendor()" class="btn btn-info simpleRfIdColor cursorFinger">Disconnect from SimpleRFiD</button>
    </div>
  </div>
  <hr />
  <div>
    <div class="row">
      <label for="source" class="form-group" [ngClass]="data.isFulfil ? 'col-md-3':'col-md-4'">
        <h6><b>{{data.platform}} Locations:</b></h6>
      </label>
      <label for="source" class="form-group col-md-3" *ngIf="data.isFulfil">
        <h6><b>Sub Locations to sync:</b></h6>
      </label>
      <label for="source" class="form-group col-md-3 offset-1">
        <h6><b>SimpleRFiD Locations:</b></h6>
      </label>
    </div>    
    <div class="row" *ngFor="let item of locations; let i = index">
      <div class="col-md-3 field-center">
          <p>{{item.Name}}</p>
      </div>
      <div class="col-md-2" style="padding-left: 0;" *ngIf="data.isFulfil">
        <select name="sublocationId" class="form-control" (change)="setSyncSublocation($event.target.value, item.ExternalId)">
          <option *ngFor="let subLocation of getSubLocationsByLocation(item.ExternalId)" [value]="subLocation.id" [selected]="subLocation.IsSelected">{{subLocation.rec_name}}</option>
        </select>
      </div>
      <div class="col-md-2 field-center">
        <input type="radio" id="locationTypeDc{{i}}" name="{{item.ExternalId}}type" value="dc" class="location-type-radio" [(ngModel)]="item.Type">
        <label for="locationTypeDc{{i}}" class="ml-5 location-type-label">DC</label>
      
        <input type="radio" id="locationTypeStore{{i}}" name="{{item.ExternalId}}type" value="store" class="ml-5 location-type-radio" [(ngModel)]="item.Type">
        <label for="locationTypeStore{{i}}" class="ml-5 location-type-label">Store</label>
      </div>
      <div class="col-md-3" style="padding-top: 2px;">
        <select id="loc{{i}}" name="status" class="form-control" (change)="createOrBindLocation($event.target.value, item)">
          <option *ngFor="let location of getLocations(item.Type, item.ExternalId)" [value]="location.Id" [selected]="item.ExternalId == location.ExternalId ? true : null" >{{location.Name}}</option>
        </select>
      </div>
    </div>
  </div>
</div>



