<br/>
<div *ngIf="loading && !isLoadInProgress && showContent" align="center">
  <br/>
  <div class="loader"></div>
</div>
<div [hidden]="!isLoadInProgress">
  <h3 align="center">Loading Never Printed</h3>
  <app-progress-bar [data]='progressBarLoadNeverPrinted' (onProgress)="loadInProgress()" (onFinish)="finishLoad()"></app-progress-bar>
</div>
<ng-container *ngIf="!loading && !isLoadInProgress && showContent">
  <div class="main-box">
    <div class="search-box">
        <div class="search-box1"><input class="form-control" [(ngModel)]="searchString" (ngModelChange)="sortByPartner()" placeholder="Search..."></div>
        <div class="search-box2"><button type="submit" class="btn btn-info simpleRfIdColor" (click)="loadNeverPrinted()" >Load</button></div>
    </div>
    <div class="main-bottom-box">
      <div class="main-bottom-box1" >
        <input id="excludeZero" type="checkbox" [(ngModel)]="excludeZero" (ngModelChange)="sortByPartner()"/>
        <label for="excludeZero" class="ml-5">Exclude items with a quantity of 0</label>
      </div>
      <div class="main-bottom-box2" >
        <button type="submit" 
        class="btn btn-info simpleRfIdColor" (click)="openPrintModal()" 
        [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle"
        [openDelay]="300" [closeDelay]="300">
        Print 
        <span *ngIf="countItemsToPrint > 0">({{countItemsToPrint}})</span>
      </button>
      </div>
    </div>
  </div>

  <br/>
  <div class="table-area">
    <div *ngIf="filteredItems.length > 0" class="row table-title-bottom">
      <span class="col-md-1"></span>
      <span class="col-md-4"></span>
      <span class="col-md-4"></span>
      <span class="col-md-2 count-col">{{inventoryStorage.allCurrentCount}}</span>
      <span class="col-md-1 count-col-all-count">{{inventoryStorage.neverPrintedCount}}</span>
    </div>
    <hr>
    <div class="row table-title-bottom">
      <span style="text-align: center;" class="col-md-1"><b>Image</b></span>
      <span class="col-md-4 product-name-col"><b>Product Name</b></span>
      <span class="table-sku col-md-4 product-key-col"><b>{{productKey}}</b></span>
      <span class="col-md-2 count-col" (click)="sortByPartner(true)">
        <b style="margin-right: 5px;">{{data.platform}}</b>
        <img *ngIf = 'isSortedByPatnerAscending == "default"' src="./assets/images/sort.png">
        <img *ngIf = 'isSortedByPatnerAscending == "asc"' src="./assets/images/sort-up.png">
        <img *ngIf = 'isSortedByPatnerAscending == "desc"' src="./assets/images/sort-down.png">
      </span>
      <span style="padding-left: 24px;" class="col-md-1" >
        <input type="checkbox" name="syncAll"
          [(ngModel)]="syncAll"
          [checked]="syncAll"
          (ngModelChange)="adjustSelection()"
          [disabled]="selectAllDisabled"/>
      </span>
    </div>
    <hr  *ngIf="filteredItems.length == 0">
    <ng-container *ngIf="filteredItems.length > 0">
      <div>
        <cdk-virtual-scroll-viewport [style.overflow]="filteredItems.length > 0 ? 'scroll':'auto'" class="list-container lg" [itemSize]="50" [bufferSize]="50">
          <div *cdkVirtualFor="let product of filteredItems let i = index templateCacheSize: 0" class="list-group-item row" >
            <div style="text-align: center;" class="col-md-1">
              <app-image-hendler [product]="product"></app-image-hendler>
            </div>
            <div style="display: block;" class="col-md-4">{{product.Title}}</div>
            <div style="display: block;" class="col-md-4">{{product.Sku}}</div>
            <div style="display: block;" title="Current" class="col-md-2 centering-content" [class.text-yellow]="product.isAdjusted">{{product.CurrentCount}}</div>
            <div style="display: block;" class="col-md-1 check-box-col">
              <input type="checkbox" name='sync{{i}}' [checked]="product.check"  (change)="changeSelection(product)" (click)="toggleSelected(i, $event)"
                  oninput="this.value = this.value.replace(/\D/g, '')"/>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-container>
  </div>
  <div class="row" style="margin-left: 0;  margin-right: 0;">
    <div class="col-md-3 offset-md-7 text-center">
      <ng-container *ngIf="allowMakeVisible">
        <span style="padding-top:5px;">
          <input type="checkbox" name="makeVisible" [(ngModel)]="makeVisible">&nbsp;<label for="makeVisible">Make visible</label>
        </span>
      </ng-container>
    </div>
  </div>
  <div *ngIf="filteredItems.length > 0" style="text-align: end;">
    <button type="submit" 
      class="btn btn-info simpleRfIdColor button-footer" 
      [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
      [openDelay]="300" [closeDelay]="300" 
      (click)="openPrintModal()">
      Print 
      <span *ngIf="countItemsToPrint > 0">({{countItemsToPrint}})</span>
    </button>
  </div>
</ng-container>