import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
	providedIn: 'root',
})
export class PrintJobNotificationService {
	private hubConnection: signalR.HubConnection;

	private readonly changedEventName = 'Changed';

	constructor(private oidcSecurityService: OidcSecurityService) {
		const options: signalR.IHttpConnectionOptions = {
			accessTokenFactory: () => this.oidcSecurityService.getAccessToken().toPromise(),
		};

		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(environment.portalBaseAddress + '/signal/v1/print-job', options)
			.configureLogging(environment.signalRLogLevel)
			.build();
	}

	public startConnection = () => {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err));
	};

	public addChangedListener = (callback: (model: IPrintJobViewModel) => void) => {
		this.hubConnection.on(this.changedEventName, (model: IPrintJobViewModel) => {
			callback(model);
		});
	};

	public removeChangedListener = () => {
		this.hubConnection.off(this.changedEventName);
	};

	public stopConnection() {
		this.hubConnection.stop()
			.then(() => console.log('Connection stoped'))
			.catch(err => console.log('Error while stoping connection: ' + err));
	}
}

export enum PrintingJobStatus {
	Postpone = 'Postpone',
	Ready = 'Ready', //ready for print ASAP
	InProgress = 'InProgress', //printing right now
	Completed = 'Completed', //printing process completed

	//Old ststuses
	Canceled = 'canceled',
  	Postponed = 'postponed',
  	Paused = 'paused',
	Success = 'success',
	InProgressOld = 'inprogress',
	Failed = 'failed',
}

enum PrintingDocumentStatus {
	Ready = 'Ready', //ready for print ASAP
	InProgress = 'InProgress', //printing right now
	Pending = 'Pending', //paused for some reasons
	Printed = 'Printed', //has printed
	Failed = 'Failed', //fail during print
	Completed = 'Completed', //printing process completed
	Canceled = 'Canceled', //printing process canceled
	ManualCanceled = 'ManualCanceled', //printing process canceled by user
}


export interface IPrintJobViewModel {
	id: number;
	orderRef?: string[];
	referenceName: string;
	status: PrintingJobStatus;
	created: Date;
	started: Date | null;
	finished: Date | null;
	printerSerialNumber: string;
	printerName: string;
	documentsCount: number;
	userName: string;
	labelName: string;
	locationName: string;
	jobNumber: number;
	failedPrintedCount: number;
	successPrintedCount: number;
	verifiedCount: number;
	isExpanded: boolean;
	isTemplateLoading: boolean;
	isDocumentsLoading: boolean;
	printProductTemplates: IPrintProductTemplateViewModel[] | null;
	printProductDocuments: IPrintProductDocumentViewModel[] | null;
}

interface IPrintProductTemplateViewModel {
	id: number;
	productTitle: string;
	productSku: string;
	minSerialNumber: number;
	maxSerialNumber: number;
	documentsCount: number;
	successPrintedCount: number;
	active: boolean;
}

interface IPrintProductDocumentViewModel {
	id: number;
	epc: string;
	serialNumber: number;
	printStatus: PrintingDocumentStatus;
	printDocumentId: string;
}
