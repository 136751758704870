import { OrderTypes } from './../core/url-builder';
import { Component, Input } from '@angular/core';
import {PurchaseOrdersComponent} from '../purchase-orders/purchase-orders.component';

@Component({
    selector: 'app-transfer-orders',
    templateUrl: '../purchase-orders/purchase-orders.component.html',
    styleUrls: ['../purchase-orders/purchase-orders.component.css']
  })
  
  
  export class TransferOrdersComponent extends PurchaseOrdersComponent{

    override ngOnInit(){
      this.isPurchaseTab = false;
      this.loading = true;
      this.orderType = 'transfer';
      this.purchseOrdersService.getOrderStatuses(this.orderType).subscribe(res =>{
        this.ordersStatuses = res;
        this.status = '';
        this.getTransfer();  
      });
    }

    getTransfer() {
      this.type = this.purchseOrdersService._transferType;
      this.orderLinkType = OrderTypes.Transfer;
      this.getFilteredOrders();
    }
  }