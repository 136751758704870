import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';

@Injectable()
export class MappingService extends BaseHttpService {

  getMappingData(): Observable<any> {
    return this.get(urls.getMappingUrl);
  }

  saveMappingData(mapping): Observable<any> {
    const data = JSON.stringify({
      mapping: mapping
    });

    return this.post(urls.saveMappingUrl, data);
  }
}
