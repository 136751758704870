<br/>

<div *ngIf="loading" align="center">
    <br/>
    <div class="loader"></div>
</div>

<form *ngIf="!loading">
    <div class="row" style="padding-right: 15px;">
        <div class="col-md-2">
            <label>Filter by status:</label>
        </div>
        <div class="col-md-2">
            <select id="filter" name="status" [(ngModel)]="status" (change)="getFilteredOrders()" class="form-control" [ngModelOptions]="{standalone: true}" >
                <option *ngFor="let status of ordersStatuses, let i = index" [ngValue]='status == "All" ? "" : status'>{{status}}</option>
            </select>
            <br/>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-info" (click)="submit()" [disabled]="!printButtonEnabled">Print</button>
        </div>
        <div class="col-md-3">
            <input id="searchKeyword" class="form-control" (input)="searchKeyword = $event.target.value" value="{{searchKeyword}}" placeholder="Search by Name, Number">
        </div>
        <div class="col-md-1">
            <button class="btn btn-primary btn-info" (click)="getOrders()" style="display: inline" [disabled]="searchKeyword.length == 0">Search</button>
        </div>
        <div class="col-md-2" *ngIf='!data.isFulfil'>
            <div class="btn-group">
                <button *ngIf = 'isPurchaseTab' class="btn btn-primary btn-blue" [ngClass]="{ 'active' : type == purchseOrdersService._purchaseType }" (click)="getPurchase()">Purchase</button>
                <button *ngIf = 'isPurchaseTab' class="btn btn-primary btn-blue" [ngClass]="{ 'active' : type == purchseOrdersService._returnType }" (click)="getReturn()">Return</button>
            </div>
        </div>     
    </div>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <input name="allSelected" type="checkbox" [ngModel]="allSelected" (click)="selectAll()"/>
                    </th>
                    <th class="order-table-date-header" (click)="sortByDate()">Date <img *ngIf = 'isSotedByDateAscending' src="./assets/images/sort-up.png">
                                                                                            <img *ngIf = '!isSotedByDateAscending' src="./assets/images/sort-down.png">
                    </th>
                    <th>Name</th>
                    <th *ngIf="type != purchseOrdersService._purchaseType">Origin</th>
                    <th *ngIf="type != purchseOrdersService._transferType">Supplier</th>
                    <th *ngIf="type == purchseOrdersService._transferType || type == purchseOrdersService._purchaseType">Destination</th>
                    <th>Number</th>
                    <th>Status</th>
                    <th *ngIf='!data.isFulfil'>Received at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of orders; let ind = index" ng-class="order.class" class="order-raw">
                    <td>
                        <input name="order[{{ind}}].Selected" type="checkbox" [(ngModel)]="order.Selected" (change)="select()" [disabled]="!isPrintable(order)"/>
                    </td>
                    <td (click)="openPartialInfoModal(order)" *ngIf="order.CreatedAt != null">{{order.CreatedAt | date:'medium':'':data.locale}}</td>
                    <td (click)="openPartialInfoModal(order)" *ngIf="order.CreatedAt == null">-</td>
                    <td title="Click to navigate to order in {{data.platform}}">
                        <a href="{{order.link}}" target="_blank" style="text-decoration: none;">{{order.Name}}</a>
                    </td>
                    <td *ngIf="type == purchseOrdersService._returnType" (click)="openPartialInfoModal(order)">{{order.Outlet}}</td>
                    <td *ngIf="type == purchseOrdersService._transferType" (click)="openPartialInfoModal(order)">{{order.SourceOutlet}}</td>                    
                    <td *ngIf="order.SupplierName && type != purchseOrdersService._transferType" (click)="openPartialInfoModal(order)">{{order.SupplierName}}</td>
                    <td *ngIf="!order.SupplierName && type != purchseOrdersService._transferType" (click)="openPartialInfoModal(order)">-</td>
                    <td *ngIf="type != purchseOrdersService._returnType" (click)="openPartialInfoModal(order)">{{order.Outlet}}</td>
                    <td (click)="openPartialInfoModal(order)">{{order.Number}}</td>
                    <td (click)="openPartialInfoModal(order)">{{order.Status}}</td>
                    <td (click)="openPartialInfoModal(order)" *ngIf="order.ReceivedAt != null && !data.isFulfil">{{order.ReceivedAt | date:'mediumDate'}}</td>
                    <td (click)="openPartialInfoModal(order)" *ngIf="order.ReceivedAt == null && !data.isFulfil">-</td>
                    <td *ngIf="type == purchseOrdersService._purchaseType">
                        <button class="btn btn-info" (click)="openPartialPrintModal(order, !isOrderInfo)" [disabled]="!data.isAvailablePrint">Print</button>
                    </td>
                    <td>
                        {{order.Printed}}<a class="has-notify-icon-small" [ngClass]="{printed: order.PrintStatus === 'Printed'}" title="Printed"></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</form>