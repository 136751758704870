<div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
          <br />
          <br />
          <img alt="SimpleRfid" src="./assets/images/internal-logo.png" style="display: block; margin-left: auto; margin-right: auto;" />
      </div>
    </div>
    <br/> 
   
    <div class="main-container">
                            
                  <div class="row">
                      <div class="col-md-10 offset-md-1">
                          <h3>Decline Charge?</h3>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-10 offset-md-1">
                          You must accept the charges from Simple RFID to proceed. If you wish to decline the charges you may press "Decline," or simply press "Back".
                      </div>
                  </div>  
                  <br />
                  <br />
                  <div class="form-group row">   
                      <div class="col-md-1 offset-md-1">
                          <button class="btn btn-primary" type="button" (click)="back()">
                              Back
                          </button>

                      </div>
                      <div class="col-md-1 offset-md-8">
                          <button type="button" class="btn btn-danger" (click)="decline()">
                              Decline
                          </button>
                      </div>
                  </div>
    </div>
  </div>  