import { Component, Input, Output , EventEmitter} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'inventory-print-modal-component',
    template: `
    <div class="modal-header">
        <!--<h4 *ngIf="!isInfo" class="modal-title">Choose products to print!</h4>-->
        <h4 class="modal-title" style="color: #0385f5;">Inventory Print</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-scrollable-container">
            <div *ngFor="let item of items; index as i" class="product-row">
                <div class="container">
                    <div class="box-1 vertical-center">
                        <span>Sku:</span>  
                    </div>
                    <div class="box-2 vertical-center">
                        <span>{{item.Sku}}</span>  
                    </div>
                    <div class="box-3 vertical-center">
                        <span>Quantity:</span>  
                    </div>
                    <div class="box-4">
                        <input type="number" [(ngModel)]="item.printQuantity" class="form-control" min="1" max="item.printQuantity">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="cancel()">Close</button>
        <button type="button" class="btn btn-info" (click)="print()">Print</button>
    </div>
    `,
    styleUrls: ['./inventory-print-modal.component.css'],
  })

  export class InventoryPrintModalComponent{
    @Input() items: Array<any>;
    @Output() passEntry: EventEmitter<Array<any>> = new EventEmitter();
    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(){
        this.items.forEach(i => {
            i.printQuantity = i.CurrentCount - i.NewCount;
        });
    }
    
    print(){
        this.activeModal.close(false);
        this.passEntry.emit(this.items);
    }

    cancel() : void{
        this.items.forEach(i => {
            i.printQuantity = i.CurrentCount - i.NewCount
        });

        this.activeModal.close(false);
    }
  }