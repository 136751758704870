import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthInterceptor, AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { MainComponent } from './main/main.component';
import { SetupWizardComponent } from './setup-wizard/setup-wizard.component';
import { DeclineChargeComponent } from './decline-charge/decline-charge.component';
import { VendLoginComponent } from './vend-login/vend-login.component';
import { FulfilLoginComponent } from './fulfil-login/fulfil-login.component';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const routes: Routes = [
		{
				path: 'setup',
				component: SetupWizardComponent,
				canActivate: [AutoLoginPartialRoutesGuard],
		},
		{
				path: '',
				component: MainComponent,
				canActivate: [AutoLoginPartialRoutesGuard],
		},
		{
				path: 'declineCharge',
				component: DeclineChargeComponent,
				canActivate: [AutoLoginPartialRoutesGuard],
		},
		{
				path: 'vendLogin',
				component: VendLoginComponent,
				canActivate: [AutoLoginPartialRoutesGuard],
		},
		{
				path: 'fulfilLogin',
				component: FulfilLoginComponent,
				canActivate: [AutoLoginPartialRoutesGuard],
		},
		{
			path: 'authentication',
			loadChildren: () => import('./authentication/authentication.module').then(m => m.default),
		},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
	providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
})
export class AppRoutingModule {}
