import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtils, OffsetCollection, OffsetPagination } from 'app/models';
import { Observable, forkJoin, of } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ChunkService {
	private readonly CHUNK_SIZE = 100;
	private readonly MAX_RCORD_COUNT = 15000;
	private httpUtils: HttpUtils = new HttpUtils();

	constructor(private http: HttpClient) {}

	public getAll<T, U>(request: U, url: string, chunkSize = this.CHUNK_SIZE): Observable<OffsetCollection<T>> {
		const params: HttpParams = this.httpUtils.getParams({
			...new OffsetPagination(0, chunkSize),
			...request,
		});
		return this.http.get<T[]>(url, { params: params, observe: 'response' }).pipe(
			switchMap((response: HttpResponse<T[]>) => {
				//TODO: log if totalCount more than MAX_RCORD_COUNT
				const totalCount = Math.min(this.getTotalCount(response), this.MAX_RCORD_COUNT);

				const firstChunk = HttpUtils.toOffsetCollection<T>(response);
				if (totalCount <= chunkSize) {
					return of(firstChunk);
				}

				const pager = new OffsetPagination(chunkSize, totalCount - chunkSize);

				return this.privateGet<T, U>(pager, request, url, chunkSize).pipe(
					map(responses => this.buildOffsetCollection(responses, firstChunk)),
				);
			}),
		);
	}

	public get<T, U>(pager: OffsetPagination, request: U, url: string): Observable<OffsetCollection<T>> {
		return this.privateGet<T, U>(pager, request, url).pipe(
			map(responses => this.buildOffsetCollection(responses, new OffsetCollection<T>(0))),
		);
	}

	private getTotalCount<T>(response: HttpResponse<T[]>): number {
		if (!response.body) {
			throw new Error('Response with empty body');
		}

		const totalCount = response.headers.get('x-pagination-total');

		if (!totalCount) {
			throw new Error('Response with empty x-pagination-total header');
		}
		return +totalCount;
	}

	private buildOffsetCollection<T>(
		responses: HttpResponse<T[]>[],
		initValue: OffsetCollection<T>,
	): OffsetCollection<T> {
		return responses.reduce((acc, cur) => {
			cur.body!.forEach(value => acc.push(value));
			// We can just get the total count once instead of getting it each time
			acc.totalCount = this.getTotalCount(cur);
			return acc;
		}, initValue);
	}

	private privateGet<T, U>(
		pager: OffsetPagination,
		request: U,
		url: string,
		chunkSize = this.CHUNK_SIZE,
	): Observable<HttpResponse<T[]>[]> {
		//If the take field bigger than max chunk size, use chunk size
		const take = pager.take < chunkSize ? pager.take : chunkSize;

		//Calculate total chunks count and skip one
		const takeCount = Math.ceil(pager.take / chunkSize);

		const chunks = Array.from({ length: takeCount }, (elem, index: number) => {
			const params: HttpParams = this.httpUtils.getParams({
				...pager,
				...request,
				...{
					offset: pager.offset + take * index,
					take: take,
				},
			});
			return this.http.get<T[]>(url, { params: params, observe: 'response' });
		});

		return forkJoin(chunks);
	}
}