import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { SigninCallbackComponent } from './components/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './components/signout-callback/signout-callback.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
	{
		path: 'unauthorized',
		component: UnauthorizedComponent,
	},
	{
		path: 'signin-callback',
		component: SigninCallbackComponent,
	},

	{
		path: 'signout-callback',
		component: SignoutCallbackComponent,
	},

	{
		path: 'forbidden',
		component: ForbiddenComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [],
})
export class AuthenticationRoutingModule {}
