import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthService {
	constructor(private oidcSecurityService: OidcSecurityService) {}

	get isLoggedIn() {
		return this.oidcSecurityService.isAuthenticated$;
	}

	get token() {
		return this.oidcSecurityService.getAccessToken();
	}

	get userData() {
		return this.oidcSecurityService.userData$;
	}

	checkAuth() {
		return this.oidcSecurityService.checkAuth();
	}

	doLogin() {
		return of(this.oidcSecurityService.authorize());
	}

	signOut() {
		return this.oidcSecurityService.logoff();
	}
}
