<div class="signin-page-wrapper">
	<div class="signin-container">
		<h1 class="signin-title">Welcome!</h1>
		<div class="animated-img-box">
			<img src="../../../../assets/images/signin-bg-img.svg" alt="" />
			<img class="arrows" src="../../../../assets/images/signin-arrows.svg" alt="" />
		</div>
		<p class="signin-description">We are preparing workspace for you.</p>
	</div>
</div>
