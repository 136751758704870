import { IDataSelectedLocation, RequiredChildsData } from '../core/required-childs-data';
import { BaseHttpService } from '../core/base-http.service';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, AfterViewChecked, DoCheck, Inject, LOCALE_ID } from '@angular/core';
import { ErrorMessageService } from 'app/core/error-message.service';
import { NgbModal, NgbModalOptions, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { LayoutFormatType, LayoutViewModel, PrinterStatus, PrinterViewModel, PrintingService } from 'app/printing/printing.service';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { CurrentShop } from './currentShopModel';
import { UserSettingsInfoService } from 'app/settings/settings.service';
import { NavigationService } from 'app/services';
import { forkJoin } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, AfterViewChecked, DoCheck {
  @ViewChild('content', { static: true }) content: ElementRef;
  printerHungTitle: string;
  isStandalone: boolean;
  locations = [];
  currentLocation: any;
  currentLocationName: string;
  syncWithName: string;
  @ViewChild('nav', { static: false }) private nav: NgbNav;
  tab = { Id: '' };
  imageUrl: string;
  imageClass: string;
  imageBlockClass: string;
  buttonClass: string;
  clientIdentity = '';
  titleClass = 'modal-title';
  data: RequiredChildsData;
  showContent = false;
  public isContentDisabled = false;
  public isPrinterHang = false;
  private connection: any;
  private printerProxy: any;
  public vendorName = '';
  defaultLabelId = 0;
  listPrintingLayouts: LayoutViewModel[];
  conectedPrinted = [];
  isPrinterOnline: boolean;
  isVisibleTab: boolean;
  isNotLocation = false;
  vendorChangeState = false;

  constructor(
    public errorMessageService: ErrorMessageService,
    private modalService: NgbModal,
    private httpService: BaseHttpService,
    private errorModalService: ErrorModalService,
    private printingService: PrintingService,
    private userSettingsService: UserSettingsInfoService,
    private navigateService: NavigationService,
    @Inject(LOCALE_ID) private locale: string) {
      this.data = new RequiredChildsData();

      this.refreshToken();
      setInterval(() => this.refreshToken(), 21 * 60 * 1000);
     }

  async ngOnInit() {

    try {
      // register locale for pipes
      const localeModule = await
      import (
        /* webpackInclude: /(.*)\.js$/ */
        `/node_modules/@angular/common/locales/global/${this.locale}.js`
      );
    } catch {
      console.warn('Translation file error found for Angular pipes.');
    }

    try {
      const shopInfo = await this.httpService.getShopInfoAsync();
      // @ts-ignore TS2339
      this.data.platform = shopInfo.Platform;
      this.data.shopName = shopInfo.Store;
      const userSettings = await this.userSettingsService.getUserSettingsInfo();
      this.data.UseBarcodeAsProductKey = userSettings.UseBarcodeProductKeys;
      this.vendorChangeState = userSettings.isInVendorChangeState;
      this.data.showScans = userSettings.ShowScans;
      this.vendorName = userSettings.VendorName;
      this.data.vendorId = userSettings.VendorId;
      this.data.showPrintersByLocation = userSettings.ShowPrintersByLocation;

      this.isVisibleTab = this.data.isShopify && this.data.UseBarcodeAsProductKey;

      await this.getLocations();
      const locationNotExists = this.data.currentLocationExternalId == null
                        || this.data.currentLocationInternalId == null
                        || this.data.curentlocationType == null;
      if (this.vendorChangeState || locationNotExists) {
        const key = this.httpService.key;
        this.navigateService.toSetupPage(key);
      }
      if (this.data.vendorId) {

        forkJoin({
          printers: this.printingService.getPrinters(this.data.vendorId),
          layouts: this.printingService.getLayouts(this.data.vendorId)
        }).subscribe(res => {
          if (res && res.printers && res.printers.length > 0) {
            this.data.Printers = res.printers;
            this.listPrintingLayouts = res.layouts;
            const onlinePrinter = res.printers.find(p => p.status == PrinterStatus.Online);
            this.data.OnlinePrinter = onlinePrinter || this.data.Printers[0];
            this.data.isAvailablePrint = res.printers.length > 0 && res.layouts.length > 0;
            this.getPrinterOnlineStatus();
            if (this.data.isAvailablePrint) {
              const shops = JSON.parse(window.localStorage.getItem('context'));
              if (shops) {
                shops.forEach(el => {
                  if (this.data.platform == el.platform && this.data.shopName == el.shopName) {
                    const printer = this.data.Printers.find(p => p.id == el.printerId);
                    this.data.defaultPrinterId = printer ? printer.id : this.data.OnlinePrinter.id;
                    this.data.OnlinePrinter = printer ? printer : this.data.OnlinePrinter;

                    this.data.PrintingLayouts = this.getPrintingLayouts();

                    let islabelExist = false;
                    this.data.PrintingLayouts.forEach(l => {
                      if (l.id == el.layoutId) {
                        islabelExist = true;
                      }
                    });
                    this.defaultLabelId = el.layoutId && islabelExist ? el.layoutId :  0;
                    this.data.DefaultLayoutId = this.defaultLabelId;
                  }
                });
              } else {
                this.data.defaultPrinterId = this.data.OnlinePrinter.id;
                this.data.PrintingLayouts = this.getPrintingLayouts();
              }
            }

            if (this.data.isAvailablePrint) {
              const baseUrl = environment.printServiceBaseUrl + '/signalr';
              this.connection = $.hubConnection(baseUrl);
              this.printerProxy = this.connection.createHubProxy('printerListHub');

              this.printerProxy.on('printerStatusChanged', (id, isPrinterOnline, issue) => {
                console.log('signalr printerStatusChanged', id);

                if (this.data.OnlinePrinter && this.data.OnlinePrinter.id == id) {
                  this.data.OnlinePrinter.status = isPrinterOnline
                    ? PrinterStatus.Online
                    : PrinterStatus.Offline;
                  this.data.OnlinePrinter.issueDescription = issue;
                }
              });
              this.getPrinterOnlineStatus();
              this.connection.start().done((data: any) => {
                console.log('Connected to printerhub');
              }).catch((error: any) => {
                  console.log('printerhub error -> ' + error);
              });
            }
          }
        }, err => {
          console.log(err);
          this.errorModalService.open('Error', 'when try get printers or layots');
        });
      }
    } catch (e) {
      console.log(e);
    }

    environment.standaloneAddresses.forEach(element => {
      if (element === location.hostname) {
        this.isStandalone = true;
      }
    });

    if (this.isStandalone && !this.readCookie('accessToken')) {
      if (environment.fulfilAddresses.indexOf(location.hostname) > -1) {
        this.navigateService.toFulfilLoginPage();
      } else {
        this.data.platform = 'Vend';
        this.imageUrl = './assets/images/lightspeed-logo-white-v3.svg';
        this.titleClass = 'modal-title';

        this.open();
      }
    }
  }

  ngDoCheck() {
    this.defaultLabelId = this.data.DefaultLayoutId;
    this.data.printersForView = this.data.showPrintersByLocation ? this.data.Printers.filter(p => p.location == this.data.currentLocationFullName) : this.data.Printers;
    if(this.data.OnlinePrinter){
      this.getPrinterOnlineStatus();
    }
  }

  getPrinterOnlineStatus() {
    this.isPrinterOnline = this.data.OnlinePrinter.status == PrinterStatus.Online;
  }

  setDefaultPrinter() {
    const printer = this.data.Printers.find(p => p.id == this.data.defaultPrinterId);
    if (printer) {
      this.data.OnlinePrinter = printer;
      this.data.PrintingLayouts = this.getPrintingLayouts();
      this.data.DefaultLayoutId = this.getDefaultLayoutId();
      this.setDefaultShopItem();
    } else {
      this.data.defaultPrinterId = this.data.OnlinePrinter.id;
    }
    this.getPrinterOnlineStatus();
  }

  setDefaultLabel(): void {
      this.data.DefaultLayoutId = this.defaultLabelId;
      this.setDefaultShopItem();
  }

  setDefaultShopItem() {
    const shops: Array<CurrentShop> = JSON.parse(window.localStorage.getItem('context'));
    if (shops) {
      shops.forEach(el => {
        if (el.shopName == this.data.shopName && el.platform == this.data.platform) {
          el.printerId = el.printerId != this.data.defaultPrinterId ? this.data.defaultPrinterId : el.printerId;
          el.layoutId = el.layoutId != this.data.DefaultLayoutId ? this.data.DefaultLayoutId : el.layoutId;
        }
      });
      window.localStorage.setItem('context', JSON.stringify(shops));
    }
  }

  async onChanged(data: any) {
    this.isNotLocation = false;
    if (!data.isBind) {
      const shops = JSON.parse(window.localStorage.getItem('context'));
      this.locations = this.locations.filter(i => {
        return i.ExternalId != data.locationId
      });
      if (shops) {
        if (this.locations.length > 0) {
          this.data.selectedLocation = this.buildLocationData(this.locations[0]);
          this.buildLocationData(this.locations[0]);
          shops.forEach(el => {
            if (this.data.platform == el.platform && this.data.shopName == el.shopName) {
              this.currentLocation = this.locations[0];
              el.location = this.locations[0];
              window.localStorage.removeItem('context');
              window.localStorage.setItem('context', JSON.stringify(shops));
            }
          });
        } else {
          this.isNotLocation = true;
        }
      }
    }
    await this.getLocations();
  }

  ngAfterViewChecked(): void {
    if (this.nav) {
        this.printingService.setTabSet(this.nav);
    }
  }

  public async getLocations() {
    let shops = JSON.parse(window.localStorage.getItem('context'));
    if (shops) {
      shops.forEach(el => {
        if (this.data.platform == el.platform && this.data.shopName == el.shopName) {
          this.currentLocation = el.location;
        }
      });
    }

    try {
      const locations = await this.printingService.getLocationsAsync(this.data.vendorId).toPromise();
      this.locations = locations.filter(i => i.IsBound)
      if (this.locations.length == 0) {
        this.currentLocation = null;
      } else {
        if (this.currentLocation && this.currentLocation.Name) {
          this.currentLocationName = this.currentLocation.Name;
          this.syncWithName = this.currentLocationName;
          this.locations.forEach(el => {
            if (this.currentLocation.Name === el.Name) {
              this.currentLocation.ExternalId = el.ExternalId;
              this.currentLocation.InternalId = el.InternalId;
              this.currentLocation.LocationKey = el.LocationKey;
              this.currentLocation.Type = el.Type;
            }
          });
        } else {
          this.currentLocation = locations[0];
          this.currentLocationName = this.currentLocation.Name;
          this.syncWithName = this.currentLocationName;
          const currentShop = new CurrentShop(this.data.shopName, this.data.platform, this.currentLocation);
          if (!shops) {
            shops = new Array<CurrentShop>();
          } else {
            shops = shops.filter(el => {return this.data.platform != el.platform && this.data.shopName != el.shopName});
          }
          shops.push(currentShop);
          window.localStorage.setItem('context', JSON.stringify(shops));
        }
      }

      this.data.selectedLocation = this.buildLocationData(this.currentLocation);
      this.data.currentLocationFullName = `${this.currentLocation.Type}:${this.currentLocation.InternalId}`;
      this.showContent = true;
      this.setChangedLocation(this.locations);
    } catch (e) {
      console.log(e);
    }
  }

  private buildLocationData(currentLocation): IDataSelectedLocation {
    if (!currentLocation) {
      return;
    }

    return {
      externalId: currentLocation.ExternalId,
      internalKey: currentLocation.LocationKey,
      internalId: currentLocation.InternalId,
      internalType: currentLocation.Type
    };
  }

  private setChangedLocation(locations: Array<any>) {
    const loc = locations.filter(i => i.Name == this.currentLocationName)
    if (loc.length == 0 && locations.length > 0) {
      this.setLocation(locations[0].Name)
    }
  }

  readCookie(name: string) {
    const fullName = name + '=';
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let item = cookies[i];

        while (item.charAt(0) == ' ') {
          item = item.substring(1, item.length);
        }

        if (item.indexOf(fullName) == 0) {
          return item.substring(fullName.length, item.length);
        }
    }
    return null;
  }

  open() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true
    };

    this.modalService.open(this.content, ngbModalOptions);
  }

  loginVend() {
    if (this.data.isVend) {
      window.location.href = 'https://secure.vendhq.com/connect?response_type=code&client_id='
        + environment.vendClientId
        + '&redirect_uri='
        + environment.vendRedirectUrl;
    }
  }

  setLocation(locationName) {
    this.locations.forEach(element => {
      if (element.Name == locationName) {
        const shops: Array<CurrentShop> = JSON.parse(window.localStorage.getItem('context'));
        shops.forEach(el => {
          if (el.shopName == this.data.shopName && el.platform == this.data.platform) {
            el.location = element;
          }
        });
        window.localStorage.removeItem('context');
        window.localStorage.setItem('context', JSON.stringify(shops));
        window.location.reload();
        return;
      }
    });
  }

  private refreshToken() {
    this.httpService.refreshToken();
  }

  private getPrintingLayouts(): LayoutViewModel[]{
    return this.data.OnlinePrinter.isNewPrintClient ?
            this.listPrintingLayouts :
            this.listPrintingLayouts.filter(l => l.format == LayoutFormatType.Zpl);
  }

  private getDefaultLayoutId(): number{
    const label = this.data.PrintingLayouts.find(l => l.id == this.defaultLabelId);
    if(label) {
      return this.data.OnlinePrinter.isNewPrintClient ? label.id : label.format == LayoutFormatType.Zpl ? label.id : 0;
    }

    return 0;
  }
}
