// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const vendAddresses = ["venddev.simplerfid.com", "vend.simplerfid.com"];

const fulfilAddresses = ["fulfildev.simplerfid.com", "fulfil.simplerfid.com"];

export const environment = {
  production: true,
  identityBaseAddress: 'https://dev-identity.simplerfid.com',
	portalBaseAddress: 'https://dev-portal-service.simplerfid.com',
	printServiceBaseAddress: 'https://dev-print-service.simplerfid.com',
  integServiceBaseAddress: 'https://integdev.simplerfid.com',
  baseAddress: "https://integdev.simplerfid.com/api/",
  vendClientId: "goFJBgfIblYrOgm04cXKgTOhprGoirpE",
  fulfilClientId: "dbb24b1cbefe9d3c9163",
  vendAddresses: vendAddresses,
  fulfilAddresses: fulfilAddresses,
  standaloneAddresses: vendAddresses.concat(fulfilAddresses),
  vendRedirectUrl:
    "https://integdev.simplerfid.com/api/Vend/GetAccessTokenFromCodeUrl",
  fulfilRedirectUrl:
    "https://integdev.simplerfid.com/api/Fulfil/GetAccessTokenFromCodeUrl",
  printServiceBaseUrl: "https://printsrvdev.simplerfid.com",
  noImageUrl: './assets/images/no-image.jpg',
  getImageUrl: 'https://webdev.simplerfid.com/api/medias/products/images/{imageId}',
  getThumbnailUrl: 'https://webdev.simplerfid.com/api/medias/products/images/{imageId}/thumbnail',
  signalRLogLevel: 3, // TODO: Change to SignalR (typo)
};
