import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

	public toDefaultPage() {
		this.router.navigate(['']);
	}

	public toDefaultPagePreserve() {
		this.router.navigate([''], { queryParamsHandling: 'preserve' });
	}

	public toSetupPage(key: string) {
		this.router.navigate(['/setup'], {queryParams: { key: key }})
	}

	public toFulfilLoginPage() {
		this.router.navigate(['/fulfilLogin']);
	}
}
