import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { CommerceProductModel, CommerceSkuModel, ProductCopyModelRequest, ProductCopyModelResponse, ProductService, ProductsViewModel } from './products.service';
import { PagerService } from 'app/core/PagerService';
import { UrlBuilderService } from 'app/core/url-builder';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { RequiredChildsData } from './../core/required-childs-data';
import {LayoutFormatType, PrinterStatus, PrintingService, SearchProductModel} from 'app/printing/printing.service';
import { ProgressBarDataModel } from '../progress-bar/progress-bar-data-model';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { ProgressBarService } from 'app/progress-bar/progress-bar.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, DoCheck {

  private setVisibilityError = 'changing the visibility';
  private setNoRfIdError = 'setting NoRFId';
  private copyError = 'copy products';
  private setTrakingError = 'setting inventory tracking'

  @Input() data: RequiredChildsData;

  loading: boolean = false;

  products: Array<CommerceProductViewModel> = [];
  pager: any = {};
  allSelected: boolean = false;
  allSelectedSku: boolean = false;
  currentPage: number = 1;

  fulfilPage: number = 1;

  showPopUp: boolean = false;
  popUpMsg: string;

  countOfCheckedProducts = 0;
  countOfCheckedProductsSku = 0;
  
  noRfIdIsSet: boolean = true;
  searchKeyword: string = '';

  isVisible: string = '';
  countToPrint: number = 0;

  nextPageLink: string;
  previousPageLink: string;
  curentPageLink: string;

  isUploadProducts: boolean = false;
  progressBarData: ProgressBarDataModel;
  modifyProduct: boolean = false;
  CanRevert: boolean = false;

  lastProductSku: string;
  progressBarTitle: string = '';
  searchPlaceholder: string = 'Search by Name, Sku...';
  productKey: string = 'Sku';
  duplicateTitle: string;

  lastSelected: number = -1;
  lastSelectedSku: number = -1;
  isPrinterOnline: boolean;

  public get isPrintingEnable() {
    if(!this.data.PrintingLayouts){
      return false;
    }
    const selectedLayout = this.data
      .PrintingLayouts
      .find(l => l.id == this.data.DefaultLayoutId);

      if (!selectedLayout) {
        return false;
      }

     const labelSupport = this.data.OnlinePrinter.isNewPrintClient
      || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.OnlinePrinter.isNewPrintClient)

    return this.countToPrint > 0 && !!selectedLayout && labelSupport && this.isPrinterOnline;
  }

  public get isPrintingEnableTitle() {
    if(!this.data.PrintingLayouts){
      return "No labels";
    }
    const selectedLayout = this.data
    .PrintingLayouts
    .find(l => l.id == this.data.DefaultLayoutId);

    if (!selectedLayout) {
      return "Select label";
    }
    
    if (selectedLayout.format == LayoutFormatType.Sld && !this.data.OnlinePrinter.isNewPrintClient) {
      return 'The Sld label format supported only by new printing';
    }

    if (this.countToPrint <= 0) {
      return 'Select any product';
    }

    if (!selectedLayout) {
      return 'Select label';
    }

    if (!this.isPrinterOnline) {
      return 'Selected printer is offline. Select online printer.';
    }
    
    return 'Run to print selected products';
  }

  constructor( private productsService: ProductService, private pagerService: PagerService,
    private getUrlService: UrlBuilderService, private errorModalService: ErrorModalService,
    private printingService: PrintingService, private confirmModalService: ConfirmModalService,
    private progressBarService: ProgressBarService) { }

  ngOnInit() {
    if(this.data.isVend){
      this.searchPlaceholder = 'Search by Sku...'
    } else if(this.data.isShopify)
    {
      this.searchPlaceholder = 'Search by Name...';
      this.productKey = this.data.UseBarcodeAsProductKey ? "Barcode" : "Sku";
      this.duplicateTitle = `Duplicate ${this.productKey}. Click ${this.productKey} to fix it.`;
    }
    this.progressBarData = new ProgressBarDataModel();
    this.progressBarData.locationExternalId = this.data.currentLocationExternalId;

    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.ImportAllProduct).subscribe(res => {
      this.progressBarData.operationType = ActivityOperationType.ImportAllProduct;
      if(res.IsInProgress){
        this.progressBarTitle = "Import All";
        this.progressBarData.isTaskInProgress = true;
        this.isUploadProducts = true;
      }else{
        if(res.HangfireJobId){
          if(res.StatusJob == "Succeeded"){
            this.progressBarData.hangFireSuccess = true;
          }else if(res.StatusJob == "Failed"){
            this.progressBarData.hangFireFail = true;
            this.progressBarData.errorMessage = "last importing products"
            this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarData.operationType).subscribe(r => {});
          }
        }
      }
    });
    this.getProducts(this.currentPage);
  }

  finishUpload(){
    this.isUploadProducts = false;
    this.progressBarData.isTaskInProgress = false;
    this.getProducts(this.currentPage);
  }

  startProgress(){
    this.isUploadProducts = true;
    this.progressBarData.isTaskInProgress = true;
  }

  ngDoCheck() {
    this.countOfCheckedProducts = 0;
    this.countOfCheckedProductsSku = 0;
    this.countToPrint = 0;
    this.isPrinterOnline = this.data.OnlinePrinter?.status == PrinterStatus.Online;

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    var arrCountVariant = [];
    var arrProductQuantity = this.products.filter(p => p.Selected && p.Quantity > 0 && p.Sku && this.data.isAvailablePrint).map(p => p.Quantity);
    this.products.forEach(p => {
      arrCountVariant = arrCountVariant.concat(
        p.Variants.filter(v => v.Selected && v.Quantity > 0 && this.data.isAvailablePrint && (!p.Selected || !p.Sku)).map(v => v.Quantity));
    });
    var arrQuantity = arrProductQuantity.concat(arrCountVariant);
    this.countToPrint = arrQuantity.length > 0 ? arrQuantity.reduce(reducer): 0;

    this.countOfCheckedProducts = this.products.filter(p => p.Selected && p.Variants.length === 0 || p.Variants.some(v => v.Selected)).length;
    this.countOfCheckedProductsSku = this.products.filter(p => p.SelectedSku && p.Variants.length === 0 || p.Variants.some(v => v.SelectedSku)).length;

    this.allSelected = this.products.length > 0 && this.countOfCheckedProducts === this.products.length;
    this.allSelectedSku = this.products.length > 0 && this.countOfCheckedProductsSku === this.products.length;
    if(this.progressBarData.hangFireSuccess){
      this.progressBarData.hangFireSuccess = false;
      this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarData.operationType).subscribe(r => {});
    }else if(this.progressBarData.hangFireFail){
      this.progressBarData.hangFireFail = false;
      this.errorModalService.open("Error", this.progressBarData.errorMessage);
    }
  }

  print(){
    if(this.data.DefaultLayoutId === 0){
      this.loading = false;
      this.confirmModalService.open("Error", "Need to select a template.", this.getProducts.bind(this.currentPage));
      return;
    }
    this.loading = true;
    this.buildCopyProducts().pipe(
      switchMap(importedProducts => {
        const importedProductsDic = importedProducts.reduce<{[key: string]: ProductCopyModelResponse}>((acc, cur) => {
          acc[cur.PartnerProductId + cur.PartnerVariandId] = cur;
          return acc;
        }, {});
        
        const products = this.products.map(p => {
          if (p.Variants.length > 0) {
            return p.Variants.map(v => {
              return { ProductId: importedProductsDic['' + v.ProductId + v.Id]?.ProductId, Quantity: v.Quantity, Name: p.Name, Sku: v.Sku };
            });
          }
          return [{ ProductId: importedProductsDic['' + p.Id + p.VariantId]?.ProductId, Quantity: p.Quantity, Name: p.Name, Sku: p.Sku }];
        })
        .flat(1)
        .filter(_ => !!_.ProductId && _.Quantity > 0)
        .map<SearchProductModel>(_ => {
          return {
            Name: _.Name,
            ProductId: _.ProductId,
            Quantity: _.Quantity,
            Sku: _.Sku,
          }
        });

        return this.printingService.printProducts(this.data, products);
      })
    ).subscribe(res => {
      this.loading = false;
      this.printingService.goToPrintTab();
    },
    err => {
      this.loading = false;
      this.errorModalService.open('Error', 'print product(s)');
    });
  }

  setPage(page: number, totalPages: number) {
    this.pager = this.pagerService.getPager(this.products.length, page, totalPages);
  }

  getUrl(id: number) {
    window.open(this.getUrlService.getUrltoProduct(id, this.data), '_blank');
  }

  getUrlToProduct(id: number) {
    return this.getUrlService.getUrltoProduct(id, this.data);
  }

  selectAllVariantsInProduct(product: any) {
    product.Variants.forEach(v => {
      v.Selected = product.Selected && v.Sku !== '' && !v.Duplicate;
    });
  }

  selectAllSkuVariantsInProduct(product: any) {
    product.Variants.forEach(v => {
      v.SelectedSku = product.SelectedSku;
    });
  }

  chekSelectedVariantsInProduct(product: any) {
    let variantsCount = product.Variants.filter(v => v.Sku != '').length;
    let countOfSelectedVariants = product.Variants.filter(v => v.Selected).length;
    product.Selected = countOfSelectedVariants === variantsCount;
  }

  chekSelectedSkuVariantsInProduct(product: any) {
    let variantsCount = product.Variants.length;
    let countOfSelectedSkuVariants = product.Variants.filter(v => v.SelectedSku).length;
    product.SelectedSku = countOfSelectedSkuVariants === variantsCount;
  }

  selectAll() {
    this.products.forEach(p => {
      if (!this.allSelected){
        p.Selected = false;
      }
      else{
        p.Selected = p.Variants.length > 0 ? true: p.Sku !== '' && !p.Duplicate;
      }
      this.selectAllVariantsInProduct(p);
    });
    this.allSelected = !this.allSelected;
  }

  selectAllSku() {
    this.products.forEach(p => {
      p.SelectedSku = this.allSelectedSku;
      this.selectAllSkuVariantsInProduct(p);
    });
    this.allSelected = !this.allSelected;
  }


  getProducts(page: number) {
    let pageLink = null;

    if(this.searchKeyword === '') {
      this.loading = true;
    }

    if(this.data.isFulfil || this.data.isShopify){
      if(this.fulfilPage > page && page != 1){
        pageLink = this.previousPageLink;
        this.curentPageLink = pageLink;
      }
      else if(this.fulfilPage < page && page != 1){
        pageLink = this.nextPageLink;
        this.curentPageLink = pageLink;
      }
      else if(this.fulfilPage === page && page != 1){
        pageLink = this.curentPageLink;
      }

      this.fulfilPage = page;
    }

    let re = /\,/gi;
    this.productsService.getProducts(page, this.searchKeyword, this.isVisible, pageLink).subscribe((res: ProductsViewModel) => {
      this.products = res.Products.map(this.productToViewModel.bind(this));
      this.products.forEach(p => {p.Duplicate = false;});
      this.nextPageLink = res.NextPageLink;
      this.previousPageLink = res.PreviousPageLink;
      this.setPage(page, res.CountOfPages);

      this.products.forEach(p => {
        this.setNoRfIdEnabled(p);
        if(this.data.isShopify){
          this.setDuplicateProduct(p);
        }
        p.IsVisibleActiv = true;
        p.Sku = p.Sku != null ? p.Sku : "";
        p.Barcode = p.Barcode != null ? p.Barcode : "";
        p.Price = p.Price != null ? p.Price.replace(re, '.') : null;
      });
      this.currentPage = page;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open("Error", 'loading products');
    });
  }

  private productToViewModel(model: CommerceProductModel): CommerceProductViewModel {
    return {
      ...model,
      ImageUrl: model.Variants.length == 0 ? model.ImageUrl ?? environment.noImageUrl : null,
      Selected: false,
      SelectedSku: false,
      Duplicate: false,
      IsVisibleActiv: false,
      Variants: model.Variants.map<CommerceSkuViewModel>(variant => {
        return {
            ...variant,
            ImageUrl: variant.ImageUrl ?? environment.noImageUrl,
            Selected: false,
            SelectedSku: false,
            Duplicate: false,
        }
      })
    }
  }

  private setDuplicateProduct(product){
    this.products.forEach(p => {
      if(product.Sku){
        if(product.Sku === p.Sku && product.Id != p.Id){
          product.Duplicate = true;
          p.Duplicate = true;
        }
        if(p.Variants.length > 0){
          p.Variants.forEach(v => {
            if(v.Sku){
              if(product.Sku === v.Sku){
                product.Duplicate = true;
                p.Duplicate = true;
                v.Duplicate = true;
              }
            }
          });
        }
      }
      if(product.Variants.length > 0){
        product.Variants.forEach(v => {
          v.Duplicate = false;
          if(v.Sku){
            this.setDuplicateVariant(product, v);
          }
        });
      }
    });
  }

  private setDuplicateVariant(product, variant){
    this.products.forEach(p => {
      if(p.Sku && p.Sku === variant.Sku){
        p.Duplicate = true;
        product.Duplicate = true;
        variant.Duplicate = true;
      }
      if(p.Variants.length > 0){
        p.Variants.forEach(v => {
          if(v.Sku && v.Sku === variant.Sku && v.Id != variant.Id){
            p.Duplicate = true;
            v.Duplicate = true;
            product.Duplicate = true;
            variant.Duplicate = true;
          }
        });
      }
    });
  }

  private setNoRfIdEnabled(product){
    if(this.data.isBigCommerce || this.data.isShopify){
      let res = true;

      if(product.Variants.length != 0){
        product.Variants.forEach(v => {
          if(v.Sku == ''){
            res = false;
          }
        });
        if(!res){ product.Title = "Set Sku to all Variants and cick button 'Refresh'"; }
        product.noRfIdEnabled = res;
      }
    }
    if(product.Sku === ''){
      product.Title = "Set Sku to product and cick button 'Refresh'";
    }
    product.noRfIdEnabled = product.Sku != '';
  }

  setVisibility(product: any) {
    product.IsVisibleActiv = false;
    const data = {
      productId: product.Id,
      isVisible: product.IsVisible
    }

    this.productsService.setVisibility(data).subscribe(res => {
      if(!res) {
        this.errorModalService.open('Error', this.setVisibilityError);
      } else {
        product.IsVisibleActiv = true;
      }
    },
    err => {
      this.errorModalService.open('Error', this.setVisibilityError);
      product.IsVisibleActiv = true;
    })
  }

  buildProductsToSaveList(): ProductCopyModelRequest[] {
    const productsToSave: ProductCopyModelRequest[] = [];
    this.products.forEach(p => {
      const variantIds: number[] = [];
      p.Variants
        .filter(v => v.Selected)
        .filter(v => (this.data.UseBarcodeAsProductKey && v.Barcode !== '') || (v.Sku !== ''))
        .forEach(v => {
          variantIds.push(v.Id);
          v.Selected = false;
      });

      if (p.Selected || variantIds.length > 0) {
        productsToSave.push({
          ProductId: p.Id,
          VariantIds: variantIds
        });
        p.Selected = false;
      }
    });

    return productsToSave;
  }

  buildProductForModify(): ProductCopyModelRequest[] {
    const productsToSave: ProductCopyModelRequest[] = [];
    this.products.forEach(p => {
      const variantIds = [];
      p.Variants
        .filter(_ => _.SelectedSku)
        .forEach(v => {
          variantIds.push(v.Id);
          v.SelectedSku = false;
        });

      if (p.SelectedSku || variantIds.length > 0) {
        productsToSave.push({
          ProductId: p.Id,
          VariantIds: variantIds
        });
        p.SelectedSku = false;
      }
    });
    return productsToSave;
  }

  copyProducts() {
    this.loading = true;
    this.buildCopyProducts().subscribe(res => {
      if (res) {
        this.refresh();
      } else {
        this.loading = false;
        this.errorModalService.open("Error", this.copyError);
      }
    },
    err => {
      this.loading = false;
      this.errorModalService.open("Error", this.copyError);
    });
  }

  private buildCopyProducts(): Observable<ProductCopyModelResponse[]> {
    const productsToSave = this.buildProductsToSaveList();
    return this.productsService.copyProducts(productsToSave);
  }

  setInventoryTracking(product: any, variantId: number){
    const data = {
      ProductId: product.Id,
      VariantId: product.VariantId !== null ? product.VariantId : variantId,
      CountOfVariants: product.Variants.length
    }

    this.productsService.setInventoryTracking(data).subscribe(res => {
      if (!res) {
        this.errorModalService.open('Error', this.setTrakingError);
      } else {
        if (product.VariantId === null && variantId) {
          let variant = product.Variants.find(v => v.Id === variantId);
          variant.IsInventoryTrackingVariant = true;
        }

        product.IsInventoryTrackingProduct = true;
      }
    }),
    err => {
      this.errorModalService.open('Error', this.setTrakingError);
    }
  }

  refresh() {
    this.getProducts(this.currentPage);
  }

  closePopUp() {
    this.showPopUp = false;
  }

  copyUpdateProducts(create: boolean){
    this.progressBarTitle = "Import All";
    this.isUploadProducts = true;
    this.progressBarData.operationType = ActivityOperationType.ImportAllProduct;
    this.progressBarData.loadOnHandQuantity = true;
    setTimeout(() => this.progressBarData.isTaskInProgress = true, 500);

    this.productsService.copyUpdateAllProducts(this.data.currentLocationExternalId).subscribe(res => {
    },
    err => {
      this.loading = false;
      this.errorModalService.open("Error", this.copyError);
    });
  }

  modifyUniqueField(){
    const productsToSave = this.buildProductForModify();
    this.progressBarTitle = `Editing duplicate ${this.productKey}`;
    this.isUploadProducts = true;
    this.progressBarData.isTaskInProgress = true;
    this.progressBarData.operationType = ActivityOperationType.ModifyProduct;
    let data = {
      Products: productsToSave,
      ExternalLocationId: this.data.currentLocationExternalId
    };
    this.productsService.modifyUniqueField(data).subscribe(res => {
      this.progressBarData.isTaskInProgress = true;
      this.loading = false;
      if(!res){
        this.errorModalService.open("Error", 'Editing duplicate');
      }
      this.refresh();
    });
  }

  revertModifyUniqueField(){
    this.progressBarTitle = `Revert Editing duplicate ${this.productKey}`;
    this.isUploadProducts = true;
    this.loading = false;
    this.progressBarData.isTaskInProgress = true;
    this.progressBarData.operationType = ActivityOperationType.RevertHistory;
    this.productsService.revertModifyAllUniqueField(this.data.currentLocationExternalId).subscribe(res => {
      this.loading = false;
      this.isUploadProducts = false;
      if(!res){
        this.errorModalService.open("Error", 'revert Editing duplicate.');
      }
      this.refresh();
    });
  }

  confirmToModifyAll(modify) {
    var selectQuantity = "all"
    if(this.countOfCheckedProductsSku > 0){
      selectQuantity = this.countOfCheckedProductsSku.toString();
    }
    modify ? this.confirmModalService.open("Confirm", `Edit Duplicate "${this.productKey}".
    Click Ok to edit the ${selectQuantity} duplicate "${this.productKey}" to make them unique.
    A duplicate item will be appended with .1 for the first duplicate, .2 for the second duplicate, and so on.
    After editing you may choose to Revert your changes, however you can only revert the last changes.`
    , this.modifyUniqueField.bind(this)):
             this.confirmModalService.open("Confirm", `Please confirm revert modify ${this.productKey}.`, this.revertModifyUniqueField.bind(this));
  }

  toggleSelected(index, event) {
    if (event.shiftKey) {
      if (this.lastSelected < 0) {
        this.lastSelected = index;
      } else{
        var min = this.lastSelected > index ? Math.min(index, this.lastSelected) + 1: Math.min(index, this.lastSelected);
        var max = Math.max(index, this.lastSelected);
        var checked = this.products[this.lastSelected].Selected;
        this.products
        .slice(min, max)
        .forEach(item => {
          if(!item.Duplicate){
            item.Selected = checked;
          }
          item.Variants.forEach(variant => {
            if(variant.Sku !== '' && !variant.Duplicate){
              variant.Selected = item.Selected;
            }
          });
        });
        this.lastSelected = -1;
      }
    } else {
      this.lastSelected = -1;
    }
  }

  toggleSelectedSku(index, event) {
    if (event.shiftKey) {
      if (this.lastSelectedSku < 0) {
        this.lastSelectedSku = index;
      } else{
        var min = this.lastSelectedSku > index ? Math.min(index, this.lastSelectedSku) + 1: Math.min(index, this.lastSelectedSku);
        var max = Math.max(index, this.lastSelectedSku);
        var checked = this.products[this.lastSelectedSku].SelectedSku;
        this.products
          .slice(min, max)
          .forEach(item => {
            item.SelectedSku = checked;
            item.Variants.forEach(variant => variant.SelectedSku = item.SelectedSku);
          });
        this.lastSelectedSku = -1;
      }
    } else {
      this.lastSelectedSku = -1;
    }
  }
}


interface CommerceProductViewModel {
  Id: string
  VariantId?: number
  Name: string
  Sku: string
  Price: string
  Quantity: number
  IsVisible: boolean
  IsInventoryTrackingProduct: boolean
  Variants: CommerceSkuViewModel[]
  IsImported: boolean
  Barcode: string
  ImageUrl: string

  Selected: boolean
  SelectedSku: boolean
  Duplicate: boolean
  IsVisibleActiv: boolean
}

interface CommerceSkuViewModel {
  Id: number
  ProductId: number
  Sku: string
  Quantity: number
  Properties: string
  IsInventoryTrackingVariant: boolean
  IsImported: boolean
  Barcode: string
  Price: string
  ImageUrl: string

  Selected: boolean
  SelectedSku: boolean
  Duplicate: boolean
}