import { HttpParams, HttpResponse } from '@angular/common/http';

export class OffsetCollection<T> extends Array<T> {
	totalCount: number;
	constructor(totalCount: number) {
		super();
		this.totalCount = totalCount;
	}
}

export class OffsetPagination {
	public offset: number;
	public take: number;

	constructor(offset: number, take: number) {
		this.offset = offset;
		this.take = take;
	}

	static Default() {
		return new OffsetPagination(0, 100);
	}

	static First() {
		return new OffsetPagination(0, 1);
	}

	static None() {
		return new OffsetPagination(0, 100000);
	}
}

export class HttpUtils {
	public getParams(paramsObject: object): HttpParams {
		return new HttpParams({ fromObject: { ...paramsObject } });
	}

	public static toOffsetCollection<T>(response: HttpResponse<T[]>): OffsetCollection<T> {
		const totalCount = response.headers.get('x-pagination-total');
		const array = response.body ?? [];
		const result = array as OffsetCollection<T>;
		result.totalCount = +(totalCount ?? 0);
		return result;
	}
}
