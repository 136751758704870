import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';
import { RevertDataModel } from './revert-data-model';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { SyncHistoryViewResponce } from 'app/models/sync-history/classes';
import { environment } from 'environments/environment';

@Injectable()
export class SyncHistoryService extends BaseHttpService {

  getSyncHistory( page: number, currentLocationExternalId: string, operation: ActivityOperationType): Observable<SyncHistoryViewResponce> {
    var requestParam = '?page=' + page + '&operation=' + operation;
    if (currentLocationExternalId) {
      requestParam += '&externalLocationId=' + currentLocationExternalId;
    }
    return this.get(urls.getSyncHistoryUrl + requestParam);
  }

  revertInventory(id, currentLocationExternalId: string, operation: ActivityOperationType): Observable<any> {
    let data = new RevertDataModel();
    data.id = id;
    data.locationExternalId = currentLocationExternalId;

    if(operation == ActivityOperationType.SyncInventory){
      return this.post(urls.enqueuedRevertItemUrl, data);
    }else{
      return this.post(urls.shopify.enqueuedRevertOnHandItemUrl, data);
    }
  }

  getSyncReport(id: number, viewOrders: boolean = false): Observable<Blob> {
    const url = environment.baseAddress +  urls.getSyncReportUrl;
    const params = {
      id: id,
      viewOrders: viewOrders
    };

    return this.http.get(url, {
      headers: this.getRequestHeaders(),
      params: params,
      responseType: 'blob'
    });
  }
}
