/* tslint:disable:member-ordering */
import { LocationType } from 'app/models';
import { LocationTypes } from 'app/models/locations/enum';
import { LayoutViewModel, PrinterViewModel } from 'app/printing/printing.service';

export class RequiredChildsData{
    public vendorId: number;

    public isAvailablePrint: boolean;

    public get currentLocationExternalId(): string | undefined {
      return this.selectedLocation?.externalId;
    };

    public currentLocationInternalId(): number | undefined {
      return this.selectedLocation?.internalId;
    };

    public selectedLocation?: IDataSelectedLocation;

    public curentlocationType(): LocationType | undefined {
      return this.selectedLocation?.internalType;
    };

    public platform: string;

    public PrintingLayouts: Array<LayoutViewModel> = [];

    public Printers: Array<PrinterViewModel> = [];

    public shopName: string;

    public OnlinePrinter: PrinterViewModel;

    public DefaultLayoutId: number;

    public UseBarcodeAsProductKey: boolean;

    public get isBigCommerce(): boolean{
        return this.platform === 'BigCommerce';
    }

    public get isShopify(): boolean{
        return this.platform === 'Shopify';
    }

    public get isFulfil(): boolean{
        return this.platform === 'Fulfil';
    }

    public get isVend(): boolean{
        return this.platform === 'Vend';
    }

    public showScans: boolean;

    public agreeUseMyData: boolean;
    public agreeUseCastomerData: boolean;
    public showPrintersByLocation: boolean;
    public printersForView: PrinterViewModel[] = [];
    public defaultPrinterId: any;
    public currentLocationFullName: string;
}

export interface IDataSelectedLocation {
  externalId: string;

  internalKey: string;
  internalId: number;
  internalType: LocationType;
}

export interface ILightLocation {
  type: LocationType,
  id: number,
  name?: string,
  vendorId: number,
  locationKey?: string
}

export interface IntegLocation {
  Id: number
  Name: string
  ExternalId: string
  Address: string
  Type: string
  InternalId?: number
  FulfilStorageLocationId?: number
  IsBound: boolean
}

export interface IntegPrintStationResponse {
  Id: string,
  ComputerName: string,
  SelectedPrinterOnline: boolean,
  LocationId?: number,
  LocationType?: LocationTypes,
  LocationName?: string,
  Printers: IntegPrinterResponse[],
  SelectedPrinter: IntegPrinterResponse,
}

export interface IntegPrinterResponse {
  Name: string,
  SerialNumber: string,
  Issue: boolean,
  Dpi: number,
}

