import { Injectable } from '@angular/core';
import {forkJoin, lastValueFrom, Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { urls } from 'app/core/urls';
import { ErrorMessageService } from 'app/core/error-message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationService } from 'app/services';
import {ILocationRequest, IntegLocationModel, IntegLocationResponse, LightLocation} from '../models/locations/interfaces';
import { ChunkService } from './chunk-service';
import { ILayoutRequest, OffsetCollection } from 'app/models';
import { IPortalLayoutResponse } from 'app/printing/printing.service';

@Injectable()
export class BaseHttpService {
  private readonly unauthorizedMessage = 'Auth token is expired. Please refresh the app.';
  public key: string;
  private isStandalone: boolean;
  http: HttpClient;
  public isAvailablePrint: boolean;

  constructor(http: HttpClient, private activatedRoute: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private navigationService: NavigationService,
    private chunkService: ChunkService) {
    this.http = http;
    const a = location;
    this.checkIsStandalone();
    if (this.isStandalone) {
      this.saveKey(activatedRoute);
    } else {
      this.key = activatedRoute.snapshot.queryParams['key'];
    }
  }

  private checkIsStandalone() {
    environment.standaloneAddresses.forEach(element => {
      if (element === location.hostname) {
        this.isStandalone = true;
      }
    });
  }

  private checkIsFulfil() {
    return environment.fulfilAddresses.some(item => item === location.hostname);
  }

  private redirectToApp() {
    // TODO: Move to the navigation service
    location.href = location.origin + '/app/';
  }

  private saveKey(activatedRoute: ActivatedRoute) {
    if (!this.key) {
      this.key = activatedRoute.snapshot.queryParams['key'];
      if (this.key) {
        window.localStorage.setItem('key', this.key);
        // TODO: Move this check to the navigation service
        if (location.href.indexOf('/setup?key=') == -1) {
          window.localStorage.setItem('needToLogin', 'true');
          this.redirectToApp();
        }
      } else {
        this.key = window.localStorage.getItem('key');
      }
    }
  }

  public async getShopInfoAsync() {
    return this.getAsync(urls.getShopInfo);
  }

  public async getLicenseAgreement() {
    return this.getAsync(urls.getLicenseAgreement);
  }

  public getKey() {
    return this.key;
  }

  private getRequestOptionsArgs(options?: any): any {
    if (options == null) {
      options = {};
    }

    if (options.headers == null) {
      options.headers = new HttpHeaders();
    }

    options.headers = options.headers
      .set('Content-Type', 'application/json')
      .set('auth_token', this.key);

    return options;
  }

  protected getRequestHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'auth_token': this.key
    });
  }

  private handleUnauthorized(res: Response, error: CustomError = undefined): Response {
    if (res.status === 401) {
      localStorage.setItem('needToLogin', JSON.stringify(true));

      if (this.checkIsFulfil()) {
        this.navigationService.toFulfilLoginPage();
      }

      if (!this.isStandalone) {
        this.errorMessageService.setErrorWithDetails(this.unauthorizedMessage, res);
        throw new Error(this.unauthorizedMessage);
      }
    } else if (res.status < 200 || res.status >= 300) {
      if(error.ErrorCode && error.ErrorCode > 0){
        console.log(`Error: code-${error.ErrorCode}, message-${error.Message}`);
        throw new Error(error.Message);
      }
      console.log(`The request has failed (${res.status})`);
      throw new Error('Error during get data');
    }

    return res;
  }

  private getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public refreshFulfilToken(): Observable<any> {
    const token = this.http
      .get(environment.baseAddress + urls.fulfilRefreshToken)
      .pipe(
        catchError((res) => {
          this.handleUnauthorized(res);
          throw new Error('Error during get data');
        }));

      return token;
  }

  private checkIfFulfilTokenExpired() {
    const data = localStorage.getItem('needToLogin');
    const needToLogin: boolean = data ? JSON.parse(data) : true;

    if (needToLogin && this.checkIsFulfil()) {
      const token = this.getCookie('accessToken');
      if (!token) {
        this.goToLogin(needToLogin);
      }
    }
  }

  private goToLogin(needToLogin: boolean) {
   if (needToLogin && location.href.indexOf('#/?key=') == -1) {
      needToLogin = false;
      localStorage.setItem('needToLogin', JSON.stringify(needToLogin));
      this.redirectToApp();
    }
  }

  protected get(url: string, options?: any): Observable<any> {
    this.checkIfFulfilTokenExpired();
    return this.http.get(environment.baseAddress + url,
      this.getRequestOptionsArgs(options))
      .pipe(catchError((res) => {
        this.handleUnauthorized(res, res.error);
        throw new Error('Error during get data');
      }));
  }

  protected get_generic<TResult>(url: string, options?: any): Observable<TResult> {
    this.checkIfFulfilTokenExpired();
    return this.http.get<TResult>(environment.baseAddress + url, {
      headers: {
        'auth_token': this.key
      },
      responseType: 'json'
    }).pipe(catchError((res) => {
        this.handleUnauthorized(res);
        throw new Error('Error during get data');
      }));
  }

  protected post(url: string, body: any, options?: any): Observable<any> {
    this.checkIfFulfilTokenExpired();
    return this.http.post(environment.baseAddress + url, body,
      this.getRequestOptionsArgs(options)).pipe(catchError((res) => {
        this.handleUnauthorized(res);
        throw new Error('Error during post data');
      }));
  }

  protected put(url: string, body: any, options?: any): Observable<any> {
    this.checkIfFulfilTokenExpired();
    return this.http.put(environment.baseAddress + url, body,
      this.getRequestOptionsArgs(options)).pipe(catchError((res) => {
        this.handleUnauthorized(res);
        throw new Error('Error during put data');
      }));
  }

  protected delete(url: string, options?: any): Observable<any> {
    this.checkIfFulfilTokenExpired();
    return this.http.delete(environment.baseAddress + url,
      this.getRequestOptionsArgs(options)).pipe(catchError((res) => {
        this.handleUnauthorized(res);
        throw new Error('Error during delete data');
      }));
  }


  protected async getAsync(url: string, options?: any) {
    this.checkIfFulfilTokenExpired();
    return lastValueFrom<any>(
      this.http.get(environment.baseAddress + url, this.getRequestOptionsArgs(options))
      .pipe(catchError((res) => {
        this.handleUnauthorized(res);
        throw new Error('Error during get data');
      }))
    );
  }

  protected async postAsync(url: string, body: any, options?: any) {
    this.checkIfFulfilTokenExpired();
    return lastValueFrom(
      this.http.post(environment.baseAddress + url, body, this.getRequestOptionsArgs(options))
      .pipe(catchError((res) => {
        this.handleUnauthorized(res)
        throw new Error('Error during post data');
      }))
    );
  }

  protected async putAsync(url: string, body: any, options?: any) {
    this.checkIfFulfilTokenExpired();
    return lastValueFrom(
      this.http.put(environment.baseAddress + url, body, this.getRequestOptionsArgs(options))
      .pipe(catchError((res) => {
        this.handleUnauthorized(res)
        throw new Error('Error during put data');
      }))
    );
  }

  protected async deleteAsync(url: string, options?: any) {
    this.checkIfFulfilTokenExpired();
    return lastValueFrom(
      this.http.delete(environment.baseAddress + url, this.getRequestOptionsArgs(options))
      .pipe(catchError((res) => {
        this.handleUnauthorized(res);
        throw new Error('Error during delete data');
      }))
    );
  }

  public async getPrinterNameAsync(locationExternalId: string) {

    let requestParam = '';

    if (locationExternalId) {
      requestParam += '?locationId=' + locationExternalId;
    }

    return this.getAsync(urls.getPrinterNameUrl + requestParam);
  }

  getBindLocations(type): Observable<any> {
    return this.get(urls.getBindLocationsUrl + '?type=' + type);
  }

  getLocationFromContext(shopName, platform) {
    let location: any;
    const context = JSON.parse(window.localStorage.getItem('context'));
    if (context) {
      context.forEach(el => {
        if (el.shopName == shopName && el.platform == platform) {
          location = el.location;
        }
      });
    }

    return location;
  }

  public async GetVendorChangeStateAsync() {
    return this.getAsync(urls.isInVendorChangeState);
  }

  public getLocationsAsync(vendorId: number): Observable<IntegLocationModel[]> {
    const chunkSize = 1000;
    const getIntegLocations = this.http.get<IntegLocationResponse[]>(environment.baseAddress + urls.getLocationsUrl, {
      headers: this.getRequestHeaders()
    });

    const request: ILocationRequest = {
      vendorId: vendorId,
    };

    const getPortalLocations = this.getManyLocations(request, chunkSize);

    return forkJoin({
      integ: getIntegLocations,
      portal: getPortalLocations,
    }).pipe(
      map(data => {
        const portal: {[key: string]: LightLocation} = data.portal.reduce((acc, cur) => {
          const key = (cur.id + cur.type).toLowerCase();
          acc[key] = cur;
          return acc;
        }, {})
        return data.integ.filter(_ => !!_.Type)
          .map(integ => {
          return {
            ...integ,
            LocationKey: portal[(integ.InternalId + integ.Type).toLowerCase()]?.locationKey
          }
        })
      })
    );
  }

  public refreshToken(): void {
    const options = {
      responseType: 'text'
    };

    this.post(urls.refreshTokenUrl, '', options)
    .subscribe(res => {
      this.key = res;
    }, err => {
      if (!this.isStandalone)
        this.errorMessageService.setErrorWithDetails(this.unauthorizedMessage, err);
    });
  }

  public getManyLayouts(url:string, request: ILayoutRequest, chunkSize?: number): Observable<OffsetCollection<IPortalLayoutResponse>> {
		return this.chunkService.getAll<IPortalLayoutResponse, ILayoutRequest>(request, url, chunkSize);
	}

  private getManyLocations(request: ILocationRequest, chunkSize?: number): Observable<OffsetCollection<LightLocation>> {
		const url = environment.portalBaseAddress + '/api/v1/locations';
		return this.chunkService.getAll<LightLocation, ILocationRequest>(request, url, chunkSize);
	}
}

export interface CustomError{
  ErrorCode: number,
  Message: string
} 