import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';

@Injectable()
export class ProductService extends BaseHttpService {

  getProducts( page: number, keyword: string, isVisible: string, pageLink: string): Observable<ProductsViewModel> {
    let pageLinkUriComponent = '';

    if(pageLink != null){
      pageLinkUriComponent = '&pageLink=' + encodeURIComponent(pageLink);
    }

    return this.get(urls.getProductsUrl + '?page=' + page + '&keyword=' + encodeURIComponent(keyword) + '&isvisible=' + isVisible + pageLinkUriComponent)
      ;
  }

  copyProducts(data: ProductCopyModelRequest[]): Observable<ProductCopyModelResponse[]> {
    return this.post(urls.copyProductsUrl, data);
  }

  setVisibility(data: any): Observable<any> {
    return this.post(urls.setVisibility, data);
  }

  setInventoryTracking(data: any): Observable<any> {
    return this.post(urls.setInventoriTracking, data);
  }

  copyUpdateAllProducts(externalLocationId: string): Observable<any> {
    return this.post(urls.copyUpdateAllProducts + '?externalLocationId=' + externalLocationId, {});
  }

  modifyUniqueField(data: any): Observable<any> {
    return this.post(urls.ModifyUniqueField, data);
  }

  revertModifyAllUniqueField(externalLocationId: string): Observable<any> {
    return this.get(urls.RevertModifyAllUniqueField + '?externalLocationId=' + externalLocationId)
      ;
  }
}

export interface ProductsViewModel {
  Products: CommerceProductModel[]
  Page: number
  CountOfPages: number
  NextPageLink: string
  PreviousPageLink: string
  CanRevert: boolean
}

export interface CommerceProductModel {
   Id: string
   VariantId?: number
   Name: string
   Sku: string
   Price: string
   Quantity: number
   IsVisible: boolean
   IsInventoryTrackingProduct: boolean
   Variants: CommerceSkuModel[]
   IsImported: boolean
   Barcode: string
   ImageUrl: string
}

export interface CommerceSkuModel{
  Id: number
  ProductId: number
  Sku: string
  Quantity: number
  Properties: string
  IsInventoryTrackingVariant: boolean
  IsImported: boolean
  Barcode: string
  Price: string
  ImageUrl: string
}

export interface ProductCopyModelRequest{
  ProductId: string
	VariantIds: number[]
}

export interface ProductCopyModelResponse{
  PartnerProductId: string
  PartnerVariandId: number
  ProductId: number
}