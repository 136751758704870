import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NotifyModalComponent } from './notify-modal.component';

@Injectable()
export class ConfirmModalService {
  constructor(private modalService: NgbModal) {}

  open(title: string, message: string, callback: Function, confirmButton: string = 'OK', cancelButton: string = 'Cancel') {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true
    };

    const modalRef = this.modalService.open(NotifyModalComponent, ngbModalOptions);

    modalRef.result.then((result) => {
        if (result) {
            callback();
        }
        console.log("closed " + result);
    }, (reason) => {
        console.log("dismissed" );
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.isConfirmation = true;
    modalRef.componentInstance.confirmButton = confirmButton;
    modalRef.componentInstance.cancelButton = cancelButton;
  }
}