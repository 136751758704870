<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header header-column">
    <div class="full-modal-width header-box-1">
      <div class="simplerfid-icon-block">
          <img style="margin-top: 0; width: -webkit-fill-available;" src="./assets/images/internal-logo.svg" class="logo" />
      </div>
      <div class="vend-modal-icon-block {{imageBlockClass}}">
        <img [src]="imageUrl" class="{{imageClass}}">
      </div>
    </div>
    <div class="header-box-2">
      <h3>You are logged out, please sign in</h3>
    </div>
  </div>
  <div class="modal-body centered-content">
    <!--h5 class="{{titleClass}}" id="modal-basic-title">You are logged out, please sign in</h5-->
    <div class="form-group">
      <button class="btn vend-btn {{buttonClass}}" (click)="login()">Sign in</button>
    </div>
  </div>
</ng-template>
