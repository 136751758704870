import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';

@Injectable()
export class UserSettingsInfoService extends BaseHttpService {

  public async getUserSettingsInfo(){
    return this.getAsync(urls.getUserSettingsInfo);
  }

  updateUserSettingsInfo(data): Observable<any> {
    return this.post(urls.updateUserSettingsInfo, data);
  }

  getAllLocations(): Observable<any> {
    return this.get(urls.getAllLocationsUrl);
  }

  getLocation(shopName: string, platform: string): Observable<any> {
    var currentLocation = this.getLocationFromContext(shopName, platform);
    var requestParam = '?locationId=';
    if (currentLocation) {
      requestParam += currentLocation.ExternalId;
    }
    return this.get(urls.getLocationUrl + requestParam);
  }

  getWebHookSettings(data): Observable<any> {
    const webHook = this.getWebHookSettingsUrl(data);
    return this.get(webHook.getWebHook);
  }

  SubscriptionToProductCreateAndUpdateWebHook(data): Observable<any> {
    const webHook = this.getWebHookSettingsUrl(data);
    return this.post(webHook.subscribe.createAndUpdateProduct, {});
  }

  UnsubscriptionToProductCreateAndUpdateWebHook(data): Observable<any> {
    const webHook = this.getWebHookSettingsUrl(data);
    return this.post(webHook.unsubscribe.createAndUpdateProduct, {});
  }

  SubscriptionToOrderCreateWebHook(data): Observable<any> {
    const webHook = this.getWebHookSettingsUrl(data);
    return this.post(webHook.subscribe.createOrder, {});
  }

  UnsubscriptionToOrderCreateWebHook(data): Observable<any> {
    const webHook = this.getWebHookSettingsUrl(data);
    return this.post(webHook.unsubscribe.createOrder, {});
  }

  saveLocations(locations, currentVendor, useBarcodesAsProductKeys = false): Observable<any> {
    let req = {
      locations: locations,
      useBarcodesAsProductKeys: useBarcodesAsProductKeys,
      vendorId: currentVendor
    };
    const data = JSON.stringify(req);
    return this.post(urls.saveLocationsUrl, data);
  }

  bindLocation(location): Observable<any> {
    return this.post(urls.bindLocationUrl, location);
  }

  unBindLocation(location): Observable<any> {
    return this.post(urls.unBindLocationUrl, location);
  }

  changeVendor(setChangeState: boolean): Observable<any> {
    return this.post(urls.changeVendor, setChangeState);
  }

  getSubLocations(): Observable<any> {
    return this.get(urls.getSubLocations);
  }

  setSubLocation(locationExternalId: string, sublocationId: string){
    return this.post(urls.setSubLocation + "?locationExternalId=" + locationExternalId + "&subLocationId=" + sublocationId, null);
  }

  setSubmitOrder(data): Observable<any> {
    return this.post(urls.shopify.setOrderSubmit, data);
  }

  getWebHookSettingsUrl(data) {
    if(data.isShopify){
      return urls.shopify.webHook;
    }else if(data.isBigCommerce){
      return urls.bigCommerce.webHook;
    }
    throw new Error(`Webhooks are not supported for this platform`);
 }

  changeOpenOrdersAgeDays(data): Observable<any> {
    return this.post(urls.changeOpenOrdersAgeDays, data);
  }
}
