import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				postLoginRoute: '',
				forbiddenRoute: '/authentication/forbidden',
				unauthorizedRoute: '/authentication/unauthorized',
				historyCleanupOff: false,
				authority: environment.identityBaseAddress,
				redirectUrl: `${window.location.origin}/authentication/signin-callback`,
				postLogoutRedirectUri: `${window.location.origin}/authentication/signout-callback`,
				clientId: 'integ.client.spa.pkce',
				scope: 'offline_access openid profile email identity.api printing',
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				secureRoutes: [
					environment.portalBaseAddress,
					environment.printServiceBaseAddress,
					environment.integServiceBaseAddress
				],
				logLevel: environment.production
					? LogLevel.Warn
					: LogLevel.Debug,
			},
		}),
	],
	exports: [AuthModule],
})
export class AuthConfigModule {}
