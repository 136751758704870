<br/>

<div *ngIf="loading" align="center">
    <br/>
    <div class="loader"></div>
</div>

<form *ngIf="!loading"  (submit)="searchOrderById()">
    <table class="table">
        <thead>
            <tr *ngIf="!data.isVend">
                <th colspan="2" class="table-head-style">
                    <div *ngIf="data.isShopify"  class="table-head-refunds">
                        <select class="form-control" name="salesStatus" [(ngModel)]="salesStatus" (change)="filterSalesStatus()">
                            <option selected value="regular">Regular</option>
                            <option value="returns">Returns</option>
                        </select>
                    </div>
                </th>
                <th class="table-head-style">
                    <div class="input-group table-head-id">
                        <input name="searchText" type="text" class="form-control" [(ngModel)]="searchText" placeholder="Order ID...">
                        <span class="input-group-btn">
                            <input class="btn btn-info simpleRfIdColor" type="submit" value="Search" />
                        </span>
                    </div>
                </th>
                <th class="table-head-style table-head-qsstatus">
                    <span *ngIf="!(salesStatus==='returns')" class="table-head-status">QC Status:</span>
                </th>
                <th class="table-head-style table-head-refresh">
                    <div *ngIf="!(salesStatus==='returns')" class="input-group">
                        <select class="form-control" name="status" [(ngModel)]="status" (change)="filterStatus()">
                            <option selected value="ready">Ready for QC</option>
                            <option value="shipping">Shipping | Submitted for QC</option>
                            <option value="shippingQc">Shipping QC | Processing</option>
                            <option value="completed">Completed</option>
                        </select>
                        <span class="input-group-btn">
                            <button class="btn btn-secondary" (click)="filterStatus()" type="button">Refresh</button>
                        </span>
                    </div>
                </th>
                <th colspan="2" class="table-head-style table-head-confirm">
                    <div *ngIf="!(salesStatus==='returns')" class="input-group">
                        <select name="action" [(ngModel)]="action" class="form-control">
                            <option selected value="">Choose action</option>
                            <option *ngIf="status==='ready'" value="submitAndPrint">Submit for QC / Picksheet PDF</option>
                            <option *ngIf="!(status==='ready')" value="print">Download Picksheet PDF</option>
                            <option *ngIf="status==='ready'" value="submit">Submit for QC only</option>
                        </select>
                        <span class="input-group-btn">
                            <button type="button" [disabled]="!action || countOfCheckedOrders == 0" class="btn btn-info simpleRfIdColor" (click)="bulkAction()">Confirm</button>
                        </span>
                    </div>
                </th>
            </tr>
            <tr>
                <th colspan="6" class="table-head-style" style="width: 100px;"></th>
                <th class="table-head-style table-text-end">
                    <div *ngIf="!(salesStatus==='returns')">
                        <label>{{countOfCheckedOrders}} Selected</label>
                    </div>
                </th>
            </tr>
            <tr>
                <th style="width: 120px;">Order</th>
                <th style="width: 240px;">Date</th>
                <th>Customer</th>
                <th class="table-text-center">Items</th>
                <th class="table-text-center">Order Status/QC Status</th>
                <th></th>
                <th *ngIf="!(salesStatus==='returns')" class="table-text-end">
                    <input name="allSelected" type="checkbox" [ngModel]="allSelected" (click)="selectAll()"/>
                </th>
                <th *ngIf="salesStatus==='returns'" class="table-text-center">
                    <span>Print Products</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order of orders; let ind = index"   ng-class="order.class">
                <td><a href="{{order.link}}" target="_blank" style="text-decoration: none;">{{order.Data.Name}}</a></td>
                <td>{{order.Data.OrderDate | date:"medium"}}</td>
                <td>{{order.Data.CustomerName}}</td>
                <td class="cursor-pointer table-text-center" (click)="openProductsInfoModal(order.Data)">{{order.Data.ItemsTotal}} item</td>
                <td class="table-text-center">{{order.Data.Status}}</td>
                <td>{{order.Data.Printed}}<a class="has-notify-icon-small" [ngClass]="{printed: order.Data.PrintStatus === 'Printed'}" title="Printed"></a></td>
                <td *ngIf="!(salesStatus==='returns')" class="table-text-end">
                    <input name="order[{{ind}}].Selected" type="checkbox" [(ngModel)]="order.Data.Selected" (click)="toggleSelected(ind, $event)"/>
                </td>
                <td *ngIf="salesStatus==='returns'" class="table-text-center">
                    <button type="submit" class="btn btn-info simpleRfIdColor"
                    (click)="copyAndPrint(order.Data.Items)"
                    [disabled]="!isPrintingEnable" [ngbTooltip]="isPrintingEnableTitle" 
                    [openDelay]="300" [closeDelay]="300">
                        Print
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</form>

<div *ngIf="showPopUp"  id="popUpOverlay">
    <div class="popUpContent" style="text-align: left">
        <h3 class="table-text-center" style="font-weight: bold;">{{popUpTitle}}</h3>
        <hr>
        To submit the order(s) please specify SKU for the following product(s):
            <div *ngFor="let e of errors">
                Order {{e.Name}}:
                <div *ngFor="let product of e.Error let i = index">
                    <a target="_blank" href="{{product.addSkuUrls}}">{{i + 1}}. {{product.CombinedName}}</a>
                </div>
                <br>
            </div>
        <hr>
        <div class="center">
          <button class="btn btn-sm btn-info" style="background-color: rgb(84, 143, 214)" (click)="closePopUp()" >Ok</button>
        </div>
    </div>
  </div>
  



