import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from '../core/base-http.service';
import { urls } from 'app/core/urls';

@Injectable()
export class PurchaseOrdersService extends BaseHttpService {
  _purchaseType : string = "SUPPLIER";
  _returnType : string = "RETURN";
  _transferType : string = "OUTLET";

   getOrders(status: string, keyword: string, type: string, lastVersion: number, isLoadNext: boolean, currentLocationExternalId: string): Observable<any> {
    var requestParam = "";
    if (currentLocationExternalId) {
      requestParam += '&locationId=' + currentLocationExternalId;
    }
    return this.get(urls.getPurchaseOrdersUrl +
      "?status=" + status + "&keyword=" + keyword + "&lastVersionNumber=" + lastVersion +
      "&isAfter=" + isLoadNext + "&type=" + type + requestParam)
      ;
  }

  submitPurchaseOrders(orders, currentLocationExternalId: string): Observable<any> {
    var data = {
      Orders: orders,
      LocationId: currentLocationExternalId
    }
    return this.post(urls.submitPurchaseOrders, data);
  }

  printPurchaseOrders(orderIds, locationExternalId: string): Observable<any> {
    var data = { OrderExternalIds: orderIds, LocationId: locationExternalId };
    return this.post(urls.printOrders, data);
  }

  getPurchaseOrderProducts(orderId): Observable<any> {
    return this.get(urls.getPurchaseOrdersProducts + "?orderId=" + orderId);
  }

  getOrderStatuses(ordertype: string): Observable<any> {
    return this.get(urls.getOrdersStatuses + '?type=' + ordertype);
  }
}
