import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';

@Injectable()
export class ProgressBarService extends BaseHttpService {

  getTaskState(locationExternalId: string, operationType: number): Observable<any> {
    var requestParam = '';

    if(locationExternalId){
      requestParam += '?locationExternalId=' + locationExternalId;
    }
    requestParam += '&operation=' + operationType;
    return this.get(urls.getSyncState + requestParam)
    ;
  }

  cancelActivity(locationExternalId: string, operationType: number){
    return this.post(urls.setSyncStateToFalse + '?locationExternalId=' + locationExternalId + '&operation=' + operationType, {})
    ;
  }

  deleteJodId(locationExternalId: string, operationType: number){
    return this.post(urls.deleteHangFireJobId + '?locationExternalId=' + locationExternalId + '&operation=' + operationType, {})
    ;
  }
}
