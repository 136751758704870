import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NotifyModalComponent } from './notify-modal.component';

@Injectable()
export class ErrorModalService {

  constructor(private modalService: NgbModal) {}

  openCustom(title: string, message: string) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true
    };

    const modalRef = this.modalService.open(NotifyModalComponent, ngbModalOptions);
    
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmButton = 'OK';
    modalRef.componentInstance.isConfirmation = false;
  }

  open(title: string, message: string) {
    this.openCustom(title, this.build(message));
    // let ngbModalOptions: NgbModalOptions = {
    //   backdrop : 'static',
    //   keyboard : false,
    //   centered: true
    // };

    // const modalRef = this.modalService.open(NotifyModalComponent, ngbModalOptions);
    
    // modalRef.componentInstance.title = title;
    // modalRef.componentInstance.message = this.build(message);
    // modalRef.componentInstance.isConfirmation = false;
  }

  private build(message: string) {
      return message;
  }
}