import { Injectable } from '@angular/core';
import { BaseHttpService } from 'app/core/base-http.service';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { urls } from "app/core/urls";
import { ConnectionInfo, SimpleRfidLocations } from 'app/models/setup';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SetupService extends BaseHttpService {
  initialConfig(isProductCopy, data: RequiredChildsData) : Observable<any>{
    let baseUrl = urls.shopify.initConfig;

    if (data.isBigCommerce){
      baseUrl = urls.bigCommerce.initConfig;
    } else if (data.isFulfil){
      baseUrl =urls.fulfil.initConfig;
    } else if (data.isVend){
      baseUrl = urls.vend.initConfig;
    }

    return this.get_generic<any>(baseUrl + '?isProductCopy=' + isProductCopy);
  }

  getLocations(vendorId: number): Observable<SimpleRfidLocations> {
    var requestParam = '';

    requestParam += '?vendorId=' + vendorId;

    return this.get_generic<SimpleRfidLocations>(urls.getLocationsInfoUrl + requestParam);
  }

  public getAvailableVendors(email: string): Observable<ConnectionInfo>{
    const url = environment.portalBaseAddress + '/api/v1/vendors';
    const params = {
      statuses: [ VendorStatuses.Standard, VendorStatuses.Trial ],

      offset: 0,
      take: 1000
    };
    return this.http.get<IVendorResponse[]>(url, {params : params}).pipe(
      map(vendors => {
        return {
          credentialsValid: true,
          message: null,
          Vendors: vendors.map(_ => {
            return {
              Id: _.id,
              Name: _.name
            }
          })
        };
      })
    );
  }
}

interface IVendorResponse {
  id: number,
  name: string,
  abbreviation: string
}

enum VendorStatuses {
	New = 1,
	Engaged = 2,
	Invoiced = 3,
	Standard = 4,
	Dead = 5,
	Trial = 6,
}
