import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseHttpService } from '../core/base-http.service';
import { urls } from 'app/core/urls';
import { map } from 'rxjs/operators';
import { IOrder, IOrderItem, IOrderItemFull, IOrdersResponse } from './orders.models';
import {environment} from '../../environments/environment';

@Injectable()
export class OrdersService extends BaseHttpService {
   getOrders(page: number, searchText:string, status: string, externalLocationId: string ): Observable<IOrdersResponse> {
     let url = urls.getOrdersUrl + '?page=' + page + '&searchText=' + encodeURIComponent(searchText) + '&status=' + status;
     if(externalLocationId){
       url += '&externalLocationId=' + externalLocationId;
     }
    return this.get(url)
      .pipe(map<any, IOrdersResponse>(response => response));
  }

  getShopifyRefundOrders(page: number, searchText:string ): Observable<IOrdersResponse> {
    let url = urls.shopify.getRefundOrders + '?page=' + page + '&searchText=' + encodeURIComponent(searchText);
   return this.get(url)
     .pipe(map<any, IOrdersResponse>(response => response));
 }

  submitOrdersForQc(orders: IOrder[], locationExternalId: string): Observable<IOrder[]> {
    const data = {
      orders: orders,
      locationExternalId: locationExternalId
    };
    return this.post(urls.submitOrdersForQcUrl, data)
              ;
  }

  public printOrders(orders: number[]): Observable<Blob> {
    const url = environment.baseAddress + urls.getOrdersPdfUrl;
    const params = {
      orderIds: orders
    };

    return this.http.get(url, {
      headers: this.getRequestHeaders(),
      params: params,
      responseType: 'blob'
    });
  }

  getItems(itemsUrl: string): Observable<any> {
    let url = urls.bigCommerce.getItems + '?itemsUrl=' + itemsUrl
    return this.get(url);
  }

  getItemsById(id: number): Observable<any> {
    let url = urls.getOrderItemsUrl + '?id=' + id;
    return this.get(url);
  }

  getShopifyItems(orderItemsArr: Array<IOrderItem>): Observable<IOrderItemFull[]> {
    const params = orderItemsArr.reduce((preValue, curValue, curIndex) => {
      preValue['orderItems[' + curIndex + '].Key'] = curValue.VariantId;
      preValue['orderItems[' + curIndex + '].Value'] = curValue.ProductId;
      return preValue;
    }, {});

    let url = urls.shopify.getOrderItems;

    return this.get(url, {params});
  }
}
