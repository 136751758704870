<div *ngIf="loading" align="center">
  <br/>
  <div class="loader"></div>
</div>

<div *ngIf="!loading">
  <div class="row" style="margin-top: 20px;">
    <div class="col-md-5 show">
      <input id="typeahead-http" type="text" [(ngModel)]="product" [ngbTypeahead]="searchProducts" [resultFormatter]="formatMatches"
      [inputFormatter]="formatMatches" class="form-control" placeholder="SKU, Barcode, or Name + Variants..."  />
    </div>
    <div class="col-md-1">
      <input type="text" class="form-control" [(ngModel)]="quantity"  >
    </div>
    <div class="col-md-1">
      <input type="button" class="btn btn-info form-control simpleRfIdColor" value="+" (click)="openMultipleProductsModal()">
    </div>
    <div class="col-md-1.5">
      <button  class="btn btn-info simpleRfIdColor" (click)="printProduct()"
        [disabled]="!isPrintingEnable"  [ngbTooltip]="isPrintingEnableTitle" 
        [openDelay]="300" [closeDelay]="300">
        Print
      </button>
    </div>
    <div class="col-md-2" *ngIf="allowSourceToggle">
      <input type="checkbox" id="toggleBetweenHistorySources"
            [(ngModel)]="usePortalSource"
            (ngModelChange)="getPrintHistory(1)" >
      <label style="margin-left: 5px;" for="toggleBetweenHistorySources">New Printing</label>
    </div>
  </div>
  <br/>
  <div class="row justify-content-center" *ngIf="pages.length > 1">
    <nav>
    <ul class="pagination">
        <li class="page-item cursor-pointer">
          <a class="page-link" (click)="getPrintHistory(1)" tabindex="-1" [hidden]="currentPage == 1">First</a>
        </li>
        <li class="page-item cursor-pointer">
          <a class="page-link" (click)="getPrintHistory(currentPage - 1)" tabindex="-1" [hidden]="currentPage == 1">Previous</a>
        </li>
        <li *ngFor="let page of pages" class="page-item cursor-pointer" [ngClass]="{active: page === currentPage}">
            <a class="page-link" (click)="getPrintHistory(page)">{{page}}</a>
        </li>
        <li class="page-item cursor-pointer">
          <a class="page-link" (click)="getPrintHistory(currentPage + 1)" [hidden]="currentPage == totalPages">Next</a>
        </li>
        <li class="page-item cursor-pointer">
          <a class="page-link" (click)="getPrintHistory(totalPages)" [hidden]="currentPage == totalPages">Last</a>
        </li>
    </ul>
    </nav>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th>
          Name
        </th>
        <th>
          Quantity
        </th>
        <th>
          Status
        </th>
        <th>
          Type
        </th>
        <th>
        </th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let printJob of printJobs">
      <td [attr.title]="replaceNameForTitle(printJob.Name)" *ngIf="printJob.Name.length < maxNameLength">
          {{printJob.Name}}
      </td>
      <td [attr.title]="replaceNameForTitle(printJob.Name)" *ngIf="printJob.Name.length >= maxNameLength">
          {{printJob.Name.slice(0, maxNameLength)}}...
      </td>
      <td>
        {{printJob.TasksCount}}
      </td>
      <td>
        {{printJob.Status}}
      </td>
      <td>
        <span *ngIf="printJob.ProductsCount == 1">Product</span>
        <span *ngIf="printJob.ProductsCount > 1">Multiple products</span>
      </td>
      <td >
        <span *ngIf="printJob.Type == 4 && printJob.Status == 'New'" class="cursor-pointer" >x</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div *ngIf="showPopUp"  id="popUpOverlay">
  <div class="popUpContent">
    <h3 style="font-weight: bold">{{popUpTitle}}</h3>
    <hr>
      To print the desired product please enter its SKU
    <hr>
    <div>
      <a role="button" class="btn btn-sm btn-info popUpBtn" (click)="closePopUp()" target="_blank" href={{addSkuUrl}}>Add SKU</a>
      <button class="btn btn-sm btn-info" style="background-color: rgb(84, 143, 214); display: inline" (click)="closePopUp()" >Cancel</button>
    </div>
  </div>
</div>

