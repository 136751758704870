import { Injectable } from '@angular/core';
import {RequiredChildsData} from './required-childs-data';

@Injectable()
export class UrlBuilderService {

  getUrltoProduct(productId: number, data: RequiredChildsData) {
    if (data.isBigCommerce) {
      return `https://store-${data.shopName}.mybigcommerce.com/manage/products/${productId}/edit`;
    } else if (data.isShopify) {
      return `https://${data.shopName}.myshopify.com/admin/products/${productId}`;
    } else if (data.isFulfil) {
      return `https://${data.shopName}.fulfil.io/client/#/model/product.product/${productId}`;
    } else if (data.isVend) {
      return `https://${data.shopName}.vendhq.com/product/${productId}`;
    } else {
      return '#';
    }
  }

  getUrltoOrder(orderId: any, data: RequiredChildsData, orderType: OrderTypes) {
    if (data.isFulfil) {
      const baseFulfilUrl = `https://${data.shopName}.fulfil.io/client/#/model/`;

      if(orderType == OrderTypes.Sales){
        return `${baseFulfilUrl}sale.sale/${orderId}`;
      } else if(orderType == OrderTypes.Transfer){
        return `${baseFulfilUrl}stock.shipment.internal/${orderId}`;
      } else if(orderType == OrderTypes.Purchase){
        return `${baseFulfilUrl}purchase.purchase/${orderId}`;
      } else {
        return '#';
      }      
    } else if (data.isShopify) {
      return `https://${data.shopName}.myshopify.com/admin/orders/${orderId}`;
    } else if(data.isVend){
      return `https://${data.shopName}.vendhq.com/consignment/${orderId}`;      
    } else if(data.isBigCommerce) {
      return `https://store-${data.shopName}.mybigcommerce.com/manage/orders/${orderId}/edit`;
    } else {
      return '#';
    }
  }
}

export enum OrderTypes{
  Sales,
  Transfer,
  Purchase
}
