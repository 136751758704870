import { LocationType } from "./enums";

export interface IExternalLocationEditViewModel {
  name: string,
  locationType: LocationType,
  externalId: string,
  selectedLocation: ILocationViewModel,
}

export interface ILocationViewModel {
  id: number,
  name: string,
  locationType: LocationType,
}

export interface IOuterLocations {
  Id: number,
  Name: string,
  Type: LocationType,
  ExternalId: string,
  VendorId: number
}

export interface ConnectionInfo
	{
		credentialsValid: boolean,
		message: string,
		Vendors: IVendorModel[]
	}

export interface IVendorModel {
  Id: number,
	Name: string;
}

export interface SimpleRfidLocations {
  Stores: ISimpleRfidLocation[],
	DistributionCenters: ISimpleRfidLocation[],
	ExternalLocations: ILocationModel[]
	CredentialsValid: boolean,
	CanUseBarcodeAsKeyField: boolean,
	Message: string
}

export interface ISimpleRfidLocation {
  Id: number,
  Name: string,
  ExternalId: string
}

export interface ILocationModel {
  Name: string,
  Id: number,
  ExternalId: string,
  Address: string,
  Type: string,
  InternalId?: number,
	FulfilStorageLocationId?: number,
	IsBound: boolean
}