<br/>
<form class="form-horizontal">
<div class="row">
    <div class="offset-md-1">
    <h4>Non-configurable fields</h4>
    </div>
</div>
<br/>
<div class="form-group row">
    <label class="col-md-2 form-control-label">
        Retail Price
    </label>
    <div class="col-md-10">
      <p class="form-control-static">Price</p>
    </div>
</div>
<div class="form-group row">
    <label class="col-md-2 form-control-label">
        Friendly Identifier
    </label>
    <div class="col-md-10">
      <p class="form-control-static">SKU</p>
    </div>
</div>
<div class="form-group row">
    <label class="col-md-2 form-control-label">
        Weight
    </label>
    <div class="col-md-10">
      <p class="form-control-static">Weight</p>
    </div>
</div>
<div class="form-group row">
    <label class="col-md-2 form-control-label">
        Width
    </label>
    <div class="col-md-10">
      <p class="form-control-static">Width</p>
    </div>
</div>
<div class="form-group row">
    <label class="col-md-2 form-control-label">
        Height
    </label>
    <div class="col-md-10">
      <p class="form-control-static">Height</p>
    </div>
</div>
<div class="form-group row">
    <label class="col-md-2 form-control-label">
        Depth
    </label>
    <div class="col-md-10">
      <p class="form-control-static">Depth</p>
    </div>
</div>

<br/>
<div class="row">
    <div class="offset-md-1">
        <h4>Configurable fields</h4>
    </div>
</div>
<br/>

<div *ngFor="let name of names"  class="form-group row">
    <label for="{{name}}" class="col-md-2 form-control-label">
        {{name==='Description'||name==='SubDescription'? name + ' * ' : name}}
    </label>
    <div class="col-md-8">
        <select name="{{name}}" id="{{name}}" [(ngModel)]="mapping[name]" class="form-control">
            <option *ngFor="let option of options">{{option}}</option>
        </select>
    </div>
    <div class="col-md-10 offset-md-2">
        <span class="text-danger">{{errors[name]}}</span>
    </div>
</div>

<div class="row">
    <p class="offset-md-1"><b>* These fields are required in Simple RFID system. Be careful when mapping these fields. If fields in BigCommerce that are mapped to these fields are blank, whole product will not be synced.</b></p>
</div>
</form>

