import {LocationType} from '../setup';

export interface LightLocation {
  type: LocationType,
  id: number,
  name: string,
  vendorId: number,
  locationKey: string
}

export interface IntegLocationModel {
  Id: number,
  Name: string,
  ExternalId: string,
  Address: string,
  Type?: string,
  InternalId?: number,
  FulfilStorageLocationId?: number,
  IsBound: boolean,
  LocationKey?: string,
}

export interface IntegLocationResponse {
  Id: number,
  Name: string,
  ExternalId: string,
  Address: string,
  Type?: string,
  InternalId?: number,
  FulfilStorageLocationId?: number,
  IsBound: boolean
}

export class ILocationRequest{
  public vendorId: number;
}
