import { Component, OnInit, Input, Injectable, DoCheck, OnDestroy } from '@angular/core';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { UserSettingsInfoService } from 'app/settings/settings.service';
import { filters } from 'app/core/filters-names';
import {LayoutFormatType, PrinterStatus, PrintingService, SearchProductModel} from 'app/printing/printing.service';
import * as XLSX from 'xlsx';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarService } from 'app/progress-bar/progress-bar.service';
import { ActivityOperationType } from 'app/core/modal/activity-operation-type';
import { OrderTypes, UrlBuilderService } from 'app/core/url-builder';
import { HangFireService } from 'app/core/hangfire/hangfire.service';
import { urls } from 'app/core/urls';
import { ProductService } from 'app/products/products.service';
import { FilterPipe } from 'app/filters/filter';
import { ButtonFilterPipe } from 'app/filters/button-filter.pipe';
import { RequiredChildsData } from 'app/core/required-childs-data';
import { InventoryStorageService } from '../inventory-storage.service';
import { ProgressBarDataModel } from 'app/progress-bar/progress-bar-data-model';
import { InventorySyncService } from '../inventory-sync.service';
import { InventoryPrintModalComponent } from '../inventory-print-modal/inventory-print-modal.component';
import { InventoryOrderModalComponent } from '../inventory-order-modal/inventory-order-modal.component';

enum InventorySource {
  Mismatch,
  Match
};

@Component({
  selector: 'app-inventory-sync-on-hand',
  templateUrl: './inventory-sync-on-hand.component.html',
  styleUrls: ['../inventory-sync.component.css', '../../fonts/icon-font.css'],
  providers: [FilterPipe, ButtonFilterPipe]
})

@Injectable()
export class InventorySyncOnHandComponent implements OnInit, DoCheck, OnDestroy {
  items: Array<any> = [];
  showBinLocation: boolean;
  filteredItems: Array<any> = [];
  loading = false;
  syncAll = false;
  makeVisible = false;
  countToSync = 0;
  itemsToSync: number = 0;
  isVisible: boolean;
  allowMakeVisible = false;
  lastAuditDate = null;
  isOrdersAccounted = true;
  selectAllDisabled = false;
  searchString = '';
  showInventoryTypeButtons = false;
  line = filters.default;
  reportsCount: number;
  eCommerceTitle: string;
  syncTab = false;
  colorFilters: any = filters;
  inventoryStatus: string;
  fulfilSyncConfirmationIds: string;
  isSyncInProgress: boolean = false;
  showContent = false;
  @Input() data: RequiredChildsData;
  private inventoryStorage: InventoryStorageService;
  lastSyncCountChangedTime: number = 0;
  lastSyncedItemsCount: number;
  countItemsToPrint: number = 0;
  progressBarData: ProgressBarDataModel;
  progressBarLoadData: ProgressBarDataModel;
  isShowSpreadsheetPopup: boolean = false;
  isShowSpreadsheetButton: boolean = false;
  inputDate = null;
  currentDate: any;
  strCurrentDate: any;
  isSync = false;
  isLoadInProgress: boolean = false;
  productKey: string;
  itemsSearch: Array<any> = [];
  activeFilterColor: string;
  lastSelected: number = -1;
  activeSourceTab: InventorySource;
  InventorySource = InventorySource; //Workaround to make accessable from HTML
  excludeZero: boolean = true;
  layoutExists: boolean;
  isPrinterOnline: boolean;
  syncOperation: ActivityOperationType;
  auditInProgress: boolean = false;
  checkOutOfStock: boolean = false;
  hiddenTitle: boolean = false;
  existsContent: boolean;
  loadActivated: boolean;
  loadDate: string = null;
  checkInventoryLoadedTimer?: any;
  lastScanDate?: Date = null;
  lastSyncDate?: Date = null;
  checkOnHandLoadedTimer: any;
  productToSync: any = null;
  

  public get isPrintingEnable() {
    if(!this.data.PrintingLayouts){
      return false;
    }
    const selectedLayout = this.data
      .PrintingLayouts
      .find(l => l.id == this.data.DefaultLayoutId);

      if (!selectedLayout) {
        return false;
      }

     const labelSupport = this.data.OnlinePrinter.isNewPrintClient
      || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.OnlinePrinter.isNewPrintClient)

    return this.data.isAvailablePrint && this.countItemsToPrint > 0 && !!selectedLayout && labelSupport && this.isPrinterOnline;
  }

  public get isPrintingEnableTitle() {
    if (!this.data.PrintingLayouts) {
      return "No labels";
    }
    const selectedLayout = this.data
    .PrintingLayouts
    .find(l => l.id == this.data.DefaultLayoutId);

    if (!selectedLayout) {
      return "Select label";
    }
    
    if (selectedLayout.format == LayoutFormatType.Sld && !this.data.OnlinePrinter.isNewPrintClient) {
      return 'The Sld label format supported only by new printing';
    }

    if (!this.data.isAvailablePrint) {
      return 'No printer connected';
    }

    if (this.countItemsToPrint <= 0) {
      return 'Select any product';
    }

    if (!selectedLayout) {
      return 'Select label';
    }

    if (!this.isPrinterOnline) {
      return 'Selected printer is offline. Select online printer.';
    }
    
    return 'Run to print selected products';
  }

  ngbModalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    keyboard: false,
    centered: true
  };

  isSotedByAvailableAscending: string = "default";
  isSotedByPatnerAscending: string = "default";

  constructor(private inventorySyncService: InventorySyncService, private filter: FilterPipe, private colorFilter: ButtonFilterPipe,
    private errorModalService: ErrorModalService,
    private confirmModalService: ConfirmModalService, private userSettingsService: UserSettingsInfoService,
    private PrintingService: PrintingService, private modalService: NgbModal, private progressBarService: ProgressBarService,
    private urlBuilder :UrlBuilderService, private hangFireService: HangFireService, private productsService: ProductService) {
    this.inventoryStorage = InventoryStorageService.GetInstance(this.inventorySyncService, this.errorModalService, this.hangFireService);
  }
  ngOnDestroy(): void {
    if(this.checkInventoryLoadedTimer){
      clearInterval(this.checkInventoryLoadedTimer);
    }
  }

  async ngOnInit() {
    this.showContent = false;
    this.progressBarData = new ProgressBarDataModel();
    this.progressBarLoadData = new ProgressBarDataModel();
    this.isShowSpreadsheetButton = false;
    this.productKey = this.data.UseBarcodeAsProductKey ? "Barcode" : "Sku";
    this.activeSourceTab = InventorySource.Mismatch;
    this.syncOperation= ActivityOperationType.SyncOnHandInventory;
    this.inventoryStorage.inventoryMatchCount = this.inventoryStorage.inventoryOnHandMatch.length;

    this.progressBarData.locationExternalId = this.data.currentLocationExternalId;
    this.progressBarLoadData.locationExternalId = this.data.currentLocationExternalId;
    this.progressBarLoadData.isSync = false;
    this.isSyncInProgress = true;

    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.LoadOnHandInventory).subscribe(res => {
      this.progressBarLoadData.operationType = ActivityOperationType.LoadOnHandInventory;
      if(res){
        this.progressBarLoadData.isTaskInProgress = res.IsInProgress;
        if(res.IsInProgress){
          this.progressBarLoadData.loadOnHandQuantity = true;
          this.hiddenTitle = true;
        }
        if(!res.IsInProgress && res.HangfireJobId){
          if(res.StatusJob == "Succeeded"){
            this.progressBarLoadData.hangFireSuccess = true;
            this.progressBarLoadData.hangfireJobId = res.HangfireJobId;
            this.auditInProgress = true;
            this.loadDate = res.CreateDateTimeJob.toString();
          }else if(res.StatusJob == "Failed"){
            this.progressBarLoadData.hangFireFail = true;
            this.progressBarLoadData.errorMessage = "last loading inventory";
            this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarLoadData.operationType).subscribe(r => {});
          }
        }
      }
      this.showContent = true;
    });

    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.SyncOnHandInventory).subscribe(res => {
      this.progressBarData.operationType = ActivityOperationType.SyncOnHandInventory;
      if(res){
        this.progressBarData.isTaskInProgress = res.IsInProgress;
        if(!res.IsInProgress && res.HangfireJobId){
          if(res.StatusJob == "Succeeded"){
            this.progressBarData.hangFireSuccess = true;
            this.progressBarData.hangfireJobId = res.HangfireJobId;
          }else if(res.StatusJob == "Failed"){
            this.progressBarData.hangFireFail = true;
            this.progressBarData.errorMessage = "last syncing inventory";
            this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarData.operationType).subscribe(r => {});
          }
        }
        this.isSyncInProgress = false;
        this.showContent = true;
      }
    });

    this.loading = true;
    this.getLastAuditDate();
    this.getLastScanDate();
    this.getLastSyncDate()

    // TODO: Get audit status from back
    this.auditInProgress = false;

    if (this.data.isBigCommerce) {
      let userSettings = await this.userSettingsService.getUserSettingsInfo();
      this.showBinLocation = userSettings.showBinLocation;
      this.loading = false;
    }

    this.checkInventoryLoaded();
    this.checkInventoryLoadedTimer = setInterval(() => this.checkInventoryLoaded(), 1000);

    this.loading = false;
  }

  ngDoCheck(){
    if(this.progressBarLoadData.auditCanceled){
      this.auditInProgress = false;
      this.progressBarLoadData.auditCanceled = false;
      this.filteredItems = [];
      this.items = [];
      this.inventoryStorage.inventoryOnHandSync = [];
      this.inventoryStorage.inventoryOnHandMatch = [];
      this.loadDate = null;
      this.getMismatchInventoryOnHand();
    }
    this.layoutExists = this.data.DefaultLayoutId > 0;
    this.isPrinterOnline = this.data.OnlinePrinter?.status == PrinterStatus.Online;
    
    if(this.progressBarLoadData.hangFireSuccess){
      this.progressBarLoadData.hangFireSuccess = false;
      if(this.loadActivated){
        this.loadActivated = false;
        this.loadDate = this.progressBarLoadData.hangFireJobDateCreate;
        this.compareAudit();
      }
    } else if (this.progressBarLoadData.hangFireFail){
      this.progressBarLoadData.hangFireFail = false;
      this.errorModalService.open("Error", this.progressBarLoadData.errorMessage);
    }

    if(this.progressBarData.hangFireSuccess){
      this.progressBarData.hangFireSuccess = false;
      this.getSyncOnHandResult();
    } else if (this.progressBarData.hangFireFail){
      this.progressBarData.hangFireFail = false;
      this.progressBarData.isSync = false;
      this.errorModalService.open("Error", this.progressBarData.errorMessage);
    }
  }

  loadInProgress() {
    this.isLoadInProgress = true;
  }

  finishLoad() {
    this.isLoadInProgress = false;
    this.progressBarLoadData.isTaskInProgress = false;
    this.showContent = true;
  }

  inProgress() {
    this.isSyncInProgress = true;
    this.showContent = true;
  }

  finishSync() {
    if(this.isSync){
      this.isSyncInProgress = false;
    this.progressBarData.isTaskInProgress = false;
    this.progressBarData.isSync = false;
    let itemsToSync = this.filteredItems.filter(i => {
      return i.sync;
    });
    itemsToSync.forEach(e => {
      this.items = this.items.filter(function (i) {
        return i.ProductId != e.ProductId && (i.VariantId == null || i.VariantId != e.VariantId);
      });
    });
    this.showContent = true;
    }
  }

  changeTab(isReverted: boolean) {
    this.syncTab = !this.syncTab;
    if (!this.syncTab && isReverted) {
      this.compareAudit();
    }
  }

  private filterItems(items) {
    return this.filter.transform(items, ['Title', 'Sku'], this.searchString.trim());
  }

  private getItemsForSearch() {
    this.filteredItems = this.filterItems(this.itemsSearch);
    return this.filteredItems;
  }

  public getInventoryTabOnHand() {

    const sources = [{
      items: this.inventoryStorage.inventoryOnHandSync,
      callback: this.getMismatchInventoryOnHand.bind(this)
    }, {
      items: this.inventoryStorage.inventoryOnHandMatch,
      callback: this.getMatchInventoryOnHand.bind(this)
    }];

    for (let index = 0; index < sources.length; index++) {
      const source = sources[index];
      this.filteredItems = this.filterItems(source.items);
      if (this.filteredItems.length > 0) {
        source.callback();
        break;
      }
    }
  }

  private getCurrentMutchSourceOnHand() {
    if (this.activeSourceTab == InventorySource.Mismatch) {
      if(this.searchString === ""){
        return this.items;
      }else{
        return this.inventoryStorage.inventoryOnHandSync;
      }
    } else if (this.activeSourceTab == InventorySource.Match) {
      return this.inventoryStorage.inventoryOnHandMatch;
    }

    return [];
  }

  private getCurrentMutchCallbackOnHand() {
    if (this.activeSourceTab == InventorySource.Mismatch) {
      return this.getMismatchInventoryOnHand;
    } else if (this.activeSourceTab == InventorySource.Match) {
      return this.getMatchInventoryOnHand;
    }

    return () => { };
  }

  private search() {
    this.itemsSearch = this.getCurrentMutchSourceOnHand();
    this.getItemsForSearch();
    this.countItemsToSync()
    this.syncAll = this.countToSync > 0 && this.filteredItems.length === this.countToSync;
    this.selectAllDisabled = this.filteredItems.length === 0;
  }

  private checkInventoryLoaded(): void {
    if (this.inventoryStorage.isInventoryMatchLoaded
      && this.inventoryStorage.isInventorySyncLoaded
      && !this.loading) {
      return;
    }
    this.isShowSpreadsheetButton = true;;
    this.getCurrentMutchCallbackOnHand().bind(this)();
  }

  private countItemsToSync() {
    this.countToSync = this.inventoryStorage.inventoryOnHandSync.filter(it => it.sync).length;
    this.isShowSpreadsheetButton = this.countToSync > 0;
    this.countItemsToPrint = this.inventoryStorage.inventoryOnHandSync.filter(it => it.sync && it.CurrentCount - it.NewCount > 0).length;
    let checkedItems = this.inventoryStorage.inventoryOnHandSync.filter(it => it.sync);
    this.itemsToSync = checkedItems.reduce((sum, current) => sum + current.NewCount, 0);
  }

  private setItemsToSync() {
    this.countItemsToSync()
    this.allowMakeVisible = this.isVisible === false;
    this.selectAllDisabled = this.filteredItems.length === 0;
    this.syncAll = this.countToSync > 0 && this.filteredItems.filter(i => i.NewCount != i.CurrentCount).length === this.countToSync;
  }

  public lineFilterOnHand(lineColor: string) {
    this.line = lineColor;
    if (lineColor !== filters.default) {
      this.filteredItems = this.colorFilter.transform(this.inventoryStorage.inventoryOnHandSync, lineColor);
      this.existsContent = this.inventoryStorage.inventoryOnHandSync.some(i => i.RfidCount > 0);
    } else {
      this.filteredItems = this.items;
      this.existsContent = this.items.length > 0;
    }

    this.inventoryStorage.SetInventoryItemsCounts(this.filteredItems);
    this.activeFilterColor = null;
    this.checkOutOfStock = false;
    if (lineColor === filters.default) {
      this.activeFilterColor = 'white';
    } else if (lineColor === filters.inflatedFilter) {
      this.activeFilterColor = 'red';
    } else if (lineColor === filters.deflatedFilter) {
      this.activeFilterColor = 'green';
    } else if (lineColor === filters.OoSFilter) {
      this.activeFilterColor = 'oss';
      this.checkOutOfStock = true;
    }

    this.countToSync = 0;
    this.itemsToSync = 0;
    this.syncAll = false;

    this.countItemsToSync();

    let currentItemsToSync = this.filteredItems.filter(_ => _.sync).length;
    if (this.filteredItems.length == currentItemsToSync && currentItemsToSync != 0) {
      this.syncAll = true;
    }

    this.selectAllDisabled = this.filteredItems.length == 0;
  }

  public getMismatchInventoryOnHand() {
    if (this.inventoryStorage.isInventorySyncLoaded) {
      this.items = this.inventoryStorage.inventoryOnHandSync;
      this.isShowSpreadsheetButton = this.filteredItems.filter(i => i.sync).length > 0 ? true : false;
      this.loading = false;
      this.isLoadInProgress = false;
      this.hiddenTitle = true;
      this.progressBarLoadData.isWasStarted = false;
      this.lineFilterOnHand(filters.default);
      this.isOrdersAccounted = this.inventoryStorage.isOrdersAccounted;
      this.inventoryStatus = this.inventoryStorage.inventoryStatus;
      this.lastAuditDate = this.inventoryStorage.lastAuditDateOnHand != null ? this.inventoryStorage.lastAuditDateOnHand : undefined;
      this.setItemsToSync();
      this.showInventoryTypeButtons = this.inventoryStorage.isFirstloaded;
      this.inventoryStorage.SetMismatchItemsCount(ActivityOperationType.LoadOnHandInventory);
      this.inventoryStorage.SetInventoryItemsCounts(this.items);

      if (this.inventoryStorage.isResetRecommended) {
        var error = 'The Quantity of Orders pending prevents calculation. Reset Inventory to allow the system to approximate current on-hand inventory against orders';
        this.errorModalService.open("Warning", error);
      }
    } else {
      this.loading = true;
    }
    this.activeSourceTab = InventorySource.Mismatch;
    this.search();
  }

  public getMatchInventoryOnHand() {
    if (this.inventoryStorage.isInventoryMatchLoaded) {
      this.filteredItems = this.inventoryStorage.inventoryOnHandMatch;
      this.existsContent = this.inventoryStorage.inventoryOnHandMatch.length > 0;
      this.inventoryStorage.inventoryMatchCount = this.inventoryStorage.inventoryOnHandMatch.length;
      this.inventoryStorage.SetInventoryItemsCounts(this.filteredItems);
      this.isShowSpreadsheetButton = this.filteredItems.filter(i => i.sync).length > 0 ? true : false;
      this.loading = false;
    } else {
      this.loading = true;
    }
    this.countToSync = 0;
    this.activeSourceTab = InventorySource.Match;
    this.search();
  }

  public adjustSelectionOnHand() {
    if (this.activeSourceTab == InventorySource.Match) {
      this.filteredItems.forEach(it => { it.sync = this.syncAll; });
    } else if(this.activeSourceTab == InventorySource.Mismatch) {
      this.filteredItems.forEach(it => {it.sync = it.NewCount < 0 || it.NewCount == it.CurrentCount ? false : this.syncAll;});
    }
    this.countItemsToSync();
  }

  public changeSelectionOnHand(product) {
    if (product.sync) {
      product.sync = !product.sync;
      product.editable = false;
      product.isAdjusted = false;
    } else {
      product.sync = true;
    }

    if (product.sync) {
      this.countToSync++;
      this.itemsToSync += product.NewCount;
    } else {
      this.countToSync--;
      this.itemsToSync -= product.NewCount;
    }

    let currentItemsToSync = this.filteredItems.filter(_ => _.sync).length;
    this.syncAll = currentItemsToSync > 0 && this.filteredItems.length == currentItemsToSync;
    this.countItemsToSync();
  }

  private confirmToUpdateOnHand(product: any) {
    this.isSync = true;
    this.confirmModalService.open("Confirm", `Please confirm updating ${this.countToSync} item(s).`, this.updateInventoryOnHand.bind(this, product));
  }

  private updateInventoryOnHand(product: any) {
    const data = {
      StoreHash: null,
      MakeProductsVisible: this.makeVisible,
      Visible: this.isVisible,
      Inventory: []
    };

    let inventoryItems: any[] = [];

    if(product != null){
      inventoryItems.push(product);
      this.productToSync = product;
    }else{
      inventoryItems = this.inventoryStorage.inventoryOnHandSync.filter(_ => _.sync);
    }

    if(inventoryItems.length < 20001){
      data.Inventory = inventoryItems.
      map(product => {
        return {
          InventoryItemId: product.InventoryItemId,
          ProductId: product.ProductId,
          VariantId: product.VariantId,
          Sku: product.Sku,
          Title: product.Title,
          IsSynchronized: product.IsSynchronized,
          QuantityBefore: product.CurrentCount,
          QuantityAfter: product.NewCount,
          CurrentCount: product.CurrentCount,
          NewCount: product.NewCount,
          Selected: product.sync,
          RfidCount: product.RfidCount,
          OrdersCount: product.OrdersCount
        };
      });

      this.inventorySyncService.enqueuedUpdateItem(data, this.data.currentLocationExternalId, urls.shopify.enqueuedUpdateOnHandItemUrl).subscribe(res => {
        this.progressBarData.operationType = ActivityOperationType.SyncOnHandInventory;
        this.isSyncInProgress = true;
        this.startProgressBarSync();
        this.progressBarData.isWasStarted = true;
        this.progressBarData.isSync = true;
      },
      this.errorHandle('updating the inventory'));
    } else {
      this.errorModalService.open('Warning', 'updating the inventory. Inventory(Mismatch + Match) must be no more than 20,000 products.');
    }
  }

  private filterByItemOnHand(item){
    this.items = this.items.filter(function (i) {
      return i.ProductId != item.ProductId && (i.VariantId == null || i.VariantId != item.VariantId);
    });
    this.inventoryStorage.inventoryOnHandSync = this.items;
    this.inventoryStorage.SetMismatchItemsCount(ActivityOperationType.LoadOnHandInventory);
    this.inventoryStorage.SetInventoryItemsCounts(this.items);
  }

  private errorHandle(message: string) {
    return err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open("Error", message);
    }
  };

  private printOnHand() {
    this.showContent = false;
    this.loading = true;

    const products: SearchProductModel[] = this.filteredItems
      .filter(p => p.sync && p.CurrentCount > 0)
      .map(p => {
        return {
          Name: p.Name,
          ProductId: p.ProductId,
          VariantId: p.VariantId,
          Sku: p.Sku,
          Quantity: p.printQuantity,
        };
      });

    this.PrintingService.printProducts(this.data, products).subscribe(res => {
      this.countToSync = 0;
      this.uncheckPrintedItems();
      this.PrintingService.goToPrintTab();
    },
      err => {
        this.errorModalService.open("Error", 'print product(s)');
      });
  }

  private uncheckPrintedItems(): void {
    this.inventoryStorage.inventoryOnHandSync.forEach(i => {
      i.sync = false;
    });
  }

  public getXmlCsvSpreadsheetOnHand(type: string): void {
    const today = new Date();
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
    let blob: Blob = null;
    let link = document.createElement('a');
    const bookName = 'Inventory Spreadsheet';

    link.download = bookName + " " + date;

    const items = [];

    let platform = this.data.platform;

    let spreadsheetItems = this.filteredItems.filter(it => it.sync);
    spreadsheetItems.forEach((i) => {
      let item: any = {};
      item.Title = i.Title;
      item.Sku = i.Sku;
      item.RFID = i.RfidCount;
      if (this.data.isBigCommerce) {
        item.Bpn = i.Bpn;
        item.Orders = i.OrdersCount;
        item.Available = i.NewCount;
        item.BigCommerce = i.CurrentCount;
      } else if (this.data.isShopify) {
        item.Orders = i.OrdersCount;
        item.Available = i.NewCount;
        item.Shopify = i.CurrentCount;
      } else if (this.data.isVend) {
        item.Available = i.NewCount;
        item.Vend = i.CurrentCount;
      } else if (this.data.isFulfil) {
        item.Fulfil = i.CurrentCount;
      }

      items.push(item);
    });

    let binaryWS = XLSX.utils.json_to_sheet(items);

    if (type == 'xlsx') {
      let wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, binaryWS, bookName);

      let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      blob = new Blob([this.s2ab(wbout)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," })
    } else if (type = 'csv') {
      let csv = XLSX.utils.sheet_to_csv(binaryWS);
      blob = new Blob([this.s2ab(csv)]);
      link.download = `${bookName + " " + date}.csv`;
    }

    this.closePopup();

    if (blob == null){
      this.errorModalService.open("Error", 'Unresolved export type');
      return;
    }

    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  public closePopup() {
    this.isShowSpreadsheetPopup = false;
  }

  public openPopup() {
    if(this.filteredItems.filter(i => i.sync).length > 0){
      this.isShowSpreadsheetPopup = true;
    }
  }

  public openPrintModal() {
    if (this.activeSourceTab == InventorySource.Mismatch) {
      let items = this.filteredItems.filter(p => {
        return p.sync && p.CurrentCount - p.NewCount > 0;
      });
      if (items.length > 0) {
        const modalRef = this.modalService.open(InventoryPrintModalComponent, this.ngbModalOptions);
        modalRef.componentInstance.items = items;
        modalRef.componentInstance.passEntry.subscribe((r) => {
          this.printOnHand();
        });
      }
    } else {
      this.printOnHand();
    }
  }

  public toggleSelected(index, event) {
    if (event.shiftKey) {
      if (this.lastSelected < 0) {
        this.lastSelected = index;
      } else{
        var min = this.lastSelected > index ? Math.min(index, this.lastSelected) + 1: Math.min(index, this.lastSelected);
        var max = Math.max(index, this.lastSelected);
        var checked = this.filteredItems[this.lastSelected].sync;
        this.filteredItems
          .slice(min, max)
          .forEach(item => item.sync = checked);
        this.lastSelected = -1;
      }
    } else {
      this.lastSelected = -1;
    }
  }

  private getLoadOnHandResult(){
    this.inventoryStorage.Load(this.progressBarLoadData.hangfireJobId, this.data.currentLocationExternalId, ActivityOperationType.CompareOnHandInventory);
  }

  private getSyncOnHandResult(){
    this.loading = true;
    this.hangFireService.getHangFireJobResult(this.progressBarData.hangfireJobId, 
                                              this.data.currentLocationExternalId,
                                              ActivityOperationType.SyncOnHandInventory).subscribe(res => {

                                                
        let syncItems: any[] = [];
        if(this.productToSync != null){
          syncItems.push(this.productToSync);
        } else{
          syncItems = this.filteredItems.filter(product => product.sync);
        }
          this.isSyncInProgress = false;
          this.progressBarData.isSync = false;
          this.progressBarData.isTaskInProgress = false;
          const arrMatch = [];
          syncItems.forEach(i => {
            i.CurrentCount = i.NewCount;
            i.FilterType = 'Default';
            i.sync = false;
            this.filterByItemOnHand(i);
            //if (i.RfidCount > 0) {
              arrMatch.push(i);
            //}
          });
          this.inventoryStorage.inventoryOnHandMatch = arrMatch.concat(this.inventoryStorage.inventoryOnHandMatch);
          this.inventoryStorage.inventoryMatchCount = this.inventoryStorage.inventoryOnHandMatch.length;
      
      this.lineFilterOnHand(this.line);

      //this.compareAudit();
    });
  }

  private startProgressBarLoad(){
    this.progressBarLoadData.isTaskInProgress = true;
  }

  private startProgressBarSync(){
    this.progressBarData.isTaskInProgress = true;
  }

  public confirmToLoad() {
    // this.confirmModalService.open("Confirm",
    //                                `DO NOT fulfil any orders.`,
    //                                this.startAudit.bind(this),
    //                                'Proceed',
    //                                'Cancel'
    //                                );
    this.startAudit();
  }

  private startAudit(){
    this.progressBarLoadData.operationType = ActivityOperationType.LoadOnHandInventory;
    this.progressBarLoadData.loadOnHandQuantity = true;
    this.hiddenTitle = true;
    this.showContent = false;
    this.loadActivated = true;
    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.LoadOnHandInventory).subscribe(res => {
      this.progressBarLoadData.isTaskInProgress = res.IsInProgress;
      
      if(!this.progressBarLoadData.isTaskInProgress){
        this.auditInProgress = true;
        this.isLoadInProgress = true;
        setTimeout(() => this.startProgressBarLoad(), 1000);
        this.progressBarLoadData.isWasStarted = true;
        this.inventorySyncService.loadItemsOnHand(this.data.currentLocationExternalId).subscribe((res) => {
        }, err => {
          this.showContent = true;
          this.progressBarLoadData.loadOnHandQuantity = false;
          this.hiddenTitle = false;
          this.loadActivated = false;
          console.log(err);
          this.errorModalService.open('Error', 'when try load items on hand');
        });
      }
    }, err => {
      this.showContent = true;
      this.progressBarLoadData.loadOnHandQuantity = false;
      this.hiddenTitle = false;
      this.loadActivated = false;
      console.log(err);
      this.errorModalService.open('Error', 'when try get task state');
    });
  }

  public compareAudit(){
    this.isLoadInProgress = true;
    this.hiddenTitle = false;
    this.progressBarLoadData.isWasStarted = true;
    this.progressBarLoadData.operationType = ActivityOperationType.CompareOnHandInventory;
    this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.LoadOnHandInventory).subscribe(res => {
      if(this.isSyncInProgress){
        this.isSyncInProgress = false;
        this.progressBarData.isSync = false;
        this.progressBarData.isTaskInProgress = false;
        this.loading = false;
      }
      if(!res.HangfireJobId){
        this.progressBarLoadData.isTaskInProgress = false;
        this.isLoadInProgress = false;
        this.hiddenTitle = true;
        this.progressBarLoadData.isWasStarted = false;
        this.loading = false;
        window.location.reload();
      }else{
        this.isLoadInProgress = true;
        this.hiddenTitle = false;
        this.progressBarLoadData.isWasStarted = true;
        this.progressBarLoadData.operationType = ActivityOperationType.CompareOnHandInventory;
        setTimeout(() => this.startProgressBarLoad(), 1000);
        //this.startProgressBarLoad();
        this.getLoadOnHandResult();
        this.getMismatchInventoryOnHand();
      }
      this.showContent = true;
    });
    this.getLastAuditDate();
    this.getLastScanDate();
    this.getLastSyncDate();
    // if(this.checkOnHandLoadedTimer){
    //   clearInterval(this.checkOnHandLoadedTimer);
    // }
  }

  getLastAuditDate(){
    this.inventorySyncService.getLastAuditDate(this.data.currentLocationExternalId).subscribe(res => {
      this.inventoryStorage.inventoryStatus = res.InventoryStatus;
      this.inventoryStorage.lastAuditDateOnHand = res.LastAuditDate?.toString();
      this.inventoryStatus = this.inventoryStorage.inventoryStatus;
      this.lastAuditDate = this.inventoryStorage.lastAuditDateOnHand != null ? this.inventoryStorage.lastAuditDateOnHand : undefined;
    },
      error => {
        this.loading = false;
        this.errorModalService.open("Error", "Failed to load Last Reset date");
      }
    );
  }

  getLastScanDate(){
    this.inventorySyncService.getLastScanDate(this.data.currentLocationExternalId).subscribe(res => {
      this.inventoryStorage.lastScanDate = res?.toString();
      this.lastScanDate = this.inventoryStorage.lastScanDate != null ? this.inventoryStorage.lastScanDate : undefined;
    },
      error => {
        this.loading = false;
        this.errorModalService.open("Error", "Failed to load Last Scan date");
      }
    );
  }

  getLastSyncDate(){
    this.inventorySyncService.getLastSyncDate(this.data.currentLocationExternalId).subscribe(res => {
      this.inventoryStorage.lastSyncDate = res?.toString();
      this.lastSyncDate = this.inventoryStorage.lastSyncDate != null ? this.inventoryStorage.lastSyncDate : undefined;
    },
      error => {
        this.loading = false;
        this.errorModalService.open("Error", "Failed to load Last Sync date");
      }
    );
  }

  private checkLoadOnHand(){
    if(this.progressBarLoadData.hangFireSuccess){
      this.progressBarLoadData.hangFireSuccess = false;
      if(this.loadActivated){
        this.loadActivated = false;
        this.loadDate = this.progressBarLoadData.hangFireJobDateCreate;
        this.compareAudit();
      }
    } else if (this.progressBarLoadData.hangFireFail){
      this.progressBarLoadData.hangFireFail = false;
      this.errorModalService.open("Error", this.progressBarLoadData.errorMessage);
    }
  }

  public oneUpdateOnHand(product: any){
    product.sync = true;
    this.countToSync = 1;
    this.confirmToUpdateOnHand(product);
  }

  public openOrdersInfoModal(product: any) {
    if (product.OrdersCount > 0 && !this.data.isVend) {
      const lastAuditDate = this.inventoryStorage.lastAuditDate ? new Date(this.inventoryStorage.lastAuditDate) : new Date();
      const lastAuditDateFormatted = lastAuditDate.toLocaleTimeString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
      product.Orders.forEach(o => {
        o.link = this.urlBuilder.getUrltoOrder(o.Id, this.data, OrderTypes.Sales);
        o.created_at = new Date(o.created_at).toLocaleTimeString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
        if (this.data.isShopify) {
          o.fulfillment_status = o.fulfillment_status ? o.fulfillment_status : 'Unfulfilled'; // != "fulfilled"? "/Unfulfilled": "/Fulfilled";
          o.status = o.closed_at ? 'Closed/' : o.cancelled_at ? 'Canceled/' : 'Open/';
        } else if (this.data.isBigCommerce) {
          o.status = '';
        }
      })
      const modalRef = this.modalService.open(InventoryOrderModalComponent, this.ngbModalOptions);
      modalRef.componentInstance.product = product;
      modalRef.componentInstance.orders = product.Orders;
      modalRef.componentInstance.date = lastAuditDateFormatted;
      modalRef.componentInstance.data = this.data;
    }
  }
}

