import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'app/constants/constants';
import { IExternalLocationEditViewModel, ILocationViewModel } from 'app/models/setup';
    
@Pipe({
  name: 'filterInnerLocationsPipe'
})
export class FilterInnerLocationsPipe implements PipeTransform {
     
  transform(list: ILocationViewModel[],
    externalLocations: IExternalLocationEditViewModel[],
    currentLocation: IExternalLocationEditViewModel
    ): ILocationViewModel[] {

      return list.filter(item => {

        if (item.id == Constants.Locations.NotSelected) {
            return true;
        }

        if (item.locationType == currentLocation.locationType) {

            if (item.id == Constants.Locations.CreateNew) {
                const isExists = !!list.find(_=> _.name == currentLocation.name && _.locationType == currentLocation.locationType);
                return !isExists;
            }
       
            const isSelectedAnywhere = !!externalLocations.find(_=> _ != currentLocation && _.selectedLocation == item);
            if (isSelectedAnywhere) {
                return false;
            }

            return true;
        }

        return false;

      });
  }
     
}