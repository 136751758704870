import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { UserSettingsInfoService } from './settings.service';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { environment } from 'environments/environment';
import { RfidLocation } from './classes/rfidLocation';
import { RequiredChildsData } from './../core/required-childs-data';
import { urls } from 'app/core/urls';
import { ConfirmModalService } from 'app/core/modal/confirm-modal.service';
import { CurrentShop } from 'app/main/currentShopModel';
import { PrinterStatus } from 'app/printing/printing.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  settings: any = {};
  loading = false;
  createWebHook: boolean;
  createOrdersWebHook:boolean;
  submitOrder:boolean;
  binLocation: boolean;
  err = 'while trying to get settings';
  isStandalone: boolean = false;
  locations: any = [];
  rfidDcs: Array<RfidLocation> = [];
  rfidStores: Array<RfidLocation> = [];
  clientId: string = '';
  clientSecret: string = '';
  @Input() data: RequiredChildsData;
  subLocations: Array<any> = [];
  currentSubLocation: string;
  @Output() onChanged = new EventEmitter<any>();
  productKey: string;
  openOrdersAgeDays: number = 14;

  constructor(private userSettingsService: UserSettingsInfoService
              , private confirmModalService: ConfirmModalService
              , private errorModalService: ErrorModalService) {}

  async ngOnInit() {
    this.loading = true;

    this.isStandalone = environment.standaloneAddresses.indexOf(location.hostname) > -1;

    let userSettings = await this.userSettingsService.getUserSettingsInfo();
      this.settings = userSettings;
      this.data.showPrintersByLocation = this.settings.ShowPrintersByLocation;
      this.submitOrder = this.settings.IsAutoSubmitOrder;
      this.productKey = this.data.UseBarcodeAsProductKey ? "Barcode" : "Sku";
      this.openOrdersAgeDays = this.settings.OpenOrdersAgeDays;
      this.initialLocation();
      if(!this.isStandalone){
        this.userSettingsService.getWebHookSettings(this.data).subscribe(res => {
          this.createWebHook = res.ProductCreateEnabled && res.ProductUpdateEnabled;
          this.createOrdersWebHook = res.OrderCreateEnabled;
          this.loading = false;
        },
        err => {
          this.loading = false;
          this.errorModalService.open("Error", this.err);
        }); 
      }else if(this.data.isFulfil){
        this.userSettingsService.getSubLocations().subscribe(res => {
          this.subLocations = res;
        });
      }         
  }

  changeVendor(){
    this.userSettingsService.changeVendor(true).subscribe(res => {
      if(res){
        location.reload();
      }
    });
  }

  getSubLocationsByLocation(locationExternalId: string){
    return this.subLocations.filter(loc => {
      return loc.warehouse == locationExternalId
    });
  }

  update() {
    this.userSettingsService.updateUserSettingsInfo(this.settings).subscribe(res => { 
      this.data.showPrintersByLocation = this.settings.ShowPrintersByLocation;
      this.data.printersForView = this.data.showPrintersByLocation ? this.data.Printers.filter(p => p.location == this.data.currentLocationFullName) : this.data.Printers;
      if(this.data.defaultPrinterId){
        const printer =  this.data.printersForView.find(p => p.id == this.data.defaultPrinterId);
        if(!printer){
          this.data.defaultPrinterId = this.getDefaultPrinterId();
        }
      } else{
        this.data.defaultPrinterId = this.getDefaultPrinterId();
      }
      this.setDefaultPrinterIdToLocalStorage();
    },
    err => {
      console.log(err);
      this.errorModalService.open("Error", 'updating the settings');
    });
  }

  setProductCreateAndUpdateWebHook(){
    if(this.createWebHook){
      this.userSettingsService.SubscriptionToProductCreateAndUpdateWebHook(this.data).subscribe(res => { 
        if (!res) {
          this.createWebHook = res;
          this.errorModalService.open("Error", 'setting auto-import/update products');
        }
      },
        err => {
          this.errorModalService.open("Error", 'setting auto-import/update products');
        });
    }else{
      this.userSettingsService.UnsubscriptionToProductCreateAndUpdateWebHook(this.data).subscribe(res => { 
        if (!res) {
          this.createWebHook = res;
          this.errorModalService.open("Error", 'setting auto-import/update products');
        }
      },
        err => {
          this.errorModalService.open("Error", 'setting auto-import/update products');
        });
    }
  }

  setOrderCreateWebHook(){
    if(this.createOrdersWebHook){
      this.userSettingsService.SubscriptionToOrderCreateWebHook(this.data).subscribe(res => { 
        if (!res) {
          this.createOrdersWebHook = res;
          this.errorModalService.open("Error", 'setting auto-import products');
        }
      },
        err => {
          this.errorModalService.open("Error", 'setting auto-import products');
        });
    }else{
      this.submitOrder = false;
      this.userSettingsService.UnsubscriptionToOrderCreateWebHook(this.data).subscribe(res => { 
        if (!res) {
          this.createOrdersWebHook = res;
          this.errorModalService.open("Error", 'setting auto-import products');
        }
      },
        err => {
          this.errorModalService.open("Error", 'setting auto-import products');
        });
    }
  }

  setSubmitOrder(){
    var data = {IsEnable: this.submitOrder}
    this.userSettingsService.setSubmitOrder(data).subscribe(res => { 
      if (!res) {
        this.createOrdersWebHook = res;
        this.errorModalService.open("Error", 'setting submit order state');
      }
    },
      err => {
        this.errorModalService.open("Error", 'setting submit order state');
      });
  }

  initialLocation(){
    this.userSettingsService.getAllLocations().subscribe(result => {
      this.locations = result;
      this.loading = false;
      this.userSettingsService.getBindLocations('dc').subscribe(res => {
        this.rfidDcs = res;
      });
  
      this.userSettingsService.getBindLocations('store').subscribe(res => {
        this.rfidStores = res;
      });    
    },
    error => {
      console.log(error);
      this.loading = false;
      this.errorModalService.open("Error", this.err);
  });
    
  }

  createOrBindLocation(locId, item){
    if(locId == -1){
      let title: string = `Create ${item.Name} location`;
      this.confirmModalService.open(title, "Confirm the creation of a new location", this.bindLocation.bind(this, locId, item));
    } else{
      this.bindLocation(locId, item);
    }
  }

  bindLocation(locId, item){
    if(locId != null){
      if(locId == 0){
        this.unBindLocation(item);
      } else{
        var location = new RfidLocation();
        location.ExternalId = item.ExternalId;
        location.Id = locId;
        location.Type = item.Type;

        this.userSettingsService.bindLocation(location).subscribe(res => {
          if(res){
            this.getBindedLocations(item);
            let shops = JSON.parse(window.localStorage.getItem('context'));
            shops.forEach(sh => {
              if(sh.location.ExternalId === item.ExternalId){
                sh.location = item;
                window.location.reload();
              }
            });
            window.localStorage.setItem("context", JSON.stringify(shops));
            if(this.data.isFulfil){
              this.userSettingsService.getSubLocations().subscribe(res => {
                this.subLocations = res;
                let subLocationId = res.filter(i => {return i.warehouse == item.ExternalId})[0].id;
                this.setSyncSublocation(subLocationId, item.ExternalId);        
              });
            }
            this.onChanged.emit({isBind: true, locationId: locId});
          }
        },
        err => {
          console.log(err);
        });
      }
    }
  }

  unBindLocation(item){
    var location = new RfidLocation();
    location.ExternalId = item.ExternalId;
    location.Id = 0;
    location.Type = item.Type;

    this.userSettingsService.unBindLocation(location).subscribe(res => {
      if(res){
        this.getBindedLocations(item);
        if(this.data.isFulfil){
          this.userSettingsService.getSubLocations().subscribe(res => {
            this.subLocations = res;        
          });
        }
        this.onChanged.emit({isBind: false, locationId: item.ExternalId});
      }
    },
    err => {
      console.log(err);
    });
  }

  getLocations(type, locationExternalId){
    if(type == "dc"){
      return this.LocationFilter(this.rfidDcs, locationExternalId);
    } else if (type == "store") {
      return this.LocationFilter(this.rfidStores, locationExternalId);
    }
    return [];
  }

  private LocationFilter(rfIdlocations, ExternalId){
    let currentLocation = this.locations.filter(function(el){
      return (el.ExternalId == ExternalId);
    });

    let existedLocation = rfIdlocations.filter(function(el){
      return (el.Name == currentLocation[0].Name);
    });

    let filteredLocations = rfIdlocations.filter(function(el){
      return (el.ExternalId == null || el.ExternalId == ExternalId);
    });    

    let bindedLocation = rfIdlocations.filter(function(el){
      return (currentLocation[0].ExternalId == el.ExternalId);
    });

    if(existedLocation.length > 0){
      filteredLocations = filteredLocations.filter(function(el){
        return (el.Id >= 0);
      });
    }
    
    return filteredLocations;
  }

  private getBindedLocations(item){
    if(item.Type == "dc"){
      this.userSettingsService.getBindLocations('dc').subscribe(res => {
        this.rfidDcs = res;
        this.initialLocation();
        this.LocationFilter(this.rfidDcs, item.ExternalId);
      });
    }else if(item.Type == "store"){
      this.userSettingsService.getBindLocations('store').subscribe(res => {
        this.rfidStores = res;
        this.initialLocation();
        this.LocationFilter(this.rfidStores, item.ExternalId);
      });   
    } 
  }

  canUnbind(externalId, type){
    if(type == "dc"){
      let res = this.rfidDcs.filter(function(el){
        return (el.ExternalId == externalId)
      });

      return res.length > 0
    } else {
      let res = this.rfidStores.filter(function(el){
        return (el.ExternalId == externalId)
      });

      return res.length > 0
    }
  }

  setSyncSublocation(value, locationExternalId): void{
    this.userSettingsService.setSubLocation(locationExternalId, value).subscribe(res =>{});    
  }

  changeOpenOrdersAgeDays(){
    if(this.openOrdersAgeDays >= 14 && this.openOrdersAgeDays <= 60){
      var data = {OpenOrdersAgeDays: this.openOrdersAgeDays}
      this.userSettingsService.changeOpenOrdersAgeDays(data).subscribe(res => { 
        if(!res){
          this.errorModalService.open("Error", 'updating the days');
        }
      },
        err => {
          console.log(err);
          this.errorModalService.open("Error", 'updating the days');
        });
    }
  }

  setDefaultPrinterIdToLocalStorage() {
    const shops: Array<CurrentShop> = JSON.parse(window.localStorage.getItem('context'));
    if (shops) {
      shops.forEach(el => {
        if (el.shopName == this.data.shopName && el.platform == this.data.platform) {
          el.printerId = el.printerId != this.data.defaultPrinterId ? this.data.defaultPrinterId : el.printerId;
        }
      });
      window.localStorage.setItem('context', JSON.stringify(shops));
    }
  }

  getDefaultPrinterId(){
    if(this.data.printersForView.length > 0){
      const printersOnline = this.data.printersForView.filter(p => p.status == PrinterStatus.Online);
      const printer = printersOnline.length > 0 ? printersOnline[0] : this.data.printersForView[0];
      this.data.OnlinePrinter = printer;
      this.data.isAvailablePrint = true;
      return printer.id;
    }

    this.data.OnlinePrinter = null;
    this.data.isAvailablePrint = false;
    
    return null;
  }
}
