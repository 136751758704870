import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthenticationRoutingModule } from "./authentication-routing.module";
import {
  ForbiddenComponent,
  SigninCallbackComponent,
  SignoutCallbackComponent,
  UnauthorizedComponent,
} from "./components";
import { AuthService } from "./services/auth.service";

@NgModule({
  declarations: [
    UnauthorizedComponent,
    SigninCallbackComponent,
    SignoutCallbackComponent,
    ForbiddenComponent,
  ],
  imports: [CommonModule, AuthenticationRoutingModule],
  providers: [AuthService],
})
export default class AuthenticationModule {}
