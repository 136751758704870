export class CurrentShop{
    shopName: string;
    platform: string;
    location: any;
    layoutId: number;
    printerId: any;

    constructor(shop:string, platform:string, location: any){
        this.shopName = shop;
        this.platform = platform;
        this.location = location;
    }
}