import { Pipe, PipeTransform } from '@angular/core';
import { filters } from 'app/core/filters-names';

@Pipe({
  name: 'buttonFilter'
})
export class ButtonFilterPipe implements PipeTransform {

  transform(items: any, line: string): any {
    let result = [];

    if (!items) {
      return result;
    }
    else{
      if (line === filters.inflatedFilter) {
        return items.filter(item => item.RfidCount !== 0 && item.NewCount < item.CurrentCount);     
      }
      if (line === filters.deflatedFilter) {
        return items.filter(item => item.RfidCount !== 0 && item.NewCount > item.CurrentCount);  
      }
      if (line === filters.OoSFilter) {
        return items.filter(item => item.CurrentCount !== 0 && item.RfidCount == 0);  
      }
      if (line === filters.default) {
        return items;
      }
    }    
    return items;
  }
}
