import { Component, DoCheck, Injectable, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { HangFireService } from "app/core/hangfire/hangfire.service";
import { ErrorModalService } from "app/core/modal/error-modal.service";
import { RequiredChildsData } from "app/core/required-childs-data";
import { ButtonFilterPipe } from "app/filters/button-filter.pipe";
import { FilterPipe } from "app/filters/filter";
import { InventoryStorageService } from "app/inventory-sync/inventory-storage.service";
import { ProgressBarService } from "app/progress-bar/progress-bar.service";
import { LayoutFormatType, PrinterStatus, PrintingService, SearchProductModel} from 'app/printing/printing.service';
import { ProgressBarDataModel } from "app/progress-bar/progress-bar-data-model";
import { NeverPrintedModel, NeverPrintedService } from "./never-printed.service";
import { urls } from "app/core/urls";
import { ActivityOperationType } from "app/core/modal/activity-operation-type";
import { InventorySyncService } from "app/inventory-sync/inventory-sync.service";
import { Observable, switchMap } from "rxjs";
import { ProductCopyModelRequest, ProductCopyModelResponse, ProductService } from "app/products/products.service";
import { NewerPrintedModalComponent } from "./never-printed-modal/never-printed-modal.component";



@Component({
    selector: 'app-never-printed',
    templateUrl: './never-printed.component.html',
    styleUrls: ['./never-printed.component.css', '../fonts/icon-font.css'],
    providers: [FilterPipe, ButtonFilterPipe]
  })
  
  @Injectable()
  export class NeverPrintedComponent implements OnInit, DoCheck, OnDestroy {

    @Input() data: RequiredChildsData;
    inventoryStorage: InventoryStorageService;

    isLoadInProgress: boolean;
    showContent: boolean;
    loading: boolean;
    itemsSearch: Array<NeverPrintedViewModel> = [];
    searchString: string = '';
    countItemsToPrint: number;
    isPrinterOnline: boolean;
    showBinLocation: boolean;
    productKey: string;
    syncAll: boolean;
    selectAllDisabled: boolean;
    filteredItems: Array<NeverPrintedViewModel> = [];
    lastSelected = -1;
    allowMakeVisible: boolean;
    isVisible: boolean;
    makeVisible: boolean;
    excludeZero: boolean;
    progressBarLoadNeverPrinted: ProgressBarDataModel;
    productsToPrint: SearchProductModel[];
    importedProductsDic: {
      [key: string]: ProductCopyModelResponse;
    }
    printErrorMessage: string;
    isSortedByPatnerAscending = 'default';



    public get isPrintingEnable() {
        if(!this.data.PrintingLayouts){
          return false;
        }
        const selectedLayout = this.data
          .PrintingLayouts
          .find(l => l.id == this.data.DefaultLayoutId);
    
          if (!selectedLayout) {
            return false;
          }
    
         const labelSupport = this.data.OnlinePrinter.isNewPrintClient
          || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.OnlinePrinter.isNewPrintClient)
    
        return this.data.isAvailablePrint && this.countItemsToPrint > 0 && !!selectedLayout && labelSupport && this.isPrinterOnline;
      }
    
      public get isPrintingEnableTitle() {
        if (!this.data.PrintingLayouts) {
          return "No labels";
        }
        const selectedLayout = this.data
        .PrintingLayouts
        .find(l => l.id == this.data.DefaultLayoutId);
    
        if (!selectedLayout) {
          return "Select label";
        }
        
        if (selectedLayout.format == LayoutFormatType.Sld && !this.data.OnlinePrinter.isNewPrintClient) {
          return 'The Sld label format supported only by new printing';
        }
    
        if (!this.data.isAvailablePrint) {
          return 'No printer connected';
        }
    
        if (this.countItemsToPrint <= 0) {
          return 'Select any product';
        }
    
        if (!selectedLayout) {
          return 'Select label';
        }
    
        if (!this.isPrinterOnline) {
          return 'Selected printer is offline. Select online printer.';
        }
        
        return 'Run to print selected products';
      }
    
      ngbModalOptions: NgbModalOptions = {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        centered: true
      };

      constructor(
        private neverPrintedService: NeverPrintedService, private inventorySyncService: InventorySyncService,
        private filter: FilterPipe, private colorFilter: ButtonFilterPipe,private errorModalService: ErrorModalService,
        private PrintingService: PrintingService, private modalService: NgbModal, private progressBarService: ProgressBarService,
        private hangFireService: HangFireService, private productsService: ProductService) {
        this.inventoryStorage = InventoryStorageService.GetInstance(this.inventorySyncService, this.errorModalService, this.hangFireService);
      }





    ngOnInit(): void {
      this.productKey = this.data.UseBarcodeAsProductKey ? 'Barcode' : 'Sku';
      this.progressBarLoadNeverPrinted = new ProgressBarDataModel();
      this.progressBarLoadNeverPrinted.locationExternalId = this.data.currentLocationExternalId;
      this.countItemsToPrint = 0;
      this.excludeZero = true;

      this.progressBarService.getTaskState(this.data.currentLocationExternalId, ActivityOperationType.LoadNeverPrinted).subscribe(res => {
        this.progressBarLoadNeverPrinted.operationType = ActivityOperationType.LoadNeverPrinted;
        if (res) {
          this.progressBarLoadNeverPrinted.isTaskInProgress = res.IsInProgress;
          if (!res.IsInProgress && res.HangfireJobId) {
            if (res.StatusJob == 'Succeeded') {
              this.progressBarLoadNeverPrinted.hangFireSuccess = true;
              this.progressBarLoadNeverPrinted.hangfireJobId = res.HangfireJobId;
            } else if (res.StatusJob == 'Failed') {
              this.progressBarLoadNeverPrinted.hangFireFail = true;
              this.progressBarLoadNeverPrinted.errorMessage = 'Error last loading never printed';
              this.progressBarService.deleteJodId(this.data.currentLocationExternalId, this.progressBarLoadNeverPrinted.operationType).subscribe(r => {});
            }
          }
        }
        this.showContent = true;
        if(this.inventoryStorage.neverPrinted.length > 0){
          this.sortByPartner();
        }
      });
    }

    ngDoCheck(): void {
      this.isPrinterOnline = this.data.OnlinePrinter?.status == PrinterStatus.Online;
      if (this.progressBarLoadNeverPrinted.hangFireSuccess) {
        this.progressBarLoadNeverPrinted.hangFireSuccess = false;
        this.getLoadResult();
      } else if (this.progressBarLoadNeverPrinted.hangFireFail) {
        this.progressBarLoadNeverPrinted.hangFireFail = false;
        this.errorModalService.open('Error', this.progressBarLoadNeverPrinted.errorMessage);
      }
    }
    ngOnDestroy(): void {
    }

    loadInProgress() {
      this.isLoadInProgress = true;
    }
  
    finishLoad() {
      this.isLoadInProgress = false;
      this.progressBarLoadNeverPrinted.isTaskInProgress = false;
      this.showContent = true;
    }

    loadNeverPrinted() {
      this.neverPrintedService.EnqueuedNeverPrinted(this.isVisible, this.data.currentLocationExternalId, urls.enqueuedLoadNeverPrinted).subscribe(res => {
        this.isLoadInProgress = true;
        setTimeout(() => this.startProgressBarLoad(), 1000);
        this.progressBarLoadNeverPrinted.operationType = ActivityOperationType.LoadNeverPrinted;
      });
    }

    getLoadResult() {
      this.hangFireService.getHangFireJobResult(this.progressBarLoadNeverPrinted.hangfireJobId,
                                                this.data.currentLocationExternalId, 
                                                ActivityOperationType.LoadNeverPrinted).subscribe(res => {
        this.inventoryStorage.neverPrinted = res.NeverPrintedItems.map(i => {
          i.VariantId = i.VariantId || null;
          return i;
        }).map(this.resultToviewModel);
        this.sortByPartner(true);
      });
    }

    private resultToviewModel(model: NeverPrintedModel): NeverPrintedViewModel{
      return {
        ...model,
        check: false,
        printQuantity: model.CurrentCount
      }
    }

    changeExcludeZero(){
      this.filteredItems = this.excludeZero ?
                                            this.inventoryStorage.neverPrinted.filter(p => { return p.CurrentCount != 0; })
                                                                              .map<NeverPrintedViewModel>(i => {
                                                                                return {...i};
                                                                              })
                                             :
                                            this.inventoryStorage.neverPrinted.map<NeverPrintedViewModel>(i => {
                                              return {...i};
                                            });
      this.search();
      this.inventoryStorage.neverPrintedCount = this.filteredItems.length;
      this.inventoryStorage.SetInventoryItemsCounts(this.filteredItems);
      this.loading = false;
      this.syncAll = false;
      this.countItemsToPrint = 0;
    }

    search() {
      this.filteredItems = this.filterItems(this.filteredItems);
        this.countCheckedItems()
        this.syncAll = this.countItemsToPrint > 0 && this.filteredItems.length === this.countItemsToPrint;
        this.selectAllDisabled = this.filteredItems.length === 0;
      }

    private filterItems(items: Array<NeverPrintedViewModel>): Array<NeverPrintedViewModel> {
      return this.filter.transform(items, ['Title', 'Sku'], this.searchString.trim());
    }

    openPrintModal(){
      const items = this.filteredItems.filter(p => {
        return p.check;
      });
      if (items.length > 0) {
        const modalRef = this.modalService.open(NewerPrintedModalComponent, this.ngbModalOptions);
        modalRef.componentInstance.items = items;
        modalRef.componentInstance.passEntry.subscribe((r) => {
          this.print();
        });
      }
    }

    print() {
      this.printErrorMessage = 'Error print product(s)';
      this.importedProductsDic = {};
      this.loading = true;
      this.buildCopyProducts().pipe(
        switchMap(importedProducts => {
          this.importedProductsDic = importedProducts.reduce<{[key: string]: ProductCopyModelResponse}>((acc, cur) => {
            acc[cur.PartnerProductId + cur.PartnerVariandId] = cur;
            return acc;
          }, {});
          
          this.productsToPrint = this.filteredItems.map(p => {
            return [{ ProductId: this.importedProductsDic['' + p.ProductId + p.VariantId]?.ProductId, Quantity: p.printQuantity, Name: p.Title, Sku: p.Sku }];
          })
          .flat(1)
          .filter(_ => !!_.ProductId && _.Quantity > 0)
          .map<SearchProductModel>(_ => {
            return {
              Name: _.Name,
              ProductId: _.ProductId,
              Quantity: _.Quantity,
              Sku: _.Sku,
            }
          });

          if(this.productsToPrint.length > 0){
            if(this.countItemsToPrint > this.productsToPrint.length){
              this.errorModalService.open('Error', 'Error. One or few product(s) do not copy');
            }
            return this.PrintingService.printProducts(this.data, this.productsToPrint);
          } else {
            this.printErrorMessage = 'Error copy product(s)';
            throw new Error('Error ' + this.printErrorMessage);
          }
        })
      ).subscribe(res => {
        this.filterByItem();
        this.PrintingService.goToPrintTab();
      },
      err => {
        this.loading = false;
        this.errorModalService.open('Error', this.printErrorMessage);
      });
    }

    adjustSelection() {
      this.filteredItems.forEach(it => {
        it.check = this.syncAll;
      });
      this.countCheckedItems();
    }

    private countCheckedItems() {
      this.countItemsToPrint = this.filteredItems.filter(it => it.check).length;
    }

    changeSelection(product) {
      if (product.check) {
        product.check = !product.check;
        this.countItemsToPrint++;
      } else {
        product.check = true;
        this.countItemsToPrint--;
      }
      this.syncAll = this.countItemsToPrint > 0 && this.filteredItems.length === this.countItemsToPrint;
      this.countCheckedItems();
    }

    toggleSelected(index, event) {
      if (event.shiftKey) {
        if (this.lastSelected < 0) {
          this.lastSelected = index;
        } else {
          const min = this.lastSelected > index ? Math.min(index, this.lastSelected) + 1 : Math.min(index, this.lastSelected);
          const max = Math.max(index, this.lastSelected);
          const checked = this.filteredItems[this.lastSelected].check;
          this.filteredItems
            .slice(min, max)
            .forEach(item => item.check = checked);
          this.lastSelected = -1;
        }
      } else {
        this.lastSelected = -1;
      }
    }

    startProgressBarLoad() {
      this.progressBarLoadNeverPrinted.isTaskInProgress = true;
    }
  
    private buildCopyProducts(): Observable<ProductCopyModelResponse[]> {
      const productsToSave = this.buildProductsToSaveList();
      return this.productsService.copyProducts(productsToSave);
    }
  
    private buildProductsToSaveList(): ProductCopyModelRequest[] {
      const productsToSave: ProductCopyModelRequest[] = this.filteredItems
        .filter(p => p.check)
        .map(p => {
          return {
            ProductId: p.ProductId,
            VariantIds: [ p.VariantId ]
          };
        });
  
      return productsToSave;
    }

    private filterByItem() {
      if(this.importedProductsDic){
        this.filteredItems = this.inventoryStorage.neverPrinted.filter(i => {
          return !this.importedProductsDic['' + i.ProductId + i.VariantId];
        }).map<NeverPrintedViewModel>(i => {
          return {...i};
        });
      }
      this.inventoryStorage.neverPrinted = this.filteredItems.map<NeverPrintedViewModel>(i => {
        return {...i};
      });
      this.inventoryStorage.neverPrintedCount = this.filteredItems.length;
      this.inventoryStorage.SetInventoryItemsCounts(this.filteredItems);
    }

    sortByPartner(isSort: boolean = false) {
      this.changeExcludeZero();
      if(!isSort){
        this.isSortedByPatnerAscending = 'default';
        isSort = true;
      }
      if (this.isSortedByPatnerAscending == 'default' || this.isSortedByPatnerAscending == 'desc') {
        this.filteredItems = this.filteredItems.sort((a, b) => this.partnerSort(a, b));
        if(isSort){
          this.isSortedByPatnerAscending = 'asc';
        }
      } else if (this.isSortedByPatnerAscending == 'asc') {
        this.filteredItems = this.filteredItems.sort((a, b) => this.partnerSort(b, a));
        if(isSort){
          this.isSortedByPatnerAscending = 'desc';
        }
      }
    }

    private partnerSort(a, b) {
    return a.CurrentCount - b.CurrentCount;
  }
  }

  export interface NeverPrintedViewModel {
    InventoryItemId: string,
    Sku: string,
    ProductId: string,
    CurrentCount: number,
    Title: string,
    VariantId?: number,
    ImageUrl:string,
    ThumbnailUrl: string,

    check: boolean,
    printQuantity: number,
}