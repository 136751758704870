import { Injectable } from '@angular/core';
import { BaseHttpService } from 'app/core/base-http.service';
import { urls } from 'app/core/urls';
import { map } from 'rxjs/operators';
import { ActivityOperationType } from '../modal/activity-operation-type';

@Injectable()
export class HangFireService extends BaseHttpService {

    getHangFireJobResult(id: string, locationExternalId, operation: ActivityOperationType){
        return this.get(urls.getHangFireJobResult + '/' + id + '/' + locationExternalId + '/' + operation + '/' + 'result')
        ;
    }
}
