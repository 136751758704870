import { LabelLayoutOrderField, OrderDirection } from "./enums"

export interface PrintJobViewModel {
  Id: number,
  Name: string,
  TasksCount: number,
  Status: string,
  ProductsCount: number,
  Type: number
}

export interface ILayoutRequest{
  orderBy: LabelLayoutOrderField,
  orderDirection: OrderDirection,
  vendorId: number,
  deleted: boolean
}

